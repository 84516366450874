/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';
import { toFormData } from '../../utility';

export const getClientListData = async (queryString) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.CLIENT}s?${queryString}`);
    return result;
};

export const downloadFile = async (path) => {
    const result = await axiosInstance.get(`v1/download-file?filePath=${path}`);
    return result;
};

export const deleteFile = async (payload) => {
    const result = await axiosInstance.post(`v1/delete-file`, payload);
    return result;
};

export const getClientById = async (id) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.CLIENT}/${id}`);
    return result;
};

export const getMatchingTendersDropdown = async () => {
    // const result = await axiosInstance.get(`v1/${API_ENDPOINT.ROLE}s-dropdown`);
    // return result;
};

export const createNewClient = async (payload) => {
    const result = await axiosInstance.post(`v1/${API_ENDPOINT.CLIENT}`, payload);
    return result;
};

export const clientAssignConsultancy = async (id, payload) => {
    const result = await axiosInstance.post(`v1/client-assign-consultancy/${id}`, payload);
    return result;
};

export const clientAssignTender = async (id, payload) => {
    const result = await axiosInstance.post(`v1/client-assign-tender/${id}`, payload);
    return result;
};

export const updateClient = async (id, payload) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.CLIENT}/${id}`, payload);
    return result;
};

export const deleteClient = async (id) => {
    const result = await axiosInstance.delete(`v1/${API_ENDPOINT.CLIENT}/${id}`);
    return result;
};

export const importCsvForConsultancy = async (payload) => {
    const result = await axiosInstance.post(
        `v1/${API_ENDPOINT.CLIENT}-upload-csv`,
        await toFormData(payload)
    );
    return result;
};
