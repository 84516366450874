/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { EMAIL_REGEX, MESSAGES } from '../../constants';
import {
    createNewUser,
    getUsersRolesDropdown,
    updateUser
} from '../../helper/services/apis/user-services';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

// eslint-disable-next-line react/prop-types
function CreateUser({ isOpen = false, selectedUser = null, closeModel = null }) {
    const [userRoles, setUserRoles] = useState([]);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm();

    const onSubmitHandler = async (formData) => {
        if (selectedUser) {
            const result = await updateUser(formData.id, formData).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            console.log(result);
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                closeModel();
            }
        } else {
            const result = await createNewUser(formData).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                closeModel();
            }
        }
    };

    const initUserRoles = async () => {
        const result = await getUsersRolesDropdown().catch((e) => toast.error(e.message));
        if (result.data) {
            setUserRoles([...result.data]);
        }
    };

    const autoGeneratePassword = () => {
        setValue('password', Math.random().toString(36).slice(2, 10), { shouldValidate: true });
    };

    useEffect(() => {
        initUserRoles();
    }, []);

    useEffect(() => {
        if (selectedUser) {
            reset({
                username: selectedUser?.userName,
                email: selectedUser?.email,
                roleId: selectedUser?.roleId,
                id: selectedUser?.id
            });
        } else {
            reset({ username: '', email: '', roleId: '', password: '' });
        }
    }, [selectedUser]);

    return (
        <ModalComponent isOpen={isOpen}>
            {/* Modal header  */}
            <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                <h3 className="text-xl font-semibold text-white">
                    {selectedUser ? 'Modifica' : 'Nuovo'} utente
                </h3>
                <button
                    type="button"
                    className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={closeModel}>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    <div className="flex flex-col w-full space-y-4">
                        <div
                            className={`w-full input-field-group ${
                                errors?.username ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="username">Username*</label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                placeholder="Username"
                                {...register('username', { required: true, maxLength: 255 })}
                            />
                            {errors?.username?.type === 'required' && (
                                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                            )}
                            {errors?.username?.type === 'maxLength' && (
                                <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                            )}
                        </div>
                        <div
                            className={`w-full input-field-group ${
                                errors?.email ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="email">Email*</label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                placeholder="Email"
                                {...register('email', {
                                    required: true,
                                    maxLength: 255,
                                    pattern: EMAIL_REGEX
                                })}
                                disabled={selectedUser}
                            />
                            {errors?.email?.type === 'required' && (
                                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                            )}
                            {errors?.email?.type === 'maxLength' && (
                                <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                            )}
                            {errors?.email?.type === 'pattern' && <p>{MESSAGES.INVALID_EMAIL}</p>}
                        </div>
                        <div
                            className={`w-full input-field-group ${
                                errors?.roleId ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="role">Ruolo*</label>
                            <select
                                id="role"
                                name="role"
                                placeholder="Ruolo"
                                {...register('roleId', {
                                    required: true
                                })}>
                                <option value="">Seleziona...</option>
                                {userRoles.map((role) => (
                                    <option key={role.id} value={role.id}>
                                        {role.name}
                                    </option>
                                ))}
                            </select>
                            {errors?.roleId?.type === 'required' && (
                                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                            )}
                        </div>
                        {!selectedUser ? (
                            <div
                                className={`w-full input-field-group relative ${
                                    errors?.password ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="password">
                                    Password{' '}
                                    <span className="font-normal text-gray-700">
                                        (min. 8 caratteri)
                                    </span>
                                    *
                                </label>
                                <input
                                    type="text"
                                    id="password"
                                    name="password"
                                    placeholder="Password"
                                    {...register('password', {
                                        required: true,
                                        maxLength: 255,
                                        minLength: 8
                                    })}
                                />
                                <span
                                    className="absolute bottom-3 right-0 inline-flex items-center px-3 text-sm text-primary-800 font-bold cursor-pointer"
                                    onClick={autoGeneratePassword}
                                    aria-hidden="true">
                                    Autogenera
                                </span>
                                {errors?.password?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                                {errors?.password?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                                {errors?.password?.type === 'minLength' && (
                                    <p className="text-red-700">min. 8 caratteri</p>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                    {selectedUser ? (
                        <PrimaryButton type="submit">MODIFICA</PrimaryButton>
                    ) : (
                        <PrimaryButton type="submit">AGGIUNGI CLIENTE</PrimaryButton>
                    )}

                    <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default CreateUser;
