/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants';
import { clientAssignTender } from '../../helper/services/apis/client-services';
import {
    getClientsMatchingToTenderDropdown,
    updateClientTender
} from '../../helper/services/apis/common-services';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

function AssociateClient({
    isOpen = false,
    type = '',
    closeModel = null,
    selectedTender,
    selectedData = null,
    selectedClientName = null,
    clientId = null
}) {
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm();
    const status = watch('status');

    const [isEdit, setIsEdit] = useState(false);
    const [clients, setClients] = useState([]);

    const onSubmitHandler = async (data) => {
        if (clientId && selectedData) {
            updateClientTender(selectedData.id, { ...data })
                .then((data) => {
                    toast.success(MESSAGES.SUCCESS);
                    closeModel(true);
                })
                .catch((e) => {
                    const message =
                        e.message.indexOf('Duplicate entry') > 0
                            ? 'Il bando è già associato al cliente selezionato.'
                            : e.message;
                    toast.error(message);
                    return false;
                });
        } else {
            clientAssignTender(data.clientId, { ...data, tenderId: selectedTender.id })
                .then((data) => {
                    toast.success(MESSAGES.SUCCESS);
                    closeModel(true);
                })
                .catch((e) => {
                    const message =
                        e.message.indexOf('Duplicate entry') > 0
                            ? 'Il bando è già associato al cliente selezionato.'
                            : e.message;
                    toast.error(message);
                    return false;
                });
        }
    };

    const initTenderClients = async () => {
        const result = await getClientsMatchingToTenderDropdown(selectedTender.id).catch((e) =>
            toast.error(e.message)
        );

        if (result.data) {
            setClients([...result.data]);
        }
    };

    useEffect(() => {
        initTenderClients();
    }, []);

    useEffect(() => {
        if (selectedData) {
            setIsEdit(true);
            reset({
                clientId: +clientId,
                status: selectedData.status,
                requiredAmount: selectedData.requiredAmount,
                obtainedAmount: selectedData.obtainedAmount,
                costEnteredByAbifin: selectedData.costEnteredByAbifin,
                payedCostForTender: selectedData.payedCostForTender,
                percentageAbifinRequest: selectedData.percentageAbifinRequest,
                percentageOfWiningTender: selectedData.percentageOfWiningTender,
                participationDate: selectedData.participationDate.split(' ')[0],
                note: selectedData.note
            });
        } else {
            setIsEdit(false);
            reset({
                clientId: '',
                status: '',
                requiredAmount: '',
                obtainedAmount: '',
                costEnteredByAbifin: '',
                payedCostForTender: '',
                percentageAbifinRequest: '',
                percentageOfWiningTender: '',
                participationDate: '',
                note: ''
            });
        }
    }, [selectedData]);

    return (
        <ModalComponent isOpen={isOpen}>
            {/* Modal header  */}
            <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                <h3 className="text-xl font-semibold text-white">Associa cliente</h3>
                <button
                    type="button"
                    className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={closeModel}>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    <div className="flex flex-col w-full space-y-4">
                        <div className="w-full input-field-group">
                            <label>Bando</label>
                            <input type="text" value={selectedTender?.name} disabled />
                            <p className="hidden">Test</p>
                        </div>
                        {isEdit ? (
                            <div className="w-full input-field-group">
                                <label htmlFor="requiredAmount">Cliente*</label>
                                <input value={selectedClientName} disabled />
                            </div>
                        ) : (
                            <div
                                className={`w-full input-field-group ${
                                    errors?.clientId ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="clientId">Cliente*</label>
                                {clients.length ? (
                                    <select
                                        id="clientId"
                                        name="clientId"
                                        {...register('clientId', { required: true })}
                                        disabled={clientId != null}>
                                        <option value="">Seleziona...</option>
                                        {clients.map((client) => (
                                            <option key={client.id} value={client.id}>
                                                {client.name}
                                            </option>
                                        ))}
                                    </select>
                                ) : null}
                                {errors?.clientId?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                        )}
                        <div
                            className={`w-full input-field-group ${
                                errors?.status ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="status">Stato*</label>
                            <select
                                id="status"
                                name="status"
                                {...register('status', { required: true })}>
                                <option value="">Seleziona...</option>
                                {/* <option value="Required">Richiesto</option> */}
                                <option value="Won">Vinto</option>
                                <option value="Waiting">In attesa</option>
                                <option value="Lost">Perso</option>
                                <option value="Reported">Segnalato</option>
                            </select>
                            {errors?.status?.type === 'required' && (
                                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                            )}
                        </div>
                        {status === 'Reported' && (
                            <div
                                className={`w-full input-field-group ${
                                    errors?.note ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="note">Note</label>
                                <textarea
                                    id="note"
                                    name="note"
                                    row="2"
                                    {...register('note', { maxLength: 500 })}
                                />
                                {errors?.note?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                                {errors?.note?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage(500)}</p>
                                )}
                            </div>
                        )}
                        <div className="flex flex-row space-x-4">
                            <div
                                className={`w-full input-field-group ${
                                    errors?.requiredAmount ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="requiredAmount">Importo richiesto (€)</label>
                                <input
                                    id="requiredAmount"
                                    name="requiredAmount"
                                    type="number"
                                    step=".01"
                                    placeholder="Importo richiesto (€)"
                                    {...register('requiredAmount', { required: false })}
                                />
                                {errors?.requiredAmount?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                            <div
                                className={`w-full input-field-group ${
                                    errors?.obtainedAmount ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="obtainedAmount">Importo ottenuto (€)</label>
                                <input
                                    id="obtainedAmount"
                                    name="obtainedAmount"
                                    type="number"
                                    step=".01"
                                    placeholder="Importo ottenuto (€)"
                                    {...register('obtainedAmount', { required: false })}
                                />
                                {errors?.obtainedAmount?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row space-x-4">
                            <div
                                className={`w-full input-field-group ${
                                    errors?.costEnteredByAbifin ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="requiredAmount">Rendicontazione (€)</label>
                                <input
                                    id="costEnteredByAbifin"
                                    name="costEnteredByAbifin"
                                    type="number"
                                    step=".01"
                                    placeholder="Rendicontazione (€)"
                                    {...register('costEnteredByAbifin', { required: false })}
                                />
                                {errors?.costEnteredByAbifin?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                            <div
                                className={`w-full input-field-group ${
                                    errors?.payedCostForTender ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="payedCostForTender">Fisso (€)</label>
                                <input
                                    id="payedCostForTender"
                                    name="payedCostForTender"
                                    type="number"
                                    step=".01"
                                    placeholder="Fisso (€)"
                                    {...register('payedCostForTender', { required: false })}
                                />
                                {errors?.payedCostForTender?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row space-x-4">
                            <div
                                className={`w-full input-field-group ${
                                    errors?.percentageAbifinRequest ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="percentageAbifinRequest">% (% di Abifin)</label>
                                <input
                                    id="percentageAbifinRequest"
                                    name="percentageAbifinRequest"
                                    type="number"
                                    // step=".01"
                                    placeholder="Importo richiesto (€)"
                                    {...register('percentageAbifinRequest', {
                                        required: false
                                    })}
                                />
                                {errors?.percentageAbifinRequest?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                            <div
                                className={`w-full input-field-group ${
                                    errors?.percentageOfWiningTender ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="percentageOfWiningTender">% di vittoria (%)</label>
                                <input
                                    id="percentageOfWiningTender"
                                    name="percentageOfWiningTender"
                                    type="number"
                                    // step=".01"
                                    placeholder="% di vittoria (%)"
                                    {...register('percentageOfWiningTender', {
                                        required: false
                                    })}
                                />
                                {errors?.percentageOfWiningTender?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                        </div>
                        <div
                            className={`w-full input-field-group ${
                                errors?.participationDate ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="participationDate">Data partecipazione*</label>
                            <input
                                id="participationDate"
                                name="participationDate"
                                type="date"
                                {...register('participationDate', { required: true })}
                            />
                            {errors?.participationDate?.type === 'required' && (
                                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                    <PrimaryButton type="submit">
                        {isEdit ? 'MODIFICA' : 'AGGIUNGI'} AZIONE
                    </PrimaryButton>
                    <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default AssociateClient;
