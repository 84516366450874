/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import AssociateClientWithConsultancy from '../../component/modal/AssociateClientWithConsultancy';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import CreateConsultancy from '../../component/modal/CreateConsultancy';
import CsvUpload from '../../component/modal/CsvUpload';
import PageHeader from '../../component/PageHeader';
import PrimaryButton from '../../component/PrimaryButton';
import RoundButton from '../../component/RoundButton';
import TableHeader from '../../component/TableHeader';
import { CONSULTANCY_TABLE_COLUMNS, MESSAGES } from '../../constants';
import {
    deleteConsultancy,
    getConsultancyListData,
    importCsvForConsultancy
} from '../../helper/services/apis/consultancy-services';
import { buildQueryChunck, getPaginateData } from '../../helper/utility';

function FinancialConsultancy() {
    const [columns, setColumns] = useState([...CONSULTANCY_TABLE_COLUMNS]);
    const [paginateData, setPaginateData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [isConsultancyCreateOpen, setIsConsultancyCreateOpen] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    const [consultancies, setConsultancies] = useState([]);
    const [selectedConsultancy, setSelectedConsultancy] = useState(null);
    const [isAssociateClientModalOpen, setIsAssociateClientModalOpen] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [isImportCsv, setIsImportCsv] = useState(false);

    const TYPE = 'Financial';

    const importBtnClickHandler = () => {
        setIsImportCsv(true);
    };

    const onCloseImportCsvModel = () => {
        setIsImportCsv(false);
    };

    const associateClient = (consultancy) => {
        setSelectedConsultancy({ ...consultancy });
        setIsAssociateClientModalOpen(true);
    };

    const editConsultancyOnClickHandler = (consultancy) => {
        setSelectedConsultancy(consultancy);
        setIsConsultancyCreateOpen(true);
    };

    const deleteConsultancyOnClickHandler = (consultancy) => {
        setSelectedConsultancy(consultancy);
        setIsConfirmOpen(true);
    };

    const getRows = (data) => {
        return data.map((consultancy) => ({
            className: '',
            cells: [
                { template: consultancy.createdAt },
                { template: consultancy.description },
                { template: consultancy.durationHours },
                { template: consultancy.cost },
                { template: consultancy.revenue },
                {
                    template:
                        consultancy.clientsCount > 0 ? (
                            <Link
                                to={`../clients?consultancyId=${consultancy.id}`}
                                className="text-primary-800">
                                {consultancy.clientsCount}
                            </Link>
                        ) : (
                            <span className="text-primary-800">0</span>
                        )
                },
                {
                    template: (
                        <div className="relative inline-block">
                            <RoundButton className="bg-grey-700 text-black peer text-mainBlack hover:bg-grey-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-grey-700 font-medium rounded-full text-sm p-2.5 text-center">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                    />
                                </svg>
                            </RoundButton>
                            <div className="absolute right-[0%] z-10 hidden w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => associateClient(consultancy)}
                                    aria-hidden="true">
                                    Associa cliente
                                </span>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => editConsultancyOnClickHandler(consultancy)}
                                    aria-hidden="true">
                                    Modifica consulenza
                                </span>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => deleteConsultancyOnClickHandler(consultancy)}
                                    aria-hidden="true">
                                    Elimina consulenza
                                </span>
                            </div>
                        </div>
                    )
                }
            ]
        }));
    };

    const generateQueryString = (qs) => {
        let queryString = qs;

        if (searchString.length) {
            queryString = buildQueryChunck(queryString, 'searchString', searchString);
        }

        const result = columns.find((column) => column.isSortable && column.sortStatus != null);

        if (result) {
            queryString = buildQueryChunck(queryString, 'sortColumn', result.column);
            queryString = buildQueryChunck(queryString, 'sortDirection', result.sortStatus);
        }

        return queryString;
    };

    const initConsultancy = async (queryString = '') => {
        setIsLoading(true);
        const qs = generateQueryString(queryString);
        const result = await getConsultancyListData('financial', qs).catch((e) =>
            toast.error(e.message)
        );
        setIsLoading(false);

        if (result.data.data) {
            setConsultancies([...result.data.data]);
            setPaginateData({ ...getPaginateData(result) });
        }
    };

    const handlePagination = (queryString) => {
        initConsultancy(queryString);
    };

    const onAssociateModalClose = () => {
        setSelectedConsultancy(null);
        setIsAssociateClientModalOpen(false);
    };

    const createConsultancyBtnClickHandler = () => {
        setIsConsultancyCreateOpen(true);
    };

    const onCloseModelHandler = () => {
        setSelectedConsultancy(null);
        setIsConsultancyCreateOpen(false);
        initConsultancy();
    };

    const confirmBtnClickHandler = async (consultancy) => {
        const result = await deleteConsultancy(consultancy.id).catch((e) => toast.error(e.message));
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            setSelectedConsultancy(null);
            setIsConfirmOpen(false);
            initConsultancy();
        }
    };

    const onCloseConfirmBtnClickHandler = (reload = false) => {
        setSelectedConsultancy(null);
        setIsConfirmOpen(false);
        if (reload) {
            initConsultancy();
        }
    };

    /**
     *
     * @param {*} value
     * Set search string
     */
    const onChangeSearchString = (event) => {
        setSearchString(event.target.value);
    };

    /**
     * Clear search string
     */
    const onClearSearchClick = () => {
        setSearchString('');
        // TODo : make api call
    };

    const onPressEnterForSearch = (event) => {
        if (event.which === 13 || event.key === 'Enter') {
            initConsultancy();
        }
    };

    /**
     *
     * @param {*} index : of column
     */
    const onChangeSortBtnClick = (index) => {
        let tempData = [...columns];
        const singleColumn = { ...tempData[index] };
        if (!singleColumn.sortStatus) {
            singleColumn.sortStatus = 'asc';
        } else if (singleColumn.sortStatus === 'asc') {
            singleColumn.sortStatus = 'desc';
        } else {
            singleColumn.sortStatus = null;
        }
        tempData = tempData.map((item) => ({ ...item, sortStatus: null }));
        tempData[index] = { ...singleColumn };
        setColumns([...tempData]);
    };

    const uploadCsv = async (file) => {
        const result = await importCsvForConsultancy({ csv: file }).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            onCloseImportCsvModel();
            initConsultancy();
        }
    };

    useEffect(() => {
        initConsultancy();
    }, [columns]);

    return (
        <section>
            {isLoading ? <Loader /> : null}

            {isImportCsv ? (
                <CsvUpload
                    isOpen={isImportCsv}
                    closeModel={onCloseImportCsvModel}
                    onSubmit={uploadCsv}
                />
            ) : null}

            <AssociateClientWithConsultancy
                isOpen={isAssociateClientModalOpen}
                selectedConsultancy={selectedConsultancy}
                closeModel={onAssociateModalClose}
            />

            <ConfirmationModal
                isOpen={isConfirmOpen}
                onConfirmAction={() => confirmBtnClickHandler(selectedConsultancy)}
                title="Eliminazione"
                message="Sei sicuro di voler procedere con l’eliminazione?"
                closeModel={onCloseConfirmBtnClickHandler}
            />

            {isConsultancyCreateOpen ? (
                <CreateConsultancy
                    isOpen={isConsultancyCreateOpen}
                    type={TYPE}
                    selectedConsultancy={selectedConsultancy}
                    closeModel={onCloseModelHandler}
                />
            ) : null}

            <PageHeader title="Consulenze finanziarie" importClickHandler={importBtnClickHandler}>
                <PrimaryButton onClick={createConsultancyBtnClickHandler}>
                    + CONSULENZA
                </PrimaryButton>
            </PageHeader>

            <TableHeader
                isSearch
                searchString={searchString}
                onChangeSearchString={onChangeSearchString}
                onClearSearchClick={onClearSearchClick}
                onPressEnter={onPressEnterForSearch}
            />

            <DataTable
                columns={columns}
                rows={getRows(consultancies)}
                paginateData={paginateData}
                handlePagination={handlePagination}
                sortBtnClick={onChangeSortBtnClick}
            />
        </section>
    );
}

export default FinancialConsultancy;
