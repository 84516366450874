/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';

export const getUsersListData = async (queryString) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.USER}s?${queryString}`);
    return result;
};

export const getUsersRolesDropdown = async () => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.ROLE}s-dropdown`);
    return result;
};

export const createNewUser = async (payload) => {
    const result = await axiosInstance.post(`v1/${API_ENDPOINT.USER}`, payload);
    return result;
};

export const updateUser = async (id, payload) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.USER}/${id}`, payload);
    return result;
};

export const deleteUser = async (id) => {
    const result = await axiosInstance.delete(`v1/${API_ENDPOINT.USER}/${id}`);
    return result;
};

export const userSendNewPassword = async (payload) => {
    const result = await axiosInstance.post(`v1/user-send-new-password`, payload);
    return result;
};
