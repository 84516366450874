/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Select, { createFilter } from 'react-select';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants';
import { getAtecoCodeDropdown } from '../../helper/services/apis/common-services';

function AtecoDropdown({
    register,
    name,
    label,
    config = {},
    errors,
    className,
    defaultValue,
    setValue
}) {
    const [options, setOptions] = useState([]);

    const initProvinve = async () => {
        const result = await getAtecoCodeDropdown().catch((e) => toast.error(e.message));

        if (result.data) {
            setOptions(result.data.map((item) => ({ value: item.id, label: item.name })));
        }
    };

    const handleChange = (event) => {
        if (event.length) {
            setValue(name, event);
        } else {
            setValue(name, '');
        }
    };

    useEffect(() => {
        initProvinve();
        handleChange(defaultValue);
    }, [defaultValue]);

    return (
        <div
            className={`w-full input-field-group ${className} ${
                errors[name] ? 'invalid-field' : ''
            }`}>
            <label>{label}</label>
            {options.length ? (
                <Select
                    defaultValue={[...defaultValue]}
                    isMulti
                    name={name}
                    onChange={handleChange}
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    filterOption={createFilter({
                        matchFrom: 'any',
                        stringify: (option) => `${option.label}`
                    })}
                />
            ) : null}

            <input type="hidden" {...register(name, { ...config })} />
            {errors[name]?.type === 'required' && (
                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
            )}
        </div>
    );
}

export default React.memo(AtecoDropdown);
