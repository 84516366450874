/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthContainer from '../component/AuthContainer';
import PrimaryButton from '../component/PrimaryButton';

import { authLogin } from '../helper/services/apis/auth-services';

import Storage from '../helper/services/common/storage-helper-services';

function Login() {
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmitHandler = async (loginData) => {
        const result = await authLogin(loginData).catch((e) => toast.error(e.message));
        if (result && result.ok) {
            Storage.set('auth-token', result.data.token);
            Storage.set(
                'profile',
                JSON.stringify({
                    id: result.data.user.id,
                    userName: result.data.user.userName,
                    email: result.data.user.email,
                    role: result.data.user.role.slug
                })
            );
            Storage.set('permission', JSON.stringify(result.data.permissions));
            navigate(`../dashboard`);
        }
    };

    return (
        <section className="flex w-screen h-screen justify-center items-center bg-grey-700">
            <AuthContainer>
                <form className="mt-12 space-y-6" onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="input-field">
                        <label htmlFor="username" className="block mb-2 text-sm font-bold">
                            Username
                        </label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            className="shadow-sm border border-grey-800 text-sm rounded-lg focus:ring-primary-800 focus:outline-primary-800 block w-full p-2.5"
                            placeholder="Username"
                            {...register('email', { required: true })}
                        />
                    </div>

                    <div className="input-field">
                        <label htmlFor="password" className="block mb-2 text-sm font-bold">
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            className="shadow-sm border border-grey-800 text-sm rounded-lg focus:ring-primary-800 focus:outline-primary-800 block w-full p-2.5"
                            placeholder="Password"
                            {...register('password', { required: true })}
                        />
                        <p className="text-right">
                            <Link
                                to="/forgot-password"
                                className="text-sm text-primary-800 font-bold">
                                Password dimentica?
                            </Link>
                        </p>
                    </div>

                    <PrimaryButton type="submit">ACCEDI</PrimaryButton>
                </form>
            </AuthContainer>
        </section>
    );
}

export default Login;
