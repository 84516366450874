/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';
import { toFormData } from '../../utility';

export const getTendersListData = async (queryString) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.TENDER}s?${queryString}`);
    return result;
};

export const getTenderById = async (id) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.TENDER}/${id}`);
    return result;
};

export const createNewTender = async (payload) => {
    const result = await axiosInstance.post(`v1/${API_ENDPOINT.TENDER}`, payload);
    return result;
};

export const updateTender = async (id, payload) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.TENDER}/${id}`, payload);
    return result;
};

export const updateTenderFields = async (id, payload) => {
    const result = await axiosInstance.patch(`v1/${API_ENDPOINT.TENDER}/${id}`, payload);
    return result;
};

export const deleteTender = async (id) => {
    const result = await axiosInstance.delete(`v1/${API_ENDPOINT.TENDER}/${id}`);
    return result;
};

export const archiveTender = async (payload) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.TENDER}-archive`, payload);
    return result;
};

export const yearReport = async (year) => {
    const result = await axiosInstance.get(`v1/year-report?year=${year}`);
    return result;
};

export const importCsvForConsultancy = async (payload) => {
    const result = await axiosInstance.post(
        `v1/${API_ENDPOINT.TENDER}-upload-csv`,
        await toFormData(payload)
    );
    return result;
};
