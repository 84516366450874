/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';
import { toFormData } from '../../utility';

export const getSectorsDropdown = async () => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.SECTOR}s`);
    return result;
};

export const getRegisteriesDropdown = async () => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.REGISTERIES}`);
    return result;
};

export const getProvinveDropdown = async () => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.PROVINCE}s`);
    return result;
};

export const getRegionDropdown = async () => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.REGION}s`);
    return result;
};

export const getRevenueDropdown = async () => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.REVENUE}s`);
    return result;
};

export const getAtecoCodeDropdown = async () => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.ATECO_CODE}s`);
    return result;
};

export const getClientsMatchingToTenderDropdown = async (tenderId) => {
    const result = await axiosInstance.get(`v1/tender-clients/${tenderId}`);
    return result;
};

export const getConsultancyDropdown = async (type = null, id = null) => {
    const result = await axiosInstance.get(`v1/consultancies-dropdown?type=${type}&clientId=${id}`);
    return result;
};

export const getClientDropdown = async (type = null, id = null) => {
    const result = await axiosInstance.get(`v1/client-dropdown?type=${type}&consultancyId=${id}`);
    return result;
};

export const getTendersMatchingToClientDropdown = async (id = null) => {
    const result = await axiosInstance.get(`v1/client-tender-dropdown?clientId=${id}`);
    return result;
};

export const getArchiveTendersDropdown = async (type = null) => {
    const result = await axiosInstance.get(`v1/archive-tender-dropdown?dataType=${type}`);
    return result;
};

export const getTenderClientsDropDown = async (id = null) => {
    const result = await axiosInstance.get(`v1/tender-client-dropdown?clientId=${id}`);
    return result;
};

export const uploadDocument = async (payload) => {
    const result = await axiosInstance.post(`v1/file-upload`, await toFormData(payload));
    return result;
};

export const updateClientTender = async (id, payload) => {
    const result = await axiosInstance.put(`v1/client-tenders/${id}`, payload);
    return result;
};

export const deleteTenderAssociation = async (id) => {
    const result = await axiosInstance.delete(`v1/client-tender-association/${id}`);
    return result;
};

export const deleteClientConsultancy = async (id) => {
    const result = await axiosInstance.delete(`v1/client-consultancy-association/${id}`);
    return result;
};

export const getClientOrTenderMatchingDropdownValues = async (isClient, clientId, tenderId) => {
    let result = null;
    if (isClient && clientId) {
        result = await axiosInstance.get(`v1/client-matching-tender/${clientId}`);
    } else if (!isClient && tenderId) {
        result = await axiosInstance.get(`v1/tender-matching-client/${tenderId}`);
    }
    return result;
};

export const addManualMarching = async (payload) => {
    const result = await axiosInstance.post(`v1/manual-match`, payload);
    return result;
};
