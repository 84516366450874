export default class Storage {
    // STEP : Set item in local storage
    static set(key, value) {
        localStorage.setItem(key, value);
    }

    // STEP : Retrieve item from storage
    static get(key) {
        return localStorage.getItem(key);
    }

    // STEP : Clear storage
    static clear() {
        localStorage.clear();
    }
}
