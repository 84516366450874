/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import CreateUser from '../../component/modal/CreateUser';
import PageHeader from '../../component/PageHeader';
import PrimaryButton from '../../component/PrimaryButton';
import RoundButton from '../../component/RoundButton';
import TableHeader from '../../component/TableHeader';
import { MESSAGES, USER_TABLE_COLUMNS } from '../../constants';
import { deleteUser, getUsersListData } from '../../helper/services/apis/user-services';
import { buildQueryChunck, getPaginateData, logoutProcess } from '../../helper/utility';

function Users() {
    const [isUserCreateOpen, setIsUserCreateOpen] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [columns, setColumns] = useState([...USER_TABLE_COLUMNS]);
    const [paginateData, setPaginateData] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchString, setSearchString] = useState('');

    const editUserOnClickHandler = (user) => {
        setSelectedUser(user);
        setIsUserCreateOpen(true);
    };

    const deleteUserOnClickHandler = (user) => {
        setSelectedUser(user);
        setIsConfirmOpen(true);
    };

    const getRows = (rows) => {
        return rows.map((user) => ({
            className: '',
            cells: [
                { template: user.createdAt },
                { template: user.userName },
                { template: user.email },
                { template: user.role.name },
                {
                    template:
                        user.role.slug !== 'super-admin' ? (
                            <div className="relative inline-block">
                                <RoundButton className="bg-grey-700 text-black peer text-mainBlack hover:bg-grey-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-grey-700 font-medium rounded-full text-sm p-2.5 text-center">
                                    <svg
                                        className="w-6 h-6"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                        />
                                    </svg>
                                </RoundButton>
                                <div className="absolute right-[0%] z-10 hidden w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                                    <span
                                        className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                        onClick={() => editUserOnClickHandler(user)}
                                        aria-hidden="true">
                                        Modifica utente
                                    </span>
                                    <span
                                        className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                        onClick={() => deleteUserOnClickHandler(user)}
                                        aria-hidden="true">
                                        Elimina utente
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className="ml-4">
                                <svg
                                    width="14"
                                    height="16"
                                    viewBox="0 0 14 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M2 7V5C2 3.67392 2.52678 2.40215 3.46447 1.46447C4.40215 0.526784 5.67392 0 7 0C8.32608 0 9.59785 0.526784 10.5355 1.46447C11.4732 2.40215 12 3.67392 12 5V7C12.5304 7 13.0391 7.21071 13.4142 7.58579C13.7893 7.96086 14 8.46957 14 9V14C14 14.5304 13.7893 15.0391 13.4142 15.4142C13.0391 15.7893 12.5304 16 12 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V9C0 8.46957 0.210714 7.96086 0.585786 7.58579C0.960859 7.21071 1.46957 7 2 7ZM10 5V7H4V5C4 4.20435 4.31607 3.44129 4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2C7.79565 2 8.55871 2.31607 9.12132 2.87868C9.68393 3.44129 10 4.20435 10 5Z"
                                        fill="#666666"
                                    />
                                </svg>
                            </div>
                        )
                }
            ]
        }));
    };

    const generateQueryString = (qs) => {
        // consultancyId, tenderId, clientId;
        let queryString = qs;
        if (searchString.length) {
            queryString = buildQueryChunck(queryString, 'searchString', searchString);
        }

        const result = columns.find((column) => column.isSortable && column.sortStatus != null);
        if (result) {
            queryString = buildQueryChunck(queryString, 'sortColumn', result.column);
            queryString = buildQueryChunck(queryString, 'sortDirection', result.sortStatus);
        }

        return queryString;
    };

    const getUsersList = async (queryString = '') => {
        const qs = generateQueryString(queryString);
        const result = await getUsersListData(qs).catch((e) => toast.error(e.message));
        if (result.data.data) {
            setUsers([...result.data.data]);
            setPaginateData({ ...getPaginateData(result) });
        }
    };

    const handlePagination = (queryString) => {
        getUsersList(queryString);
    };

    const createUserBtnClickHandler = () => {
        setIsUserCreateOpen(true);
    };

    const onCloseModelHandler = () => {
        setIsUserCreateOpen(false);
        if (selectedUser) {
            setSelectedUser(null);
            logoutProcess();
        } else {
            getUsersList();
        }
    };

    const confirmBtnClickHandler = async (user) => {
        const result = await deleteUser(user.id).catch((e) => toast.error(e.message));
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            setSelectedUser(null);
            setIsConfirmOpen(false);
            getUsersList();
        }
    };

    const closeConfirmClickHandler = () => {
        setIsConfirmOpen(false);
    };

    /**
     *
     * @param {*} value
     * Set search string
     */
    const onChangeSearchString = (event) => {
        setSearchString(event.target.value);
    };

    /**
     * Clear search string
     */
    const onClearSearchClick = () => {
        setSearchString('');
    };

    const onPressEnterForSearch = (event) => {
        if (event.which === 13 || event.key === 'Enter') {
            getUsersList();
        }
    };

    /**
     *
     * @param {*} index : of column
     */
    const onChangeSortBtnClick = (index) => {
        let tempData = [...columns];
        const singleColumn = { ...tempData[index] };
        if (!singleColumn.sortStatus) {
            singleColumn.sortStatus = 'asc';
        } else if (singleColumn.sortStatus === 'asc') {
            singleColumn.sortStatus = 'desc';
        } else {
            singleColumn.sortStatus = null;
        }
        tempData = tempData.map((item) => ({ ...item, sortStatus: null }));
        tempData[index] = { ...singleColumn };
        setColumns([...tempData]);
    };

    useEffect(() => {
        getUsersList();
    }, [columns]);

    // useEffect(() => {
    //     getUsersList();
    // }, []);

    return (
        <section>
            <ConfirmationModal
                isOpen={isConfirmOpen}
                onConfirmAction={() => confirmBtnClickHandler(selectedUser)}
                title="Vuoi eliminare l’utente?"
                message="Sei sicuro di voler eliminare l’utente? Una volta eliminato non sarà più possibile recuperarlo."
                closeModel={closeConfirmClickHandler}
            />
            <CreateUser
                isOpen={isUserCreateOpen}
                selectedUser={selectedUser}
                closeModel={onCloseModelHandler}
            />

            <PageHeader title="Utenti">
                <PrimaryButton onClick={createUserBtnClickHandler}>+ UTENTE</PrimaryButton>
            </PageHeader>

            <TableHeader
                isSearch
                searchString={searchString}
                onChangeSearchString={onChangeSearchString}
                onClearSearchClick={onClearSearchClick}
                onPressEnter={onPressEnterForSearch}
            />
            <DataTable
                columns={columns}
                rows={getRows(users)}
                paginateData={paginateData}
                handlePagination={handlePagination}
                sortBtnClick={onChangeSortBtnClick}
            />
        </section>
    );
}

export default Users;
