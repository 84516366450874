import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import Login from './pages/Login';
import Dashboard from './pages/dashboard/Dashboard';
import { ConfigContextProvider } from './store/ConfigContext';
import ForgotPassword from './pages/ForgotPassword';
import Clients from './pages/dashboard/Clients';
import Tenders from './pages/dashboard/Tenders';
import Client from './pages/dashboard/Client';
import Tender from './pages/dashboard/Tender';
import Users from './pages/dashboard/Users';
import FinancialConsultancy from './pages/dashboard/FinancialConsultancy';
import MarketingConsultancy from './pages/dashboard/MarketingConsultancy';
import Report from './pages/dashboard/Report';

/**
 * This component is responsible for authentication
 */
function RequireAuth() {
    // const token = Storage.get('auth-token');
    // if (!token) {
    //     window.location.href = '/login';
    //     return null;
    // }
    return (
        <Dashboard>
            <Outlet />
        </Dashboard>
    );
}

console.log('Environment : ', process.env.NODE_ENV);

function App() {
    return (
        <div className="app-container relative">
            <ConfigContextProvider>
                <BrowserRouter>
                    <Routes>
                        <Route exact path="login" element={<Login />} />
                        <Route exact path="forgot-password" element={<ForgotPassword />} />
                        <Route exact path="dashboard" element={<RequireAuth />}>
                            <Route
                                exact
                                path=""
                                element={<Navigate replace to="/dashboard/clients" />}
                            />
                            <Route exact path="clients" element={<Clients />} />
                            <Route exact path="client/:id" element={<Client />} />
                            <Route exact path="tenders" element={<Tenders />} />
                            <Route exact path="tender/:id" element={<Tender />} />
                            <Route exact path="users" element={<Users />} />
                            <Route exact path="report" element={<Report />} />
                            <Route
                                exact
                                path="financial-consultancy"
                                element={<FinancialConsultancy />}
                            />
                            <Route
                                exact
                                path="marketing-consultancy"
                                element={<MarketingConsultancy />}
                            />
                            {/* <Route exact path="evaluations" element={<EvaluationListing />} />
                            <Route exact path="questions" element={<Questions />} />
                            <Route exact path="plans" element={<Plans />} />
                            <Route exact path="client/:id" element={<Client />} />
                            <Route exact path="profile" element={<Setting />} />
                            <Route exact path="evaluation/:id" element={<Evaluation />} /> */}
                            <Route exact path="*" element={<Navigate replace to="/login" />} />
                        </Route>
                        <Route exact path="*" element={<Navigate replace to="/login" />} />
                    </Routes>
                </BrowserRouter>
            </ConfigContextProvider>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName="black-background"
                theme="dark"
            />
        </div>
    );
}

export default App;
