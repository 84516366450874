/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React from 'react';
import Logo from '../assets/images/logo.png';

function AppLogo({ width = '64px' }) {
    return <img src={Logo} alt="logo" className={`w-[${width}] h-[${width}]`} width={width} />;
}

export default AppLogo;
