/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
    addManualMarching,
    getClientOrTenderMatchingDropdownValues
} from '../../helper/services/apis/common-services';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';
import { MESSAGES } from '../../constants';
import Loader from '../Loader';

function MatchingClientTender({
    isOpen = false,
    closeModel = null,
    clientId = null,
    tenderId = null
}) {
    const [options, setOptions] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const retrieveOptions = async () => {
        const result = await getClientOrTenderMatchingDropdownValues(
            !!clientId,
            clientId,
            tenderId
        );

        if (result.data) {
            setOptions(result.data.map((item) => ({ value: item.id, label: item.name })));
        }
    };

    const handleChange = (event) => {
        setSelectedId(event.value);
    };

    const handleSubmit = async (tender) => {
        let payload = null;
        setIsLoading(true);
        if (clientId) {
            payload = {
                clientId,
                tenderId: selectedId,
                action: 'in'
            };
        } else {
            payload = {
                tenderId,
                clientId: selectedId,
                action: 'in'
            };
        }
        const result = await addManualMarching(payload).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            setIsLoading(false);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            closeModel(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        retrieveOptions();
    }, []);

    useEffect(() => {
        console.log(options);
    }, [options]);

    return (
        <ModalComponent isOpen={isOpen}>
            {isLoading ? <Loader /> : null}
            {/* Modal header  */}
            <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                <h3 className="text-xl font-semibold text-white">Aggiungi al matching</h3>
                <button
                    type="button"
                    className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => closeModel()}>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>

            {/* { !selectedClient  } */}
            {/* Modal Body */}
            <div className="p-6">
                <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                    <div className="w-full input-field-group">
                        <label htmlFor="filter">{clientId ? 'Bandi' : 'Clienti'}</label>
                        {options.length && (
                            <Select
                                name="filter"
                                onChange={handleChange}
                                options={options}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        )}
                        {/* <input type="hidden" {...register(name, { ...config })} /> */}
                    </div>
                </div>
            </div>

            {/* Modal Footer */}
            <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                <PrimaryButton onClick={handleSubmit}>AGGIUNGI</PrimaryButton>
                <SecondaryButton onClick={() => closeModel(true)}>ANNULLA</SecondaryButton>
            </div>
        </ModalComponent>
    );
}

export default MatchingClientTender;
