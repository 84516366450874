/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';

import { logoutProcess } from '../../helper/utility';
import AppLogo from '../../component/AppLogo';
import SideMenu from '../../component/Layout/SideMenu';

function Dashboard() {
    const location = useLocation();
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

    const checkIsActive = (path) => {
        return location.pathname.includes(path);
    };

    const logoutBtnOnPressHandler = () => {
        logoutProcess();
    };

    const handleToggleMobileSidebar = () => {
        setIsMobileSidebarOpen(!isMobileSidebarOpen);
    };

    return (
        // <div className="dashboard">
        //     <header className="flex justify-between dashboard--header bg-primary-900 items-center">
        //         <div className="px-6 py-1.5 h-16">
        //             <img className="h-full" src={Logo} alt="logo" />
        //         </div>
        //         <div className="text-white text-2xl">WINNING WOMAN INSTITUTE</div>
        //         <div className="flex space-x-2">
        //             <div className="mt-2">
        //                 <Link to="profile">
        //                     <img src={gear} alt="Setting" className="pb-2 px-3.5 h-11" />
        //                 </Link>
        //             </div>
        //             <div className="mt-2">
        //                 <img
        //                     src={logout}
        //                     alt="Setting"
        //                     className="pb-2 px-3.5 h-11 cursor-pointer"
        //                     onClick={logoutBtnOnPressHandler}
        //                     aria-hidden="true"
        //                 />
        //             </div>
        //         </div>
        //     </header>
        //     <aside className="dashboard--nav bg-white text-[#A4A4A4]">
        //         <nav>
        //             <ul className="px-3 py-4 divide-y">
        //                 <li className="py-4 text-center cursor-pointer">
        //                     <Link to="clients">
        //                         <img
        //                             src={checkIsActive('clients') ? activeClient : inActiveClient}
        //                             alt="Clienti"
        //                             className="pb-2 px-3.5"
        //                         />
        //                         <span className="text-xs hover:text-primary-900">Clienti</span>
        //                     </Link>
        //                 </li>
        //                 <li className="py-4 text-center cursor-pointer">
        //                     <Link to="evaluations">
        //                         <img
        //                             src={
        //                                 checkIsActive('evaluations')
        //                                     ? activeValutazioni
        //                                     : inActiveValutazioni
        //                             }
        //                             alt="Valutazioni"
        //                             className="pb-2 px-3.5"
        //                         />
        //                         <span className="text-xs hover:text-primary-900">Valutazioni</span>
        //                     </Link>
        //                 </li>
        //                 <li className="py-4 text-center cursor-pointer">
        //                     <Link to="questions">
        //                         <img
        //                             src={
        //                                 checkIsActive('questions') ? activeDomande : inActiveDomande
        //                             }
        //                             alt="Domande"
        //                             className="pb-2 px-3.5"
        //                         />
        //                         <span className="text-xs hover:text-primary-900">Domande</span>
        //                     </Link>
        //                 </li>
        //                 <li className="py-4 text-center cursor-pointer">
        //                     <Link to="plans">
        //                         <img
        //                             src={checkIsActive('plans') ? activeAcquisti : inActiveAcquisti}
        //                             alt="Acquisti"
        //                             className="pb-2 px-3.5"
        //                         />
        //                         <span className="text-xs hover:text-primary-900">Acquisti</span>
        //                     </Link>
        //                 </li>
        //             </ul>
        //         </nav>
        //     </aside>
        //     <main className="dashboard--main bg-[#F5F3F1] text-[#575757] px-6 py-8">
        //         <div className="max-w-7xl mx-auto">
        //             <Outlet />
        //         </div>
        //     </main>
        // </div>
        <div>
            <div className="flex h-screen antialiased bg-white">
                <SideMenu className="hidden md:block" />

                <div className="flex-1 h-full overflow-x-hidden overflow-y-auto relative">
                    {/* Mobile Header */}
                    <header className="md:hidden flex justify-between items-center p-4 h-[72px]">
                        <AppLogo width="36px" />
                        <button
                            onClick={() => handleToggleMobileSidebar()}
                            className="p-1 transition-colors duration-200 rounded-md text-primary-lighter bg-primary-50 hover:text-white hover:bg-primary-800 focus:outline-none focus:ring">
                            <span className="sr-only">Open main manu</span>
                            <span aria-hidden="true">
                                <svg
                                    className="w-8 h-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            </span>
                        </button>
                    </header>

                    {isMobileSidebarOpen ? (
                        <div className="md:hidden h-screen w-screen  absolute top-[72px] left-0">
                            <div className="flex flex-row-reverse h-full w-full bg-mainBlack opacity-80 absolute top-0 left-0" />
                            <div className="flex flex-row-reverse h-full">
                                <SideMenu className="z-10" />
                            </div>
                        </div>
                    ) : null}

                    <main className="p-4">
                        <Outlet />
                    </main>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
