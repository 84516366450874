/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { CLASSIFICATIONS, MESSAGES } from '../../constants';

function ClassificationDropdown({
    register,
    name,
    config = {},
    errors,
    className,
    defaultValue,
    setValue,
    multiple = false
}) {
    const [options, setOptions] = useState([]);

    const handleChange = (event) => {
        if (event && event.length) {
            setValue(name, event);
        } else {
            setValue(name, '');
        }
    };

    useEffect(() => {
        setOptions([...CLASSIFICATIONS]);
        if (setValue) {
            handleChange(defaultValue);
        }
    }, [defaultValue]);

    return (
        <div
            className={`w-full input-field-group ${className} ${
                errors[name] ? 'invalid-field' : ''
            }`}>
            <label htmlFor="classificazione">Classificazione{config.required ? '*' : ''}</label>
            {multiple ? (
                options.length ? (
                    <>
                        <Select
                            defaultValue={[...defaultValue]}
                            isMulti
                            name={name}
                            onChange={handleChange}
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                        <input type="hidden" {...register(name, { ...config })} />
                    </>
                ) : null
            ) : options.length ? (
                <select
                    id="classificazione"
                    name="classificazione"
                    {...register(name, { ...config })}>
                    <option value="">Seleziona...</option>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            ) : null}

            {errors[name]?.type === 'required' && (
                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
            )}
        </div>
    );
}

export default ClassificationDropdown;
