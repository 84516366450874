/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { INIT_TENDER, MESSAGES, MULTI_CAP_REGEX } from '../../constants';
import { createNewTender, updateTender } from '../../helper/services/apis/tender-services';
import AtecoDropdown from '../dropdown/AtecoDropdown';
import ProvinveDropdown from '../dropdown/ProvinveDropdown';
import RegionDropDown from '../dropdown/RegionDropDown';
import RegisteriesDropdown from '../dropdown/RegisteriesDropdown';
import RevenueDropDown from '../dropdown/RevenueDropDown';
import SectorsDropdown from '../dropdown/SectorsDropdown';
import Loader from '../Loader';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';
import ClassificationDropdown from '../dropdown/ClassificationDropdown';

function CreateTender({ isOpen = false, closeModel = null, selectedTender = null }) {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors }
    } = useForm();
    const tenderType = watch('type');

    const [isLoading, setIsLoading] = useState(false);
    // const [atecoCodes, setAtecoCodes] = useState([]);
    const [includedAtecoCodes, setIncludedAtecoCodes] = useState([]);
    const [excludedAtecoCodes, setExcludedAtecoCodes] = useState([]);
    const [sector, setSector] = useState([]);
    const [province, setProvince] = useState([]);
    const [registery, setRegistery] = useState([]);
    const [region, setRegion] = useState([]);

    const onSubmitHandler = async (data) => {
        let registeries = [];
        let sectors = [];

        const atecoCodes = {};

        setIsLoading(true);
        if (data.type === 'Company' || data.type === 'All') {
            if (data.includedAtecoCodes.length) {
                data.includedAtecoCodes.forEach((item) => {
                    atecoCodes[item.value] = { include_or_exclude: 'Include' };
                });
            }
            if (data.excludedAtecoCodes.length) {
                data.excludedAtecoCodes.forEach((item) => {
                    atecoCodes[item.value] = { include_or_exclude: 'Exclude' };
                });
            }
        } else {
            data.revenueId = null;
            // data.excludeAtecoCodes = null;
        }

        if (data.type === 'Association' || data.type === 'All') {
            if (data.registeries.length) {
                registeries = data.registeries.map((code) => code.value);
            }
            if (data.sectors.length) {
                sectors = data.sectors.map((code) => code.value);
            }
        } else {
            data.maximumRequested = null;
        }

        data.atecoCodes = atecoCodes;

        let regions = [];
        if (data.regions.length) {
            regions = data.regions.map((code) => code.value);
        }
        data.regions = regions;

        data.registeries = registeries;
        data.sectors = sectors;

        let provinces = [];
        if (data.provinces.length) {
            provinces = data.provinces.map((code) => code.value);
        }
        data.provinces = provinces;

        if (selectedTender) {
            const result = await updateTender(data.id, data).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            setIsLoading(false);
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                closeModel();
            }
        } else {
            const result = await createNewTender(data).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            setIsLoading(false);
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                closeModel();
            }
        }
    };

    // useEffect(() => {
    //     if (selectedTender) {
    //         let codes = [];
    //         if (selectedCode.atecoCodes?.length) {
    //             codes = selectedTender.atecoCodes.map((item) => ({
    //                 value: item.id,
    //                 label: item.name
    //             }));
    //             console.log(codes);
    //         }
    //         setSelectedCode([...codes]);
    //         reset({
    //             id: selectedTender.id,
    //             type: selectedTender.type,
    //             name: selectedTender.name,
    //             tenderFor: selectedTender.tenderFor,
    //             deadline: selectedTender.deadline,
    //             ceiling: selectedTender.ceiling,
    //             textualCeiling: selectedTender.textualCeiling,
    //             excludeAtecoCodes: selectedTender.excludeAtecoCodes,
    //             revenueId: selectedTender.revenueId,
    //             registeries: selectedTender.registeries,
    //             sectors: selectedTender.sectors,
    //             maximumRequested: selectedTender.maximumRequested,
    //             regions: selectedTender.regions,
    //             postalCode: selectedTender.postalCode,
    //             locationForMatching: selectedTender.locationForMatching,
    //             provinces: selectedTender.provinces,
    //             classification: selectedTender.classification,
    //             loanPercentage: selectedTender.loanPercentage,
    //             entity: selectedTender.entity,
    //             tenderUrl: selectedTender.tenderUrl,
    //             femaleMajority: selectedTender.femaleMajority,
    //             youthMajority: selectedTender.youthMajority,
    //             minimumYearsOfActivity: selectedTender.minimumYearsOfActivity,
    //             archive: selectedTender.archive,
    //             additionalNotes: selectedTender.additionalNotes,
    //             atecoCodes: codes
    //         });

    //         setDefaultRevenue(selectedTender.revenueId);
    //     } else {
    //         reset({
    //             type: '',
    //             name: '',
    //             tenderFor: '',
    //             deadline: '',
    //             ceiling: '',
    //             textualCeiling: '',
    //             excludeAtecoCodes: '',
    //             postalCode: '',
    //             locationForMatching: '',
    //             classification: '',
    //             loanPercentage: '',
    //             entity: '',
    //             tenderUrl: '',
    //             femaleMajority: '',
    //             youthMajority: '',
    //             minimumYearsOfActivity: '',
    //             additionalNotes: '',
    //             atecoCodes: '',
    //             revenueId: '',
    //             regions: '',
    //             provinces: '',
    //             maximumRequested: '',
    //             registeries: '',
    //             sectors: ''
    //         });
    //     }
    // }, []);

    const setTender = (tender) => {
        if (tender) {
            // TODO : Set Defaslt values
            setIncludedAtecoCodes([
                ...tender.includedAtecoCodes.map((item) => ({ value: item.id, label: item.name }))
            ]);
            setExcludedAtecoCodes([
                ...tender.excludedAtecoCodes.map((item) => ({ value: item.id, label: item.name }))
            ]);

            setSector([...tender.sectors.map((item) => ({ value: item.id, label: item.name }))]);
            setRegion([...tender.regions.map((item) => ({ value: item.id, label: item.name }))]);
            setProvince([
                ...tender.provinces.map((item) => ({ value: item.id, label: item.name }))
            ]);
            setRegistery([
                ...tender.registeries.map((item) => ({ value: item.id, label: item.name }))
            ]);

            const data = {
                ...tender,
                // excludeAtecoCodes: tender.excludeAtecoCodes != null ? tender.excludeAtecoCodes.toString() : null,
                revenueId: tender.revenueId != null ? tender.revenueId.toString() : null,
                registeries: tender.registeries != null ? tender.registeries.toString() : null,
                sectors: tender.sectors != null ? tender.sectors.toString() : null,
                regions: tender.regions != null ? tender.regions.toString() : null,
                provinces: tender.provinces != null ? tender.provinces.toString() : null,
                isAnalyzed: tender.isAnalyzed != null ? tender.isAnalyzed.toString() : null
            };
            reset({ ...data });
        } else {
            reset({ ...INIT_TENDER });
        }
    };

    useEffect(() => {
        if (selectedTender) {
            setTender(selectedTender);
        } else {
            setTender(null);
        }
    }, [selectedTender]);

    return (
        <ModalComponent isOpen={isOpen} size="max-w-6xl">
            {isLoading ? <Loader /> : null}
            {/* Modal header  */}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                    <h3 className="text-xl font-semibold text-white">
                        {`${selectedTender ? 'Modifica' : 'Nuovo'}`} bando
                    </h3>
                    <button
                        type="button"
                        className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                        data-modal-toggle="defaultModal"
                        onClick={closeModel}>
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    {/* section one */}
                    <div className="border-b border-grey-800">
                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div className="my-4">
                                <p className="font-bold w-full">Bando per*</p>
                                <div className="flex w-full flex-wrap space-x-4">
                                    <div className="flex items-center space-x-2">
                                        <input
                                            id="inline-radio"
                                            type="radio"
                                            value="Company"
                                            name="tender-type"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            {...register('type', { required: true })}
                                        />
                                        <label htmlFor="tender-type">Aziende</label>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <input
                                            id="inline-radio"
                                            type="radio"
                                            value="Association"
                                            name="tender-type"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            {...register('type', { required: true })}
                                        />
                                        <label htmlFor="tender-type">Associazioni</label>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <input
                                            id="inline-radio"
                                            type="radio"
                                            value="All"
                                            name="tender-type"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            {...register('type', { required: true })}
                                        />
                                        <label htmlFor="tender-type">Aziende e associazioni</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div
                                className={`w-full input-field-group ${
                                    errors?.name ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="name">Titolo bando*</label>
                                <input
                                    type="text"
                                    placeholder="Titolo bando"
                                    id="name"
                                    name="name"
                                    {...register('name', { required: true, maxLength: 255 })}
                                />
                                {errors?.name?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                                {errors?.name?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            {/* <div
                                className={`w-full input-field-group md:w-1/3 ${
                                    errors?.tenderFor ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="tenderFor">Bando per*</label>
                                <input
                                    type="text"
                                    placeholder="Bando per"
                                    id="tenderFor"
                                    name="tenderFor"
                                    {...register('tenderFor', { required: true, maxLength: 255 })}
                                />
                                {errors?.tenderFor?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                                {errors?.tenderFor?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div> */}

                            <div
                                className={`w-full input-field-group md:w-1/3 ${
                                    errors?.deadline ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="deadline">Scadenza*</label>
                                <input
                                    type="date"
                                    id="deadline"
                                    onKeyDown={(e) => e.preventDefault()}
                                    name="deadline"
                                    {...register('deadline', { required: true, maxLength: 255 })}
                                />
                                {errors?.deadline?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                                {errors?.deadline?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/3 ${
                                    errors?.ceiling ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="ceiling">Plafond</label>
                                <input
                                    type="number"
                                    placeholder="Plafond"
                                    step=".01"
                                    id="ceiling"
                                    name="ceiling"
                                    {...register('ceiling', { maxLength: 21 })}
                                />
                                {errors?.ceiling?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage(21)}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/3 ${
                                    errors?.textualCeiling ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="textualCeiling">Plafond testuale</label>
                                <input
                                    type="text"
                                    placeholder="Plafond"
                                    id="textualCeiling"
                                    name="textualCeiling"
                                    {...register('textualCeiling', { maxLength: 255 })}
                                />
                                {errors?.textualCeiling?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* section for company */}
                    {(tenderType === 'Company' || tenderType === 'All') && (
                        <div style={{ backgroundColor: '#3062AC13' }} className="p-4">
                            <p className="font-bold text-primary-800 w-full">PER AZIENDE</p>
                            <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                                {/* // TODO : Atecocode */}
                                <AtecoDropdown
                                    register={register}
                                    label="Includi codici Ateco"
                                    name="includedAtecoCodes"
                                    errors={errors}
                                    className="md:w-[49%]"
                                    defaultValue={includedAtecoCodes}
                                    setValue={setValue}
                                />

                                <AtecoDropdown
                                    register={register}
                                    label="Escludi codici Ateco"
                                    name="excludedAtecoCodes"
                                    errors={errors}
                                    className="md:w-[49%]"
                                    defaultValue={excludedAtecoCodes}
                                    setValue={setValue}
                                />

                                {/* <div className="my-4">
                                    <p className="font-bold w-full">Escludi codici ATECO?</p>
                                    <div className="flex w-full flex-wrap space-x-4">
                                        <div className="flex items-center space-x-2">
                                            <input
                                                id="excludeAtecoCodes"
                                                type="radio"
                                                value="1"
                                                name="excludeAtecoCodes"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                {...register('excludeAtecoCodes')}
                                            />
                                            <label htmlFor="yes">Si escludi</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                id="excludeAtecoCodes"
                                                type="radio"
                                                value="0"
                                                name="excludeAtecoCodes"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                {...register('excludeAtecoCodes')}
                                            />
                                            <label htmlFor="no">No includi</label>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                                <RevenueDropDown
                                    register={register}
                                    name="revenueId"
                                    errors={errors}
                                    setValue={setValue}
                                />
                            </div>
                        </div>
                    )}

                    {/* Section for assoication */}
                    {(tenderType === 'Association' || tenderType === 'All') && (
                        <div style={{ backgroundColor: '#3062AC13' }} className="p-4">
                            <p className="font-bold text-primary-800 w-full">PER ASSOCIAZIONI</p>

                            <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                                <RegisteriesDropdown
                                    register={register}
                                    name="registeries"
                                    errors={errors}
                                    className="md:w-1/2"
                                    setValue={setValue}
                                    defaultValue={registery}
                                    multiple
                                />
                                <SectorsDropdown
                                    register={register}
                                    name="sectors"
                                    errors={errors}
                                    config={{ required: false }}
                                    className="md:w-1/2"
                                    setValue={setValue}
                                    defaultValue={sector}
                                    multiple
                                />
                            </div>

                            <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                                <div
                                    className={`w-full input-field-group ${
                                        errors?.maximumRequested ? 'invalid-field' : ''
                                    }`}>
                                    <label htmlFor="maximumRequested">Massimo richiedibile</label>
                                    <input
                                        type="text"
                                        id="maximumRequested"
                                        name="maximumRequested"
                                        {...register('maximumRequested')}
                                    />
                                    {errors?.maximumRequested?.type === 'maxLength' && (
                                        <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Section Six */}
                    <div className="border-b border-grey-800">
                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <RegionDropDown
                                register={register}
                                name="regions"
                                errors={errors}
                                className="md:w-1/3"
                                setValue={setValue}
                                defaultValue={region}
                                multiple
                            />
                            <div
                                className={`w-full input-field-group md:w-1/3 ${
                                    errors?.postalCode ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="cap">CAP</label>
                                <input
                                    type="text"
                                    id="cap"
                                    name="cap"
                                    placeholder="CAP"
                                    {...register('postalCode', {
                                        pattern: MULTI_CAP_REGEX
                                    })}
                                />
                                {errors?.postalCode?.type === 'pattern' && <p>CAP non è valido</p>}
                            </div>
                            <div className="w-full input-field-group md:w-1/3">
                                <label htmlFor="locationForMatching">
                                    Quale sede vuoi utilizzare per il matching?
                                </label>
                                <select
                                    id="locationForMatching"
                                    name="locationForMatching"
                                    {...register('locationForMatching')}>
                                    <option value="">Seleziona...</option>
                                    <option value="legale">Sede legale</option>
                                    <option value="operative">Sede operativa</option>
                                </select>
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <ProvinveDropdown
                                register={register}
                                name="provinces"
                                errors={errors}
                                className="md:w-1/3"
                                setValue={setValue}
                                defaultValue={province}
                                multiple
                            />

                            {/* <div className={`w-full input-field-group md:w-1/3 ${errors?.classification}`}> */}
                            {/* <label htmlFor="classification">Classificazione</label>
                                <input
                                    type="text"
                                    id="classification"
                                    name="classification"
                                    placeholder="Classificazione"
                                    {...register('classification', { maxLength: 255 })}
                                />
                                {errors?.classification?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )} */}
                            <ClassificationDropdown
                                register={register}
                                name="classification"
                                errors={errors}
                                className="md:w-1/3"
                                setValue={setValue}
                                config={{ required: true }}
                            />
                            {/* </div> */}

                            <div
                                className={`w-full input-field-group md:w-1/3 ${
                                    errors?.loanPercentage ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="loanPercentage">Percentuale finanziamento</label>
                                <input
                                    type="number"
                                    step=".01"
                                    id="loanPercentage"
                                    name="loanPercentage"
                                    placeholder="Percentuale finanziamento %"
                                    {...register('loanPercentage', { maxLength: 13 })}
                                />
                                {errors?.loanPercentage?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage(13)}</p>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div
                                className={`w-full input-field-group md:w-1/2 ${
                                    errors?.entity ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="entity">Ente</label>
                                <input
                                    id="entity"
                                    name="entity"
                                    type="text"
                                    {...register('entity', { maxLength: 255 })}
                                />
                                {errors?.entity?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/2 ${
                                    errors?.tenderUrl ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="tenderUrl">URl fondo bando</label>
                                <input
                                    id="tenderUrl"
                                    name="tenderUrl"
                                    type="URl"
                                    {...register('tenderUrl', { maxLength: 255 })}
                                />
                                {errors?.tenderUrl?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div className="w-full input-field-group md:w-1/3">
                                <label htmlFor="femaleMajority">Maggioranza femminile</label>
                                <select
                                    id="femaleMajority"
                                    name="femaleMajority"
                                    {...register('femaleMajority')}>
                                    <option value="">Non dichiarato</option>
                                    <option value="0">No</option>
                                    <option value="1">Si</option>
                                </select>
                            </div>

                            <div className="w-full input-field-group md:w-1/3">
                                <label htmlFor="youthMajority">Maggioranza giovanile</label>
                                <select
                                    id="youthMajority"
                                    name="youthMajority"
                                    {...register('youthMajority')}>
                                    <option value="">Non dichiarato</option>
                                    <option value="0">No</option>
                                    <option value="1">Si</option>
                                </select>
                            </div>

                            <div className="w-full input-field-group md:w-1/3">
                                <label htmlFor="yearOfActivity">Minimo anni di attività</label>
                                <input
                                    type="number"
                                    id="yearOfActivity"
                                    name="yearOfActivity"
                                    step=".01"
                                    {...register('minimumYearsOfActivity', {
                                        maxLength: 11
                                    })}
                                />
                                {errors?.minimumYearsOfActivity?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage(11)}</p>
                                )}
                            </div>
                        </div>
                        <div className="flex w-full md:flex-row md:space-x-4 my-4">
                            <div className="w-full input-field-group">
                                <div className="w-full input-field-group">
                                    <label htmlFor="introduction">Introduzione</label>
                                    <input
                                        type="text"
                                        id="introduction"
                                        name="introduction"
                                        {...register('introduction', {
                                            maxLength: 255
                                        })}
                                    />
                                    {errors?.minimumYearsOfActivity?.type === 'maxLength' && (
                                        <p className="text-red-700">
                                            {MESSAGES.maxLimitMessage(11)}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div className="flex flex-col w-full my-4">
                                <div className="my-4">
                                    <p className="font-bold w-full">Letto e Analizzato</p>
                                    <div className="flex w-full flex-wrap space-x-4">
                                        <div className="flex items-center space-x-2">
                                            <input
                                                id="inline-radio"
                                                type="radio"
                                                value="1"
                                                name="isAnalyzed"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                {...register('isAnalyzed', { required: true })}
                                            />
                                            <label htmlFor="isAnalyzed">Si</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                id="inline-radio"
                                                type="radio"
                                                value="0"
                                                name="isAnalyzed"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                {...register('isAnalyzed', { required: true })}
                                            />
                                            <label htmlFor="isAnalyzed">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div
                                className={`w-full input-field-group ${
                                    errors?.additionalNotes ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="note">Note aggiuntive</label>
                                <textarea
                                    id="note"
                                    name="note"
                                    {...register('additionalNotes', {
                                        maxLength: 500
                                    })}
                                />
                                {errors?.additionalNotes?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage(500)}</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="border-b border-grey-800">
                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div
                                className={`w-full input-field-group ${
                                    errors?.driveLink ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="driveLink">Link Google Drive</label>
                                <input
                                    type="url"
                                    id="driveLink"
                                    name="driveLink"
                                    {...register('driveLink', { maxLength: 1000 })}
                                />
                                {errors?.driveLink?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage(1000)}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                    <PrimaryButton type="submit">
                        {`${selectedTender ? 'MODIFICA' : 'AGGIUNGI'}`} BANDO
                    </PrimaryButton>
                    <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default CreateTender;
