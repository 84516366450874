/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Loader from '../component/Loader';
// import { checkIsInMaintenance } from '../helper/services/apis/config-services';

const ConfigContext = React.createContext({
    isInMaintenance: false,
    onChangeHandler: () => {}
});

export function ConfigContextProvider(props) {
    const [isInMaintenance, setIsInMaintenance] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isLoading, setIsLoading] = useState(false);

    const onChangeHandler = (state) => {
        setIsInMaintenance(state);
    };

    // const checkAppStatus = async () => {
    //     // const result = await checkIsInMaintenance();
    //     // if (result) {
    //     //     setIsInMaintenance(result.data.result);
    //     // }
    //     setIsLoading(false);
    // };

    useEffect(() => {}, []);

    const getLandingScreen = () => {
        return isLoading ? <Loader /> : props.children;
    };

    return (
        <ConfigContext.Provider value={{ isInMaintenance, onChangeHandler }}>
            {getLandingScreen()}
        </ConfigContext.Provider>
    );
}

export default ConfigContext;
