/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';
import { toFormData } from '../../utility';

export const getConsultancyListData = async (type, queryString) => {
    const result = await axiosInstance.get(`v1/consultancies/${type}?${queryString}`);
    return result;
};

export const createNewConsultancy = async (payload) => {
    const result = await axiosInstance.post(`v1/${API_ENDPOINT.CONSULTANCY}`, payload);
    return result;
};

export const updateConsultancy = async (id, payload) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.CONSULTANCY}/${id}`, payload);
    return result;
};

export const deleteConsultancy = async (id) => {
    const result = await axiosInstance.delete(`v1/${API_ENDPOINT.CONSULTANCY}/${id}`);
    return result;
};

export const ConsultancyAssignClient = async (id, payload) => {
    const result = await axiosInstance.post(`v1/consultancy-assign-client/${id}`, payload);
    return result;
};

export const importCsvForConsultancy = async (payload) => {
    const result = await axiosInstance.post(
        `v1/${API_ENDPOINT.CONSULTANCY}-upload-csv`,
        await toFormData(payload)
    );
    return result;
};
