/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import PageHeader from '../../component/PageHeader';
import RoundButton from '../../component/RoundButton';
import {
    MESSAGES,
    MONTH,
    REPORT_DATATABLE_COLUMN,
    TENDER_CLIENT_ANALYSIS_HEADERS,
    TENDER_STATUS
} from '../../constants';
import { yearReport } from '../../helper/services/apis/tender-services';
import { generateCSV, reformatDate, sortArray } from '../../helper/utility';
import Storage from '../../helper/services/common/storage-helper-services';

function Report() {
    const navigate = useNavigate();
    const [currentStateIndex, setCurrentStateIndex] = useState(0);
    const [tenders, setTenders] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [yearOptions, setYearOptions] = useState(null);

    const [tenderAnalysisData, setTenderAnalysisData] = useState([]);
    const [tenderAnalysisHeaders, setTenderAnalysisHeaders] = useState([
        ...TENDER_CLIENT_ANALYSIS_HEADERS
    ]);

    const getHeader = () => {
        return (
            <ol className="inline-flex items-center space-x-1">
                <li className="inline-flex items-center">
                    <span className="inline-flex items-center text-primary-800">Report</span>
                </li>
            </ol>
        );
    };

    const stateLabelOnClickHandler = (index) => {
        setCurrentStateIndex(index);
    };

    const initTender = async (year) => {
        const result = await yearReport(year).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });

        if (result.ok) {
            setTenders({ ...result.data });
        }
    };

    const getABIFINRichiesto = (client) => {
        let value = 0;
        value = (client.pivot.requiredAmount * client.pivot.percentageAbifinRequest) / 100;
        return +value.toFixed(2);
    };

    const getImponibile = (client, cost) => {
        let value = 0;

        value =
            (client.pivot.obtainedAmount * client.pivot.percentageAbifinRequest) / 100 +
            (+cost + +client.pivot.payedCostForTender + +client.pivot.costEnteredByAbifin);
        return +value.toFixed(2);
    };

    const getGeneratoPrevisto = (client, cost) => {
        if (client.pivot.status === 'Waiting') {
            let value = 0;
            let exp = 0;
            console.log(client);

            if (cost) {
                value += +cost;
            }

            if (client.pivot.costEnteredByAbifin) {
                value += +client.pivot.costEnteredByAbifin;
            }

            if (client.pivot.payedCostForTender) {
                value += +client.pivot.payedCostForTender;
            }

            exp = client.pivot.percentageOfWiningTender
                ? client.pivot.percentageOfWiningTender / 100
                : 0;

            exp *= client.pivot.percentageAbifinRequest
                ? client.pivot.percentageAbifinRequest / 100
                : 0;

            exp *= client.pivot.requiredAmount ? client.pivot.requiredAmount : 0;

            value += exp;

            return +value.toFixed(2);
        }
        return getImponibile(client, cost);
    };

    const calculateRealizo = (previsto, rendicontazione, fisso, abbonamenti, richiesto) => {
        let value = 0;
        value = !isNaN((previsto - rendicontazione - fisso - abbonamenti) / richiesto)
            ? (previsto - rendicontazione - fisso - abbonamenti) / richiesto
            : 0;

        return value * 100;
    };

    const getReportRows = (data) => {
        const dataTablerows = [];
        const total = {
            requiredAmount: 0,
            clients: 0,
            subscriptionCost: 0,
            abifinRichiesto: 0,
            payedCostForTender: 0,
            costEnteredByAbifin: 0,
            imponibile: 0,
            generatoPrevisto: 0,
            per: 0
        };
        if (data) {
            for (let index = 12; index > 0; index--) {
                if (data[index]) {
                    const month = { ...data[index] };
                    const row = {
                        label: MONTH[index - 1],
                        requiredAmount: 0,
                        clients: 0,
                        subscriptionCost: 0,
                        abifinRichiesto: 0,
                        payedCostForTender: 0,
                        costEnteredByAbifin: 0,
                        imponibile: 0,
                        generatoPrevisto: 0,
                        per: 0
                    };

                    // tenders
                    const tenderKeys = Object.keys(month);
                    tenderKeys.forEach((element) => {
                        const tender = month[element];
                        tender.clients.forEach((client) => {
                            row.requiredAmount +=
                                client.pivot.requiredAmount != null
                                    ? +client.pivot.requiredAmount
                                    : 0;
                            row.clients += 1;
                            row.subscriptionCost +=
                                client.subscriptionCost != null ? +client.subscriptionCost : 0;
                            row.abifinRichiesto += getABIFINRichiesto(client);
                            row.payedCostForTender +=
                                client.pivot.payedCostForTender != null
                                    ? +client.pivot.payedCostForTender
                                    : 0;
                            row.costEnteredByAbifin +=
                                client.pivot.costEnteredByAbifin != null
                                    ? +client.pivot.costEnteredByAbifin
                                    : 0;
                            row.generatoPrevisto += getGeneratoPrevisto(
                                client,
                                client.subscriptionCost
                            );
                            row.imponibile += getImponibile(client, client.subscriptionCost);
                        });
                        row.per = calculateRealizo(
                            row.generatoPrevisto,
                            row.costEnteredByAbifin,
                            row.payedCostForTender,
                            row.subscriptionCost,
                            row.abifinRichiesto
                        );
                    });

                    dataTablerows.push({
                        className: '',
                        cells: [
                            { template: row.label, className: 'border-x-2' },
                            {
                                template: `€ ${new Intl.NumberFormat('de-DE', {
                                    currency: 'EUR'
                                }).format(Math.round(row.requiredAmount))}`,
                                className: 'border-x-2'
                            },
                            { template: row.clients, className: 'border-x-2' },
                            {
                                template: `€ ${new Intl.NumberFormat('de-DE', {
                                    currency: 'EUR'
                                }).format(Math.round(row.abifinRichiesto))}`,
                                className: 'border-x-2'
                            },
                            {
                                template: `€ ${new Intl.NumberFormat('de-DE', {
                                    currency: 'EUR'
                                }).format(Math.round(row.subscriptionCost))}`,
                                className: 'border-x-2'
                            },
                            {
                                template: `€ ${new Intl.NumberFormat('de-DE', {
                                    currency: 'EUR'
                                }).format(Math.round(row.payedCostForTender))}`,
                                className: 'border-x-2'
                            },
                            {
                                template: `€ ${new Intl.NumberFormat('de-DE', {
                                    currency: 'EUR'
                                }).format(Math.round(row.costEnteredByAbifin))}`,
                                className: 'border-x-2'
                            },
                            {
                                template: `€ ${new Intl.NumberFormat('de-DE', {
                                    currency: 'EUR'
                                }).format(Math.round(row.imponibile))}`,
                                className: 'border-x-2'
                            },
                            {
                                template: `€ ${new Intl.NumberFormat('de-DE', {
                                    currency: 'EUR'
                                }).format(Math.round(row.generatoPrevisto))}`,
                                className: 'border-x-2'
                            },
                            {
                                template: `${Math.round(row.per)} %`,
                                className: 'border-x-2'
                            }
                        ]
                    });

                    total.requiredAmount += row.requiredAmount;
                    total.clients += row.clients;
                    total.subscriptionCost += row.subscriptionCost;
                    total.abifinRichiesto += row.abifinRichiesto;
                    total.payedCostForTender += row.payedCostForTender;
                    total.costEnteredByAbifin += row.costEnteredByAbifin;
                    total.imponibile += row.imponibile;
                    total.generatoPrevisto += row.generatoPrevisto;
                }
            }
        }

        total.per = Math.round(
            calculateRealizo(
                total.generatoPrevisto,
                total.costEnteredByAbifin,
                total.payedCostForTender,
                total.subscriptionCost,
                total.abifinRichiesto
            )
        );

        dataTablerows.push({
            className: '',
            cells: [
                {
                    template: 'Totale generato:',
                    className: 'border-2 font-black bg-[#3062AC1A]'
                },
                {
                    template: `€ ${new Intl.NumberFormat('de-DE', {
                        currency: 'EUR'
                    }).format(Math.round(total.requiredAmount))}`,
                    className: 'border-2 font-bold bg-[#3062AC1A]'
                },
                {
                    template: total.clients,
                    className: 'border-2 font-bold bg-[#3062AC1A]'
                },
                {
                    template: `€ ${new Intl.NumberFormat('de-DE', {
                        currency: 'EUR'
                    }).format(Math.round(total.abifinRichiesto))}`,
                    className: 'border-2 font-bold bg-[#3062AC1A]'
                },
                {
                    template: `€ ${new Intl.NumberFormat('de-DE', {
                        currency: 'EUR'
                    }).format(Math.round(total.subscriptionCost))}`,
                    className: 'border-2 font-bold bg-[#3062AC1A]'
                },
                {
                    template: `€ ${new Intl.NumberFormat('de-DE', {
                        currency: 'EUR'
                    }).format(Math.round(total.payedCostForTender))}`,
                    className: 'border-2 font-bold bg-[#3062AC1A]'
                },
                {
                    template: `€ ${new Intl.NumberFormat('de-DE', {
                        currency: 'EUR'
                    }).format(Math.round(total.costEnteredByAbifin))}`,
                    className: 'border-2 font-bold bg-[#3062AC1A]'
                },
                {
                    template: `€ ${new Intl.NumberFormat('de-DE', {
                        currency: 'EUR'
                    }).format(Math.round(total.imponibile))}`,
                    className: 'border-2 font-bold bg-[#3062AC1A]'
                },
                {
                    template: `€ ${new Intl.NumberFormat('de-DE', {
                        currency: 'EUR'
                    }).format(Math.round(total.generatoPrevisto))}`,
                    className: 'border-2 font-bold bg-[#3062AC1A]'
                },
                {
                    template: `${total.per} %`,
                    className: 'border-2 font-bold bg-[#3062AC1A]'
                }
            ]
        });

        return dataTablerows;
    };

    const getConIva = (client, cost) => {
        let result = 0;
        const imponibile = getImponibile(client, cost);
        let perValue = 0;
        if (imponibile > 0) {
            perValue = (imponibile * 22) / 100;
            result = imponibile + perValue;
        }
        return +result.toFixed(2);
    };

    const getTenderAnalysisRows = (data) => {
        const dataTablerows = [];

        if (data) {
            data.forEach((item) => {
                const row = {
                    className: '',
                    cells: []
                };
                row.cells.push({
                    template: (
                        <Link
                            to={`../tender/${item.id}`}
                            className="text-primary-800 line-clamp-2"
                            title={item.name}>
                            {item.name}
                        </Link>
                    ),
                    className: 'min-w-[128px] px-2'
                });
                row.cells.push({
                    template: item.displayCreatedAt,
                    className: 'min-w-[128px]  px-2'
                });
                // row.cells.push({
                //     template: item.month,
                //     className: 'min-w-[128px]'
                // });
                row.cells.push({
                    template: (
                        <Link
                            to={`../client/${item.clientId}`}
                            className="text-primary-800 line-clamp-2"
                            title={item.clientName}>
                            {item.clientName}
                        </Link>
                    ),
                    className: 'min-w-[128px] px-2'
                });
                row.cells.push({
                    template: (
                        <span
                            className="px-3 py-2 rounded-2xl text-white inline-block"
                            style={{
                                backgroundColor: item.statusColor
                            }}>
                            {item.status}
                        </span>
                    ),
                    className: 'min-w-[128px] px-2'
                });
                row.cells.push({
                    template: item.percentageOfWiningTender ? item.percentageOfWiningTender : '-',
                    className: 'min-w-[128px] px-2'
                });
                row.cells.push({
                    template: item.subscriptionCost ? item.subscriptionCost.toFixed(2) : '-',
                    className: 'min-w-[128px] px-2'
                });
                row.cells.push({
                    template: item.payedCostForTender
                        ? `€ ${item.payedCostForTender.toFixed(2)}`
                        : '-',
                    className: 'min-w-[128px] px-2'
                });
                row.cells.push({
                    template: item.costEnteredByAbifin
                        ? `€ ${item.costEnteredByAbifin.toFixed(2)}`
                        : '-',
                    className: 'min-w-[128px] px-2'
                });
                row.cells.push({
                    template: item.requiredAmount ? item.requiredAmount.toFixed(2) : '-',
                    className: 'min-w-[128px] px-2'
                });
                row.cells.push({
                    template: item.obtainedAmount ? `€ ${item.obtainedAmount.toFixed(2)}` : '-',
                    className: 'min-w-[128px] px-2'
                });
                row.cells.push({
                    template: item.percentageAbifinRequest ? item.percentageAbifinRequest : '-',
                    className: 'min-w-[128px] px-2'
                });

                row.cells.push({
                    template: `€ ${item.imponibile}`,
                    className: 'min-w-[128px] px-2'
                });
                row.cells.push({
                    template: `€ ${item.coniva}`,
                    className: 'min-w-[128px] px-2'
                });

                row.cells.push({
                    template: `€ ${item.previsto}`,
                    className: 'min-w-[128px] px-2'
                });
                row.cells.push({
                    template: `€ ${item.abifinRichiesto.toFixed(2)}`,
                    className: 'min-w-[128px] px-2'
                });

                dataTablerows.push(row);
            });
        }
        return dataTablerows;
    };

    const downloadReport = (data) => {
        let csvData = `Mese,SUM of richiesta,COUNTA of Partecipanti,SUM of Abbonamenti,SUM of Abifin richiesto, SUM of Fisso,SUM of Rendicontazione,SUM of Generato previsto,% Realizzo,\r\n`;

        const total = {
            requiredAmount: 0,
            clients: 0,
            subscriptionCost: 0,
            abifinRichiesto: 0,
            payedCostForTender: 0,
            costEnteredByAbifin: 0,
            generatoPrevisto: 0,
            per: 0
        };
        if (data) {
            for (let index = 12; index > 0; index--) {
                if (data[index]) {
                    const month = { ...data[index] };
                    const row = {
                        label: MONTH[index - 1],
                        requiredAmount: 0,
                        clients: 0,
                        subscriptionCost: 0,
                        abifinRichiesto: 0,
                        payedCostForTender: 0,
                        costEnteredByAbifin: 0,
                        generatoPrevisto: 0,
                        per: 0
                    };

                    // tenders
                    const tenderKeys = Object.keys(month);
                    tenderKeys.forEach((element) => {
                        const tender = month[element];
                        tender.clients.forEach((client) => {
                            row.requiredAmount +=
                                client.pivot.requiredAmount != null
                                    ? +client.pivot.requiredAmount
                                    : 0;
                            row.clients += 1;
                            row.subscriptionCost +=
                                client.subscriptionCost != null ? +client.subscriptionCost : 0;
                            row.abifinRichiesto += getABIFINRichiesto(client);
                            row.payedCostForTender +=
                                client.pivot.payedCostForTender != null
                                    ? +client.pivot.payedCostForTender
                                    : 0;
                            row.costEnteredByAbifin +=
                                client.pivot.costEnteredByAbifin != null
                                    ? +client.pivot.costEnteredByAbifin
                                    : 0;
                            row.generatoPrevisto += getGeneratoPrevisto(
                                client,
                                client.subscriptionCost
                            );
                        });
                        row.per = calculateRealizo(
                            row.generatoPrevisto,
                            row.costEnteredByAbifin,
                            row.payedCostForTender,
                            row.subscriptionCost,
                            row.abifinRichiesto
                        );
                    });

                    csvData += `${row.label},€ ${new Intl.NumberFormat('de-DE', {
                        currency: 'EUR'
                    }).format(Math.round(row.requiredAmount))},${
                        row.clients
                    },€ ${new Intl.NumberFormat('de-DE', {
                        currency: 'EUR'
                    }).format(Math.round(row.subscriptionCost))},€ ${new Intl.NumberFormat(
                        'de-DE',
                        {
                            currency: 'EUR'
                        }
                    ).format(Math.round(row.abifinRichiesto))},€ ${new Intl.NumberFormat('de-DE', {
                        currency: 'EUR'
                    }).format(Math.round(row.payedCostForTender))},€ ${new Intl.NumberFormat(
                        'de-DE',
                        {
                            currency: 'EUR'
                        }
                    ).format(Math.round(row.costEnteredByAbifin))},€ ${new Intl.NumberFormat(
                        'de-DE',
                        {
                            currency: 'EUR'
                        }
                    ).format(Math.round(row.generatoPrevisto))},${Math.round(row.per)}%,\r\n`;

                    total.requiredAmount += row.requiredAmount;
                    total.clients += row.clients;
                    total.subscriptionCost += row.subscriptionCost;
                    total.abifinRichiesto += row.abifinRichiesto;
                    total.payedCostForTender += row.payedCostForTender;
                    total.costEnteredByAbifin += row.costEnteredByAbifin;
                    total.generatoPrevisto += row.generatoPrevisto;
                    total.per += Math.round(row.per);
                }
            }
        }

        csvData += `Totale generato,€ ${new Intl.NumberFormat('de-DE', {
            currency: 'EUR'
        }).format(Math.round(total.requiredAmount))},${total.clients},€ ${new Intl.NumberFormat(
            'de-DE',
            {
                currency: 'EUR'
            }
        ).format(Math.round(total.subscriptionCost))},€ ${new Intl.NumberFormat('de-DE', {
            currency: 'EUR'
        }).format(Math.round(total.abifinRichiesto))},€ ${new Intl.NumberFormat('de-DE', {
            currency: 'EUR'
        }).format(Math.round(total.payedCostForTender))},€ ${new Intl.NumberFormat('de-DE', {
            currency: 'EUR'
        }).format(Math.round(total.costEnteredByAbifin))},€ ${new Intl.NumberFormat('de-DE', {
            currency: 'EUR'
        }).format(Math.round(total.generatoPrevisto))},${total.per} %`;

        generateCSV(csvData, 'Report');
    };

    const onYearChangeHandler = (event) => {
        setSelectedYear(event.target.value);
    };

    const initYears = async () => {
        const years = [];
        const min = 2015;
        for (let year = min; year <= new Date().getFullYear(); year++) {
            years.push({ id: year, name: year });
        }

        setYearOptions([...years.reverse()]);
    };

    /**
     *
     * @param {*} index : of column
     */
    const onChangeSortBtnClick = (index) => {
        let tempData = [...tenderAnalysisHeaders];
        const singleColumn = { ...tempData[index] };
        if (!singleColumn.sortStatus) {
            singleColumn.sortStatus = 'asc';
        } else if (singleColumn.sortStatus === 'asc') {
            singleColumn.sortStatus = 'desc';
        } else {
            singleColumn.sortStatus = null;
        }
        tempData = tempData.map((item) => ({ ...item, sortStatus: null }));
        tempData[index] = { ...singleColumn };
        setTenderAnalysisHeaders([...tempData]);
    };

    const createTenderAnalysisData = (data) => {
        if (data) {
            const dataTablerows = [];

            for (let index = 12; index > 0; index--) {
                if (data[index]) {
                    const month = { ...data[index] };

                    const tenderKeys = Object.keys(month);
                    tenderKeys.forEach((element) => {
                        const tender = month[element];
                        tender.clients.forEach((client) => {
                            const date = client.pivot.participationDate
                                ? reformatDate(client.pivot.participationDate)
                                : '-';
                            dataTablerows.push({
                                id: tender.id,
                                name: tender.name,
                                createdAt: new Date(client.pivot.participationDate),
                                displayCreatedAt: date,
                                month: MONTH[index - 1],
                                clientId: client.id,
                                clientName: client.name,
                                statusColor: TENDER_STATUS[client?.pivot?.status]?.color,
                                status: TENDER_STATUS[client?.pivot?.status]?.label,
                                subscriptionCost: +client.subscriptionCost,
                                payedCostForTender: +client.pivot.payedCostForTender,
                                costEnteredByAbifin: +client.pivot.costEnteredByAbifin,
                                percentageOfWiningTender: client.pivot.percentageOfWiningTender,
                                obtainedAmount: +client.pivot.obtainedAmount,
                                percentageAbifinRequest: client.pivot.percentageAbifinRequest,
                                requiredAmount: +client.pivot.requiredAmount,
                                imponibile: getImponibile(client, client.subscriptionCost),
                                coniva: getConIva(client, client.subscriptionCost),
                                previsto: getGeneratoPrevisto(client, client.subscriptionCost),
                                abifinRichiesto: +getABIFINRichiesto(client)
                            });
                        });
                    });
                }
            }
            setTenderAnalysisData([...dataTablerows]);
        } else {
            setTenderAnalysisData([]);
        }
    };

    useEffect(() => {
        const user = JSON.parse(Storage.get('profile'));
        if (user?.role === 'tenderOffice') {
            navigate(`../dashboard`);
        }
        initYears();
    }, []);

    useEffect(() => {
        setSelectedYear(new Date().getFullYear());
    }, [yearOptions]);

    useEffect(() => {
        if (selectedYear) {
            initTender(selectedYear);
        }
    }, [selectedYear]);

    useEffect(() => {
        createTenderAnalysisData(tenders);
    }, [tenders]);

    useEffect(() => {
        if (tenderAnalysisData.length > 0) {
            const result = tenderAnalysisHeaders.find(
                (column) => column.isSortable && column.sortStatus != null
            );
            if (result) {
                setTenderAnalysisData([
                    ...sortArray(tenderAnalysisData, result.column, result.sortStatus)
                ]);
            } else {
                setTenderAnalysisData([...sortArray(tenderAnalysisData, 'id', 'asc')]);
            }
        }
    }, [tenderAnalysisHeaders]);

    return (
        <section>
            <PageHeader title={getHeader()}>
                <div className="relative inline-block">
                    <RoundButton onClick={() => downloadReport(tenders)}>
                        <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 15C0 14.7348 0.127933 14.4804 0.355656 14.2929C0.583379 14.1054 0.892237 14 1.21429 14H15.7857C16.1078 14 16.4166 14.1054 16.6443 14.2929C16.8721 14.4804 17 14.7348 17 15C17 15.2652 16.8721 15.5196 16.6443 15.7071C16.4166 15.8946 16.1078 16 15.7857 16H1.21429C0.892237 16 0.583379 15.8946 0.355656 15.7071C0.127933 15.5196 0 15.2652 0 15ZM3.99864 7.293C4.22636 7.10553 4.53516 7.00021 4.85714 7.00021C5.17913 7.00021 5.48793 7.10553 5.71564 7.293L7.28571 8.586V1C7.28571 0.734784 7.41365 0.48043 7.64137 0.292893C7.86909 0.105357 8.17795 0 8.5 0C8.82205 0 9.13091 0.105357 9.35863 0.292893C9.58635 0.48043 9.71429 0.734784 9.71429 1V8.586L11.2844 7.293C11.3964 7.19749 11.5304 7.12131 11.6785 7.0689C11.8267 7.01649 11.986 6.9889 12.1472 6.98775C12.3085 6.9866 12.4684 7.0119 12.6176 7.06218C12.7668 7.11246 12.9024 7.18671 13.0164 7.28061C13.1304 7.3745 13.2206 7.48615 13.2816 7.60905C13.3427 7.73194 13.3734 7.86362 13.372 7.9964C13.3706 8.12918 13.3371 8.2604 13.2735 8.3824C13.2098 8.50441 13.1173 8.61475 13.0014 8.707L9.3585 11.707C9.13079 11.8945 8.82199 11.9998 8.5 11.9998C8.17801 11.9998 7.86921 11.8945 7.6415 11.707L3.99864 8.707C3.771 8.51947 3.64312 8.26516 3.64312 8C3.64312 7.73484 3.771 7.48053 3.99864 7.293Z"
                                fill="white"
                            />
                        </svg>
                    </RoundButton>
                </div>
            </PageHeader>

            <div className="flex flex-row-reverse w-full">
                <div className="input-field-group">
                    <select onChange={onYearChangeHandler} value={selectedYear}>
                        <option value="">Seleziona...</option>
                        {yearOptions?.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="border-b border-b-greyLine space-x-2">
                <ol className="inline-flex items-center space-x-1">
                    <li
                        onClick={() => stateLabelOnClickHandler(0)}
                        aria-hidden="true"
                        className={`py-1 px-2 md:px-5 font-bold cursor-pointer ${
                            currentStateIndex === 0
                                ? ' text-primary-800 border-b-2 border-b-primary-800'
                                : null
                        }`}>
                        Analisi
                    </li>
                    <li
                        onClick={() => stateLabelOnClickHandler(1)}
                        aria-hidden="true"
                        className={`py-1 px-2 md:px-5 font-bold cursor-pointer ${
                            currentStateIndex === 1
                                ? ' text-primary-800 border-b-2 border-b-primary-800'
                                : null
                        }`}>
                        {/* Bandi consegnati */}
                        Finanza Agevolata
                    </li>
                </ol>
            </div>

            {currentStateIndex === 0 ? (
                <DataTable columns={REPORT_DATATABLE_COLUMN} rows={getReportRows(tenders)} />
            ) : null}

            {currentStateIndex === 1 ? (
                <DataTable
                    columns={tenderAnalysisHeaders}
                    sortBtnClick={onChangeSortBtnClick}
                    rows={getTenderAnalysisRows(tenderAnalysisData)}
                />
            ) : null}
        </section>
    );
}

export default Report;
