/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants';
import {
    createNewConsultancy,
    updateConsultancy
} from '../../helper/services/apis/consultancy-services';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

function CreateConsultancy({
    isOpen = false,
    type = null,
    selectedConsultancy = null,
    closeModel = null
}) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const onSubmitHandler = async (formData) => {
        const payload = { ...formData };
        payload.type = type;
        if (selectedConsultancy) {
            const result = await updateConsultancy(formData.id, payload).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                closeModel();
            }
        } else {
            const result = await createNewConsultancy(payload).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                closeModel();
            }
        }
    };

    useEffect(() => {
        if (selectedConsultancy) {
            reset({
                description: selectedConsultancy?.description,
                durationHours: selectedConsultancy?.durationHours,
                cost: selectedConsultancy?.cost,
                revenue: selectedConsultancy?.revenue,
                id: selectedConsultancy?.id
            });
        } else {
            reset({ description: '', durationHours: '', cost: '', revenue: '' });
        }
    }, [selectedConsultancy]);

    return (
        <ModalComponent isOpen={isOpen} size="max-w-6xl">
            {/* Modal header  */}
            <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                <h3 className="text-xl font-semibold text-white">
                    {selectedConsultancy
                        ? type === 'Marketing'
                            ? 'Modifica consulenza di marketing'
                            : 'Modifica consulenza finanziaria'
                        : type === 'Marketing'
                        ? 'Nuovo consulenza di marketing'
                        : 'Nuovo consulenza finanziaria'}
                </h3>
                <button
                    type="button"
                    className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={closeModel}>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                        <div
                            className={`w-full input-field-group ${
                                errors?.description ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="description">Descrizione consulenza*</label>
                            <input
                                type="text"
                                id="description"
                                name="description"
                                placeholder="Descrizione consulenza"
                                {...register('description', { required: true, maxLength: 255 })}
                            />
                            {errors?.description?.type === 'required' && (
                                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                            )}
                            {errors?.description?.type === 'maxLength' && (
                                <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                        <div
                            className={`w-full input-field-group md:w-1/3 ${
                                errors?.durationHours ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="durationHours">Durata ore*</label>
                            <input
                                type="number"
                                id="durationHours"
                                name="durationHours"
                                step="0.01"
                                placeholder="Durata ore"
                                {...register('durationHours', { required: true, maxLength: 19 })}
                            />
                            {errors?.durationHours?.type === 'required' && (
                                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                            )}
                            {errors?.durationHours?.type === 'maxLength' && (
                                <p className="text-red-700">{MESSAGES.maxLimitMessage(16)}</p>
                            )}
                        </div>
                        <div
                            className={`w-full input-field-group md:w-1/3 ${
                                errors?.cost ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="cost">Costo (€) *</label>
                            <input
                                type="number"
                                id="cost"
                                name="cost"
                                step="0.01"
                                placeholder="Costo"
                                {...register('cost', { required: true, maxLength: 19 })}
                            />
                            {errors?.cost?.type === 'required' && (
                                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                            )}
                            {errors?.cost?.type === 'maxLength' && (
                                <p className="text-red-700">{MESSAGES.maxLimitMessage(16)}</p>
                            )}
                        </div>
                        <div
                            className={`w-full input-field-group md:w-1/3 ${
                                errors?.revenue ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="revenue">Ricavo (€) *</label>
                            <input
                                type="number"
                                id="revenue"
                                name="revenue"
                                step="0.01"
                                placeholder="Ricavo"
                                {...register('revenue', { required: true, maxLength: 19 })}
                            />
                            {errors?.revenue?.type === 'required' && (
                                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                            )}
                            {errors?.revenue?.type === 'maxLength' && (
                                <p className="text-red-700">{MESSAGES.maxLimitMessage(16)}</p>
                            )}
                        </div>
                    </div>
                    {/* </div> */}
                </div>

                {/* Modal Footer */}
                <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                    <PrimaryButton type="submit">
                        {selectedConsultancy ? 'MODIFICA CONSULENZA' : 'AGGIUNGI CONSULENZA'}
                    </PrimaryButton>
                    <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default CreateConsultancy;
