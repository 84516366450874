/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import AssociateAction from '../../component/modal/AssociateAction';
import AssociateClient from '../../component/modal/AssociateClient';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import CreateClient from '../../component/modal/CreateClient';
import CreateConsultancy from '../../component/modal/CreateConsultancy';
import FileUpload from '../../component/modal/FileUpload';
import PageHeader from '../../component/PageHeader';
import RoundButton from '../../component/RoundButton';
import {
    CLIENT_CONSULTANCY_HEADERS,
    CLIENT_FIELDS,
    CLIENT_TENDER_HEADERS,
    FILES_TABLE_COLUMNS,
    MESSAGES,
    TENDER_STATUS
} from '../../constants';
import {
    deleteClient,
    deleteFile,
    downloadFile,
    getClientById
} from '../../helper/services/apis/client-services';
import {
    deleteClientConsultancy,
    deleteTenderAssociation
} from '../../helper/services/apis/common-services';
import { reformatDate, sortArray } from '../../helper/utility';
import ExportModal from '../../component/modal/exportModal';

function Client() {
    const params = useParams();
    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [currentStateIndex, setCurrentStateIndex] = useState(0);
    const [client, setClient] = useState(null);
    const [tendersData, setTendersData] = useState([]);
    const [consultanciesData, setconsultanciesData] = useState([]);
    const [isAssociateClientOpen, setAssociateClientOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [selectedTender, setSelectedTender] = useState(null);

    const [tenderTableHeaders, setTenderTableHeaders] = useState([...CLIENT_TENDER_HEADERS]);
    const [consultancyTableHeaders, setConsultancyTableHeaders] = useState([
        ...CLIENT_CONSULTANCY_HEADERS
    ]);
    const [isExportOpen, setIsExportOpen] = useState(false);
    const [exportData, setExportData] = useState(null);

    const [isConsultancyCreateOpen, setIsConsultancyCreateOpen] = useState(false);
    const [selectedConsultancy, setSelectedConsultancy] = useState(null);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isRemoveAssociationConfirmOpen, setIsRemoveAssociationConfirmOpen] = useState(false);

    const [isCompany, setIsCompany] = useState(false);
    const [isCompanyCreateOpen, setIsCompanyCreateOpen] = useState(false);
    const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [isUpdateTenderOpen, setIsUpdateTenderOpen] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [isDeleteAttachmentConfirmOpen, setIsDeleteAttachmentConfirmOpen] = useState(false);

    const initClient = async (id) => {
        setIsLoading(true);
        const result = await getClientById(id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        setIsLoading(false);

        if (result.ok) {
            const addresses = { legal: [], operative: [] };
            result.data.addresses.forEach((item) => {
                if (item.type === 'operative') {
                    addresses.operative.push({ ...item });
                } else if (item.type === 'legal') {
                    addresses.legal.push({ ...item });
                }
            });
            setClient({ ...result.data, addresses: { ...addresses } });
        }
    };

    const onClickDownloadImageHandler = async (doc) => {
        // Download Image
        const result = await downloadFile(doc.path).catch((e) => toast.error(e.message));
        const downloadLink = document.createElement('a');
        downloadLink.target = 'blank';
        downloadLink.download = `${doc.name}`;
        downloadLink.href = result.data;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const deleteDocFromStorage = async (doc) => {
        const result = await deleteFile({ filePath: doc.path, type: 'client', id: doc.id }).catch(
            (e) => toast.error(e.message)
        );
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            initClient(params.id);
        }
        setIsDeleteAttachmentConfirmOpen(false);
    };

    const onClickDeleteImageHandler = (doc) => {
        setSelectedAttachment(doc);
        setIsDeleteAttachmentConfirmOpen(true);
    };

    const onCancelDeleteImageHandler = () => {
        setSelectedAttachment(null);
        setIsDeleteAttachmentConfirmOpen(false);
    };

    const confirmBtnClickHandler = async (consultancy) => {
        const result = await deleteClientConsultancy(consultancy.pivot.id).catch((e) =>
            toast.error(e.message)
        );
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            setSelectedConsultancy(null);
            setIsConfirmOpen(false);
            initClient(params.id);
        }
    };

    const confirmBtnDeleteClientClickHandler = async (data) => {
        const result = await deleteClient(data.id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            setIsDeleteConfirmOpen(false);
            navigate('/dashboard/clients');
        }
    };

    const deleteAssociateTender = async (tender) => {
        const result = await deleteTenderAssociation(tender.pivot.id).catch((e) =>
            toast.error(e.message)
        );
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            setIsRemoveAssociationConfirmOpen(false);
            initClient(params.id);
        }
    };

    const onEditConsultancyBtnClickHandler = (consultancy) => {
        setSelectedConsultancy(consultancy);
        setIsConsultancyCreateOpen(true);
    };

    const deleteConsultancyOnClickHandler = (consultancy) => {
        setSelectedConsultancy(consultancy);
        setIsConfirmOpen(true);
    };

    const onCloseCrerateConsultancyModelHandler = () => {
        setSelectedConsultancy(null);
        setIsConsultancyCreateOpen(false);
        initClient(params.id);
    };

    const onCloseConfirmBtnClickHandler = (event, reload = false) => {
        setSelectedConsultancy(null);
        setIsConfirmOpen(false);
        if (reload) {
            initClient(params.id);
        }
    };

    /**
     *
     * @param {*} index : of column
     */
    const onChangeSortBtnClick = (index, type) => {
        if (type == 'tender') {
            let tempData = [...tenderTableHeaders];
            const singleColumn = { ...tempData[index] };
            if (!singleColumn.sortStatus) {
                singleColumn.sortStatus = 'asc';
            } else if (singleColumn.sortStatus === 'asc') {
                singleColumn.sortStatus = 'desc';
            } else {
                singleColumn.sortStatus = null;
            }
            tempData = tempData.map((item) => ({ ...item, sortStatus: null }));
            tempData[index] = { ...singleColumn };
            setTenderTableHeaders([...tempData]);
        }
        if (type == 'consultancy') {
            let tempData = [...consultancyTableHeaders];
            const singleColumn = { ...tempData[index] };
            if (!singleColumn.sortStatus) {
                singleColumn.sortStatus = 'asc';
            } else if (singleColumn.sortStatus === 'asc') {
                singleColumn.sortStatus = 'desc';
            } else {
                singleColumn.sortStatus = null;
            }
            tempData = tempData.map((item) => ({ ...item, sortStatus: null }));
            tempData[index] = { ...singleColumn };
            setConsultancyTableHeaders([...tempData]);
        }
    };

    const getImponibile = (tender, cost) => {
        let value = 0;

        // if (
        //     tender.pivot.requiredAmount &&
        //     tender.pivot.percentageAbifinRequest &&
        //     cost &&
        //     tender.pivot.payedCostForTender &&
        //     tender.pivot.costEnteredByAbifin
        // ) {
        //     const per = (tender.pivot.requiredAmount * tender.pivot.percentageAbifinRequest) / 100;

        //     value =
        //         (tender.pivot.requiredAmount * tender.pivot.percentageAbifinRequest) / 100 +
        //         (+cost + +tender.pivot.payedCostForTender + +tender.pivot.costEnteredByAbifin);
        // }

        value =
            (tender.pivot.obtainedAmount * tender.pivot.percentageAbifinRequest) / 100 +
            (+cost + +tender.pivot.payedCostForTender + +tender.pivot.costEnteredByAbifin);
        return +value.toFixed(2);
    };

    const getGeneratoPrevisto = (tender, cost) => {
        if (tender.pivot.status === 'Waiting') {
            let value = 0;
            let exp = 0;

            if (cost) {
                value += +cost;
            }

            if (tender.pivot.costEnteredByAbifin) {
                value += +tender.pivot.costEnteredByAbifin;
            }

            if (tender.pivot.payedCostForTender) {
                value += +tender.pivot.payedCostForTender;
            }

            exp = tender.pivot.percentageOfWiningTender
                ? tender.pivot.percentageOfWiningTender / 100
                : 0;

            exp *= tender.pivot.percentageAbifinRequest
                ? tender.pivot.percentageAbifinRequest / 100
                : 0;

            exp *= tender.pivot.requiredAmount ? tender.pivot.requiredAmount : 0;

            value += exp;

            return +value.toFixed(2);
        }
        return getImponibile(tender, cost);
    };

    const getConIva = (tender, cost) => {
        let result = 0;
        const imponibile = getImponibile(tender, cost);
        let perValue = 0;
        if (imponibile > 0) {
            perValue = (imponibile * 22) / 100;
            result = imponibile + perValue;
        }
        return +result.toFixed(2);
    };

    const getABIFINRichiesto = (tender) => {
        let value = 0;
        // if (tender.pivot.requiredAmount && tender.pivot.percentageAbifinRequest) {
        // }
        value = (tender.pivot.requiredAmount * tender.pivot.percentageAbifinRequest) / 100;
        return `${value.toFixed(2)}`;
    };

    const onAssociateModalOpen = (tender) => {
        console.log(tender);
        setSelectedTender(tender);
        setIsUpdateTenderOpen(true);
    };

    const onAssociateModalClose = (reload = false) => {
        setSelectedTender(null);
        setAssociateClientOpen(false);
        setIsUpdateTenderOpen(false);
        if (reload) {
            initClient(params.id);
        }
    };

    const onRemoveAssociateConfirmationModalOpen = (tender) => {
        setSelectedTender(tender);
        setIsRemoveAssociationConfirmOpen(true);
    };

    const onRemoveAssociateConfirmationModalClose = (reload = false) => {
        setSelectedTender(null);
        setIsRemoveAssociationConfirmOpen(false);
        if (reload) {
            initClient(params.id);
        }
    };

    const getTendersRows = (data) => {
        return data.map((tender) => ({
            className: '',
            cells: [
                // COl : 1
                {
                    template: (
                        <Link
                            to={`../tender/${tender.id}`}
                            className="text-primary-800"
                            title={tender.name}>
                            {tender.name}
                        </Link>
                    ),
                    className: 'min-w-[128px] max-w-[280px] truncate px-2'
                },
                // COl : 2
                { template: tender.displayCreatedAt, className: 'min-w-[128px] px-2' },
                // COl : 3
                {
                    template: (
                        <span
                            className="px-3 py-2 rounded-2xl text-white inline-block"
                            style={{ backgroundColor: tender.statusColor }}>
                            {tender.status}
                        </span>
                    ),
                    className: 'min-w-[128px] px-2'
                },
                // COl : 4
                {
                    template: tender.percentageOfWiningTender
                        ? tender.percentageOfWiningTender
                        : '-',
                    className: 'min-w-[128px] px-2'
                },

                // COl : 5
                {
                    template: tender.subscriptionCost ? tender.subscriptionCost.toFixed(2) : '-',
                    className: 'min-w-[128px] px-2'
                },

                // COl : 6
                {
                    template: tender.payedCostForTender
                        ? `€ ${tender.payedCostForTender.toFixed(2)}`
                        : '-',
                    className: 'min-w-[128px] px-2'
                },

                // COl : 7
                {
                    template: tender.costEnteredByAbifin
                        ? `€ ${tender.costEnteredByAbifin.toFixed(2)}`
                        : '-',
                    className: 'min-w-[128px] px-2'
                },

                // COl : 8
                {
                    template: tender.requiredAmount ? tender.requiredAmount.toFixed(2) : '-',
                    className: 'min-w-[128px] px-2'
                },
                // COl : 9
                {
                    template: tender.obtainedAmount ? `€ ${tender.obtainedAmount.toFixed(2)}` : '-',
                    className: 'min-w-[128px] px-2'
                },
                // COl : 10
                {
                    template: tender.percentageAbifinRequest ? tender.percentageAbifinRequest : '-',
                    className: 'min-w-[128px] px-2'
                },
                // COl : 11
                {
                    template: `€ ${tender.imponibile}`,
                    className: 'min-w-[128px] px-2'
                },
                // COl : 12
                {
                    template: `€ ${tender.coniva}`,
                    className: 'min-w-[128px] px-2'
                },
                // COl : 13
                {
                    template: `€ ${tender.previsto}`,
                    className: 'min-w-[128px] px-2'
                },
                // COl : 14
                {
                    template: `€ ${tender.abifinRichiesto.toFixed(2)}`,
                    className: 'min-w-[128px] px-2'
                },
                // COl : 15
                {
                    template: (
                        <div className="relative inline-block">
                            <RoundButton className="bg-grey-700 text-black peer text-mainBlack hover:bg-grey-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-grey-700 font-medium rounded-full text-sm p-2.5 text-center">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                    />
                                </svg>
                            </RoundButton>
                            <div className="absolute right-[0%] z-10 hidden w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => onAssociateModalOpen(tender)}
                                    aria-hidden="true">
                                    Modifica
                                </span>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => onRemoveAssociateConfirmationModalOpen(tender)}
                                    aria-hidden="true">
                                    Elimina
                                </span>
                            </div>
                        </div>
                    )
                }
            ]
        }));
    };

    const getConsRows = (data) => {
        return data.consultancies.map((consultancy) => ({
            className: '',
            cells: [
                { template: consultancy.description },
                { template: consultancy.createdAt },
                { template: consultancy.type === 'Marketing' ? 'Marketing' : 'Finanziarie' },
                { template: consultancy.durationHours },
                { template: consultancy.cost },
                { template: consultancy.revenue },
                {
                    template: (
                        <div className="relative inline-block">
                            <RoundButton className="bg-grey-700 text-black peer text-mainBlack hover:bg-grey-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-grey-700 font-medium rounded-full text-sm p-2.5 text-center">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                    />
                                </svg>
                            </RoundButton>
                            <div className="absolute right-[0%] z-10 hidden w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => onEditConsultancyBtnClickHandler(consultancy)}
                                    aria-hidden="true">
                                    Modifica consulenza
                                </span>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => deleteConsultancyOnClickHandler(consultancy)}
                                    aria-hidden="true">
                                    Elimina consulenza
                                </span>
                            </div>
                        </div>
                    )
                }
            ]
        }));
    };

    const getDocumentsRows = (data) => {
        return data.documents.map((doc) => ({
            className: '',
            cells: [
                { template: doc.name, className: ' text-primary-800' },
                { template: doc.createdAt },
                {
                    className: ' flex inline-block',
                    template: (
                        <div className="relative inline-block">
                            <RoundButton className="bg-grey-700 text-black peer text-mainBlack hover:bg-grey-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-grey-700 font-medium rounded-full text-sm p-2.5 text-center">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                    />
                                </svg>
                            </RoundButton>
                            <div className="absolute right-[0%] z-10 hidden w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => onClickDownloadImageHandler(doc)}
                                    aria-hidden="true">
                                    Scarica allegato
                                </span>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => onClickDeleteImageHandler(doc)}
                                    aria-hidden="true">
                                    Elimina allegato
                                </span>
                            </div>
                        </div>
                    )
                }
            ]
        }));
    };

    const getHeader = () => {
        return (
            <ol className="inline-flex items-center space-x-1">
                <li className="inline-flex items-center">
                    <Link
                        to="../clients"
                        className="inline-flex items-center text-primary-800 cursor-pointer">
                        Clienti
                    </Link>
                    {/* <span className="inline-flex items-center text-primary-800">Clienti</span> */}
                </li>
                <li>
                    <div className="flex items-center">
                        <svg
                            className="w-8 h-8 text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="ml-1">{client?.name}</span>
                    </div>
                </li>
            </ol>
        );
    };

    const stateLabelOnClickHandler = (index) => {
        setCurrentStateIndex(index);
    };

    const getLegalAddress = (address) => {
        const legaleAddress = address.find((item) => item.type === 'legal');

        return `${legaleAddress.city}, ${legaleAddress.region.name}, ${legaleAddress.province.name}, ${legaleAddress.houseNumber}`;
    };

    const getOperationalAddress = (address) => {
        let operativeAddress = address
            .filter((item) => item.type === 'operative')
            .map(
                (item) =>
                    `${item.city} ${item.region.name} ${item.province.name}-${item.houseNumber}`
            );
        if (operativeAddress) {
            operativeAddress = operativeAddress.join(', ');
        }
        return operativeAddress;
    };

    /**
     * Get Addresses, region, province, city based on selected type
     * @param {*} address
     * @param {'legal' || 'operative'} type
     * @param {'city' || 'region' || 'province' || 'full'} field
     */
    const getAddress = (addresses, type, field) => {
        let result = null;
        const address = addresses.find((item) => item.type === type);
        if (field === 'city') {
            result = address[field];
        } else if (field === 'region' || field === 'province') {
            result = address[field].name;
        } else if (field === 'full') {
            result = `${address.houseNumber}-${address.postalCode} ${address.province.name} ${address.region.name} ${address.city}`;
        }
        return result;
    };

    // const getClientOperativeCity = (address) => {
    //     let city = '-';
    //     const operativeAdd = address.find((item) => item.type === 'operative');
    //     if (operativeAdd) {
    //         city = operativeAdd.city;
    //     }
    //     return city;
    // };

    // const getClientOperativeRegion = (address) => {
    //     let region = '-';
    //     const operativeAdd = address.find((item) => item.type === 'operative');
    //     if (operativeAdd) {
    //         region = `${operativeAdd?.province?.name}/${operativeAdd?.region?.name}`;
    //     }
    //     return region;
    // };

    const closeExportModal = () => {
        setIsExportOpen(false);
    };

    const exportClients = (data) => {
        const filteredClients = data.map((item) => {
            const atecoCodes =
                item?.atecoCodes?.length > 0
                    ? item.atecoCodes.map((atecoCode) => atecoCode.name).join(' & ')
                    : null;

            const registeries =
                item?.registeries?.length > 0
                    ? item.registeries.map((registery) => registery.name).join(' & ')
                    : null;

            const sectors =
                item?.sectors?.length > 0
                    ? item.sectors.map((sector) => sector.name).join(' & ')
                    : null;

            return {
                createdAt: item.createdAt,
                name: item.name,
                operativeAddressCity: getAddress(item.addresses, 'operative', 'city'),
                operativeAddressRegion: `${getAddress(
                    item.addresses,
                    'operative',
                    'province'
                )} / ${getAddress(item.addresses, 'operative', 'region')}`,
                matchingTendersCount: item.matchingTendersCount,
                tendersCount: item.tendersCount,
                documentsCount: item.documentsCount,
                type: item.type === 'Company' ? 'Azienda' : 'Associazione',
                legalAddress: getAddress(item.addresses, 'legal', 'full'),
                operativeAddress: getAddress(item.addresses, 'operative', 'full'),
                atecoCodes,
                firstContactWithAbifin: item.firstContactWithAbifin,
                abifinReference: item.abifinReference,
                companyFiscalCode: item.companyFiscalCode,
                vatNumber: item.vatNumber,
                email: item.email,
                phoneNumber: item.phoneNumber,
                legalEmail: item.legalEmail,
                uniqueCode: item.uniqueCode,
                subscription: item.subscription,
                subscriptionCost: item.subscriptionCost,
                referanceName: item.referanceName,
                referanceEmail: item.referanceEmail,
                referancePhoneNumber: item.referancePhoneNumber,
                yearOfEstablishment: item.yearOfEstablishment,
                yearOfStartBusiness: item.yearOfStartBusiness,
                lastYearTurnover: item.lastYearTurnover,
                bank: item.bank,
                bankAccount: item.bankAccount,
                registeries,
                sectors,
                registrationRegisters: item.registrationRegisters,
                registrationRegistersNumber: item.registrationRegistersNumber,
                federationAffiliations: item.federationAffiliations,
                registrationRegistersDate: item.registrationRegistersDate,
                companySize: item.companySize ? `${item.companySize} Impresa` : null,
                femaleMajority: item.femaleMajority == '1' ? 'Si' : 'No',
                youthMajority: item.youthMajority == '1' ? 'Si' : 'No'
            };
        });

        setExportData([...filteredClients]);
        setIsExportOpen(true);

        // const headers = [
        //     'Data inserimento',
        //     'Ragione sociale',
        //     'Città sede operativa',
        //     'Provincia / Regione sede operatova',
        //     'Tipologia',
        //     'Matching Bandi Data inserimento',
        //     'Matching Bandi Titolo',
        //     'Matching Bandi Plafond',
        //     'Matching Bandi Scadenza',
        //     'Matching Bandi Per',
        //     'Matching Bandi Tipologia',
        //     'Associati Bandi Data inserimento',
        //     'Associati Bandi Titolo',
        //     'Associati Bandi Plafond',
        //     'Associati Bandi Scadenza',
        //     'Associati Bandi Per',
        //     'Associati Bandi Tipologia',
        //     'Associati Consulenze Data inserimento',
        //     'Associati Consulenze Descrizione consulenza',
        //     'Associati Consulenze Tipologia',
        //     '\r\n'
        // ];

        // const rows = filteredClients.map((client) => {
        //     const row = [];
        //     row.push(client.createdAt);
        //     row.push(client.name);
        //     row.push(getClientOperativeCity(client.addresses));
        //     row.push(getClientOperativeRegion(client.addresses));
        //     row.push(client.type === 'Company' ? 'Azienda' : 'Associazione');

        //     // Set matching tenders columns
        //     if (client.matchingTenders.length) {
        //         let createdAt = '';
        //         let title = '';
        //         let plafond = '';
        //         let deadline = '';
        //         let per = '';
        //         let type = '';
        //         client.matchingTenders.forEach((tender, index) => {
        //             if (index === 0) {
        //                 createdAt = tender.createdAt;
        //                 title = tender.name;
        //                 plafond = tender.ceiling;
        //                 deadline = reformatDate(tender.deadline);
        //                 per = tender.tenderFor;
        //                 type =
        //                     tender.type === 'Company'
        //                         ? 'Aziende'
        //                         : tender.type === 'Association'
        //                         ? 'Associazioni'
        //                         : 'Aziende e associazioni';
        //             } else {
        //                 createdAt = `${createdAt} |&| ${tender.createdAt}`;
        //                 title = `${title} |&| ${tender.name}`;
        //                 plafond = `${plafond} |&| ${tender.ceiling}`;
        //                 deadline = `${deadline} |&| ${reformatDate(tender.deadline)}`;
        //                 per = `${per} |&| ${tender.tenderFor}`;
        //                 type = `${type} |&| ${
        //                     tender.type === 'Company'
        //                         ? 'Aziende'
        //                         : tender.type === 'Association'
        //                         ? 'Associazioni'
        //                         : 'Aziende e associazioni'
        //                 }`;
        //             }
        //         });
        //         row.push(createdAt);
        //         row.push(title);
        //         row.push(plafond);
        //         row.push(deadline);
        //         row.push(per);
        //         row.push(type);
        //     } else {
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //     }

        //     // Set associate tenders columns
        //     if (client.tenders.length) {
        //         let createdAt = '';
        //         let title = '';
        //         let plafond = '';
        //         let deadline = '';
        //         let per = '';
        //         let type = '';
        //         client.tenders.forEach((tender, index) => {
        //             if (index === 0) {
        //                 createdAt = tender.createdAt;
        //                 title = tender.name;
        //                 plafond = tender.ceiling;
        //                 deadline = reformatDate(tender.deadline);
        //                 per = tender.tenderFor;
        //                 type =
        //                     tender.type === 'Company'
        //                         ? 'Aziende'
        //                         : tender.type === 'Association'
        //                         ? 'Associazioni'
        //                         : 'Aziende e associazioni';
        //             } else {
        //                 createdAt = `${createdAt} |&| ${tender.createdAt}`;
        //                 title = `${title} |&| ${tender.name}`;
        //                 plafond = `${plafond} |&| ${tender.ceiling}`;
        //                 deadline = `${deadline} |&| ${reformatDate(tender.deadline)}`;
        //                 per = `${per} |&| ${tender.tenderFor}`;
        //                 type = `${type} |&| ${
        //                     tender.type === 'Company'
        //                         ? 'Aziende'
        //                         : tender.type === 'Association'
        //                         ? 'Associazioni'
        //                         : 'Aziende e associazioni'
        //                 }`;
        //             }
        //         });
        //         row.push(createdAt);
        //         row.push(title);
        //         row.push(plafond);
        //         row.push(deadline);
        //         row.push(per);
        //         row.push(type);
        //     } else {
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //     }

        //     // Set associate cons columns
        //     if (client.consultancies.length) {
        //         let createdAt = '';
        //         let description = '';
        //         let type = '';

        //         client.consultancies.forEach((consultancy, index) => {
        //             if (index === 0) {
        //                 createdAt = consultancy.createdAt;
        //                 description = consultancy.description;
        //                 type = consultancy.type === 'Marketing' ? 'Marketing' : 'Finanziarie';
        //             } else {
        //                 createdAt = `${createdAt} |&| ${consultancy.createdAt}`;
        //                 description = `${description} |&| ${consultancy.description}`;
        //                 type = `${type} |&| ${
        //                     consultancy.type === 'Marketing' ? 'Marketing' : 'Finanziarie'
        //                 }`;
        //             }
        //         });

        //         row.push(createdAt);
        //         row.push(description);
        //         row.push(type);
        //     } else {
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //     }
        //     row.push('\r\n');
        //     return row;
        // });

        // let csvData = headers.join(',');
        // rows.forEach((row) => {
        //     csvData += row.join(',');
        // });

        // generateCSV(csvData, 'Clienti');
    };

    const onCloseModelHandler = () => {
        setIsCompany(true);
        setIsCompanyCreateOpen(false);
        initClient(params.id);
    };

    // eslint-disable-next-line no-unused-vars
    const onCloseFileUpload = (data) => {
        initClient(params.id);
        setIsFileUploadOpen(false);
    };

    // eslint-disable-next-line no-unused-vars
    const associateOnClickHandler = (data) => {
        setAssociateClientOpen(true);
    };

    const editOnClickHandler = (data) => {
        setIsCompany(data.type === 'Company');
        // setSelectedClient(client);
        setIsCompanyCreateOpen(true);
    };

    // eslint-disable-next-line no-unused-vars
    const attachOnClickHandler = (data) => {
        setIsFileUploadOpen(true);
    };

    const exportOnClickHandler = (data) => {
        exportClients([{ ...data }]);
    };

    const closeConfirmClickHandler = () => {
        setIsDeleteConfirmOpen(false);
    };

    // eslint-disable-next-line no-unused-vars
    const deleteOnClickHandler = (data) => {
        setIsDeleteConfirmOpen(true);
    };

    useEffect(() => {
        initClient(params.id);
        if (searchParams.get('tabIndex')) {
            setCurrentStateIndex(1);
        }
    }, []);

    useEffect(() => {
        if (client) {
            if (client.tenders && client.tenders.length > 0) {
                setTendersData(
                    client.tenders.map((tender) => {
                        const date = tender.pivot.participationDate
                            ? reformatDate(tender.pivot.participationDate)
                            : '-';
                        return {
                            id: tender.id,
                            name: tender.name,
                            createdAt: new Date(tender.pivot.participationDate),
                            displayCreatedAt: date,
                            statusColor: TENDER_STATUS[tender.pivot.status].color,
                            status: TENDER_STATUS[tender.pivot.status].label,
                            subscriptionCost: +client.subscriptionCost,
                            payedCostForTender: +tender.pivot.payedCostForTender,
                            costEnteredByAbifin: +tender.pivot.costEnteredByAbifin,
                            percentageOfWiningTender: tender.pivot.percentageOfWiningTender,
                            obtainedAmount: +tender.pivot.obtainedAmount,
                            percentageAbifinRequest: tender.pivot.percentageAbifinRequest,
                            requiredAmount: +tender.pivot.requiredAmount,
                            imponibile: getImponibile(tender, client.subscriptionCost),
                            coniva: getConIva(tender, client.subscriptionCost),
                            previsto: getGeneratoPrevisto(tender, client.subscriptionCost),
                            abifinRichiesto: +getABIFINRichiesto(tender),
                            pivot: { ...tender.pivot }
                        };
                    })
                );
            } else {
                setTendersData([]);
            }
            if (client.consultancies && client.consultancies.length > 0) {
                setconsultanciesData([
                    client.consultancies.map((consultancy) => {
                        const date = consultancy.createdAt.split('-');
                        return {
                            id: consultancy.id,
                            description: consultancy.description,
                            createdAt: new Date(`${date[2]}-${date[1]}-${date[0]}`),
                            displayCreatedAt: consultancy.createdAt,
                            type: consultancy.type === 'Marketing' ? 'Marketing' : 'Finanziarie',
                            durationHours: +consultancy.durationHours,
                            cost: +consultancy.cost,
                            revenue: +consultancy.revenue
                        };
                    })
                ]);
            } else {
                setconsultanciesData([]);
            }
        } else {
            setTendersData([]);
            setconsultanciesData([]);
        }
    }, [client]);

    useEffect(() => {
        if (tendersData.length > 0) {
            const result = tenderTableHeaders.find(
                (column) => column.isSortable && column.sortStatus != null
            );
            if (result) {
                setTendersData([...sortArray(tendersData, result.column, result.sortStatus)]);
            } else {
                setTendersData([...sortArray(tendersData, 'id', 'asc')]);
            }
        }
    }, [tenderTableHeaders]);

    useEffect(() => {
        if (consultanciesData.length > 0) {
            const result = consultancyTableHeaders.find(
                (column) => column.isSortable && column.sortStatus != null
            );
            if (result) {
                setconsultanciesData([
                    ...sortArray(consultanciesData, result.column, result.sortStatus)
                ]);
            } else {
                setconsultanciesData([...sortArray(consultanciesData, 'id', 'asc')]);
            }
        }
    }, [tenderTableHeaders]);

    return (
        <section>
            {isLoading ? <Loader /> : null}
            {/* <CreateClient />
            <CreateAssociation /> */}

            {isExportOpen ? (
                <ExportModal
                    isOpen={isExportOpen}
                    data={exportData}
                    fields={CLIENT_FIELDS.map((item) => ({ ...item }))}
                    fileName="Bandi"
                    closeModel={closeExportModal}
                />
            ) : null}

            <ConfirmationModal
                isOpen={isConfirmOpen}
                onConfirmAction={() => confirmBtnClickHandler(selectedConsultancy)}
                title="Eliminazione"
                message="Sei sicuro di voler procedere con l’eliminazione?"
                closeModel={onCloseConfirmBtnClickHandler}
            />

            <ConfirmationModal
                isOpen={isRemoveAssociationConfirmOpen}
                onConfirmAction={() => deleteAssociateTender(selectedTender)}
                title="Eliminazione"
                message="Sei sicuro di voler procedere con l’eliminazione?"
                closeModel={onRemoveAssociateConfirmationModalClose}
            />

            {isDeleteConfirmOpen ? (
                <ConfirmationModal
                    isOpen={isDeleteConfirmOpen}
                    onConfirmAction={() => confirmBtnDeleteClientClickHandler(client)}
                    title="Eliminazione"
                    message="Sei sicuro di voler procedere con l’eliminazione?"
                    closeModel={() => closeConfirmClickHandler()}
                />
            ) : null}

            {isDeleteAttachmentConfirmOpen ? (
                <ConfirmationModal
                    isOpen={isDeleteAttachmentConfirmOpen}
                    onConfirmAction={() => deleteDocFromStorage(selectedAttachment)}
                    title="Eliminazione"
                    message="Sei sicuro di voler procedere con l’eliminazione?"
                    closeModel={onCancelDeleteImageHandler}
                />
            ) : null}

            {selectedConsultancy ? (
                <CreateConsultancy
                    isOpen={isConsultancyCreateOpen}
                    type={selectedConsultancy.type}
                    selectedConsultancy={selectedConsultancy}
                    closeModel={onCloseCrerateConsultancyModelHandler}
                />
            ) : null}

            {isAssociateClientOpen ? (
                <AssociateAction
                    isOpen={isAssociateClientOpen}
                    selectedClient={client}
                    closeModel={onAssociateModalClose}
                />
            ) : null}

            {isUpdateTenderOpen ? (
                <AssociateClient
                    isOpen={isUpdateTenderOpen}
                    selectedTender={selectedTender}
                    closeModel={onAssociateModalClose}
                    selectedData={selectedTender.pivot}
                    clientId={client?.id}
                    selectedClientName={client?.name}
                />
            ) : null}

            {isCompanyCreateOpen ? (
                <CreateClient
                    isOpen={isCompanyCreateOpen}
                    isCompany={isCompany}
                    closeModel={onCloseModelHandler}
                    selectedClient={client}
                />
            ) : null}

            {isFileUploadOpen ? (
                <FileUpload
                    isOpen={isFileUploadOpen}
                    type="client"
                    id={client?.id}
                    closeModel={onCloseFileUpload}
                />
            ) : null}

            <PageHeader title={getHeader()}>
                <div className="relative inline-block">
                    <RoundButton className="peer">
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                            />
                        </svg>
                    </RoundButton>
                    <div className="absolute right-0 hidden z-20 w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                        <span
                            className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                            onClick={() => associateOnClickHandler(client)}
                            aria-hidden="true">
                            Associa azione
                        </span>
                        <span
                            className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                            onClick={() => editOnClickHandler(client)}
                            aria-hidden="true">
                            Modifica cliente
                        </span>
                        <span
                            className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                            onClick={() => attachOnClickHandler(client)}
                            aria-hidden="true">
                            Allega file
                        </span>
                        <span
                            className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                            onClick={() => exportOnClickHandler(client)}
                            aria-hidden="true">
                            Esporta
                        </span>
                        <span
                            className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                            onClick={() => deleteOnClickHandler(client)}
                            aria-hidden="true">
                            Elimina cliente
                        </span>
                    </div>
                </div>
            </PageHeader>

            <div className="border-b border-b-greyLine space-x-2">
                <ol className="inline-flex items-center space-x-1">
                    <li
                        onClick={() => stateLabelOnClickHandler(0)}
                        aria-hidden="true"
                        className={`py-1 px-2 md:px-5 font-bold cursor-pointer ${
                            currentStateIndex === 0
                                ? ' text-primary-800 border-b-2 border-b-primary-800'
                                : null
                        }`}>
                        Dettagli
                    </li>
                    <li
                        onClick={() => stateLabelOnClickHandler(1)}
                        aria-hidden="true"
                        className={`py-1 px-2 md:px-5 font-bold cursor-pointer ${
                            currentStateIndex === 1
                                ? ' text-primary-800 border-b-2 border-b-primary-800'
                                : null
                        }`}>
                        Bandi (
                        {client?.tenders && client?.tenders.length ? client.tenders.length : 0} )
                    </li>
                    <li
                        onClick={() => stateLabelOnClickHandler(2)}
                        aria-hidden="true"
                        className={`py-1 px-2 md:px-5 font-bold cursor-pointer ${
                            currentStateIndex === 2
                                ? ' text-primary-800 border-b-2 border-b-primary-800'
                                : null
                        }`}>
                        Consulenze (
                        {client?.consultancies && client.consultancies.length
                            ? client.consultancies.length
                            : 0}
                        )
                    </li>
                    <li
                        onClick={() => stateLabelOnClickHandler(3)}
                        aria-hidden="true"
                        className={`py-1 px-2 md:px-5 font-bold cursor-pointer ${
                            currentStateIndex === 3
                                ? ' text-primary-800 border-b-2 border-b-primary-800'
                                : null
                        }`}>
                        Allegati (
                        {client?.documents && client.documents.length ? client.documents.length : 0}
                        )
                    </li>
                </ol>
            </div>

            {currentStateIndex === 0 && client ? (
                <div className="space-y-4">
                    <div className="flex space-x-2">
                        <dt>Ragione sociale</dt>
                        <dd className="font-bold">{client?.name ? client.name : ''}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Indirizzo sede legale</dt>
                        <dd className="font-bold">
                            {client?.addresses ? getLegalAddress(client.addresses.legal) : ''}
                        </dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Sede operativa</dt>
                        <dd className="font-bold">
                            {client?.addresses
                                ? getOperationalAddress(client.addresses.operative)
                                : ''}
                        </dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Codici ateco</dt>
                        <dd className="font-bold">
                            {client?.atecoCodes &&
                                client.atecoCodes.map((item) => item.name).join(', ')}
                        </dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Primo contatto Abifin</dt>
                        <dd className="font-bold">{client?.firstContactWithAbifin}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Referente Abifin</dt>
                        <dd className="font-bold">{client?.abifinReference}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Segnalatore Abifin</dt>
                        <dd className="font-bold">{client?.signalman}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Codice fiscale azienda</dt>
                        <dd className="font-bold">{client?.companyFiscalCode}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>PIVA</dt>
                        <dd className="font-bold">{client?.vatNumber}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Email Azienda</dt>
                        <dd className="font-bold">{client?.email}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Telefono azienda</dt>
                        <dd className="font-bold">{client?.phoneNumber}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>PEC Azienda</dt>
                        <dd className="font-bold">{client?.legalEmail}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Codice Univoco</dt>
                        <dd className="font-bold">{client?.uniqueCode}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Abbonato</dt>
                        <dd className="font-bold">
                            {client?.subscription != null
                                ? client?.subscription == '1'
                                    ? 'Si'
                                    : 'No'
                                : ''}
                        </dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Primo Abbonamento</dt>
                        <dd className="font-bold">
                            {client?.firstSubscription != null
                                ? reformatDate(client?.firstSubscription)
                                : '-'}
                        </dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Abbonamento (€)</dt>
                        <dd className="font-bold">{client?.subscriptionCost}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Legale rappresentante</dt>
                        <dd className="font-bold">{client?.legalRepresentative}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>C. Fisc legale rappresentante</dt>
                        <dd className="font-bold">{client?.legalRepresentativeTaxCode}</dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Referente</dt>
                        <dd className="font-bold">{client?.referanceName}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Email referente</dt>
                        <dd className="font-bold">{client?.referanceEmail}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Telefono referente</dt>
                        <dd className="font-bold">{client?.referancePhoneNumber}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Anno costituzione</dt>
                        <dd className="font-bold">{client?.yearOfEstablishment}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Anno avvio attività</dt>
                        <dd className="font-bold">{client?.yearOfStartBusiness}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Anno ultimo fatturato</dt>
                        <dd className="font-bold">{client?.invoiceAmount}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Banca</dt>
                        <dd className="font-bold">{client?.bank}</dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>IBAN</dt>
                        <dd className="font-bold">{client?.bankAccount}</dd>
                    </div>

                    {client?.type === 'Association' ? (
                        <>
                            <div className="flex space-x-2">
                                <dt>Registri</dt>
                                <dd className="font-bold">
                                    {client?.registeries &&
                                        client.registeries.map((item) => item.name).join(', ')}
                                </dd>
                            </div>
                            <div className="flex space-x-2">
                                <dt>Settore</dt>
                                <dd className="font-bold">
                                    {client?.sectors &&
                                        client.sectors.map((item) => item.name).join(', ')}
                                </dd>
                            </div>
                            <div className="flex space-x-2">
                                <dt>Iscrizione registri</dt>
                                <dd className="font-bold">{client?.registrationRegisters}</dd>
                            </div>
                            <div className="flex space-x-2">
                                <dt>Numero iscrizione registro</dt>
                                <dd className="font-bold">{client?.registrationRegistersNumber}</dd>
                            </div>
                            <div className="flex space-x-2">
                                <dt>Affiliazioni federazioni</dt>
                                <dd className="font-bold">{client?.federationAffiliations}</dd>
                            </div>
                            <div className="flex space-x-2">
                                <dt>Data iscrizione registro</dt>
                                <dd className="font-bold">
                                    {client?.registrationRegistersDate
                                        ? reformatDate(client?.registrationRegistersDate)
                                        : ''}
                                </dd>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex space-x-2">
                                <dt>REA</dt>
                                <dd className="font-bold">{client?.reaCode}</dd>
                            </div>
                            <div className="flex space-x-2">
                                <dt>Numero INPS</dt>
                                <dd className="font-bold">{client?.inpsCode}</dd>
                            </div>
                        </>
                    )}

                    <div className="flex space-x-2">
                        <dt>Grandezza impresa</dt>
                        <dd className="font-bold">
                            {client?.companySize ? `${client?.companySize} Impresa` : ''}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Maggioranza femminile</dt>
                        <dd className="font-bold">
                            {client?.femaleMajority != null
                                ? client?.femaleMajority == '1'
                                    ? 'Si'
                                    : 'No'
                                : ''}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Maggioranza giovanile</dt>
                        <dd className="font-bold">
                            {client?.youthMajority != null
                                ? client?.youthMajority == '1'
                                    ? 'Si'
                                    : 'No'
                                : ''}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Link Google Drive</dt>
                        <dd className="font-bold">
                            {client?.driveLink != null ? (
                                <a
                                    href={client?.driveLink}
                                    target="_blank"
                                    className="cursor-pointer text-primary-800 truncate"
                                    rel="noreferrer">
                                    {client?.driveLink}
                                </a>
                            ) : (
                                '-'
                            )}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Note</dt>
                        <dd className="font-bold">
                            <dd className="font-bold">{client?.note}</dd>
                        </dd>
                    </div>
                </div>
            ) : null}

            {currentStateIndex === 1 && client?.tenders ? (
                <DataTable
                    columns={tenderTableHeaders}
                    rows={getTendersRows(tendersData)}
                    sortBtnClick={(i) => onChangeSortBtnClick(i, 'tender')}
                />
            ) : null}

            {currentStateIndex === 2 ? (
                <DataTable
                    columns={consultancyTableHeaders}
                    rows={getConsRows(client)}
                    sortBtnClick={(i) => onChangeSortBtnClick(i, 'consultancy')}
                />
            ) : null}

            {currentStateIndex === 3 ? (
                <DataTable columns={FILES_TABLE_COLUMNS} rows={getDocumentsRows(client)} />
            ) : null}
        </section>
    );
}

export default Client;
