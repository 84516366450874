/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import PageHeader from '../../component/PageHeader';
import PrimaryButton from '../../component/PrimaryButton';
import RoundButton from '../../component/RoundButton';
import TableHeader from '../../component/TableHeader';
import AssociateClient from '../../component/modal/AssociateClient';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import CreateTender from '../../component/modal/CreateTender';
import CsvUpload from '../../component/modal/CsvUpload';
import FileUpload from '../../component/modal/FileUpload';
import MatchingClientTender from '../../component/modal/MatchingClientTender';
import TenderFilters from '../../component/modal/TenderFilter';
import ExportModal from '../../component/modal/exportModal';
import { INIT_TENDER_FILTER, MESSAGES, TENDER_FIELDS, TENDER_TABLE_COLUMNS } from '../../constants';
import { addManualMarching } from '../../helper/services/apis/common-services';
import {
    archiveTender,
    deleteTender,
    getTendersListData,
    importCsvForConsultancy,
    updateTenderFields
} from '../../helper/services/apis/tender-services';
import { buildQueryChunck, getPaginateData, reformatDate } from '../../helper/utility';

function Tenders() {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Define data table state
     */
    const [columns, setColumns] = useState([...TENDER_TABLE_COLUMNS]);
    const [paginateData, setPaginateData] = useState(null);
    const [filters, setFilters] = useState({ ...INIT_TENDER_FILTER });
    const [selectAll, setSelectAll] = useState(false);
    const [searchString, setSearchString] = useState('');
    // const [tenderFields, setTenderFields] = useState({ ...TENDER_FIELDS });

    const [tenders, setTenders] = useState([]);
    const [showMatchingBtn, setShowMatchingBtn] = useState(false);

    /**
     * Modal status handler
     */
    const [isTenderCreateOpen, setIsTenderCreateOpen] = useState(false);
    const [isArchiveConfirmOpen, setIsArchiveConfirmOpen] = useState(false);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [isAssociateClientOpen, setAssociateClientOpen] = useState(false);
    const [isFiltersModal, setIsFiltersModal] = useState(false);
    const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);
    const [isExportOpen, setIsExportOpen] = useState(false);
    const [isMatchingModalOpen, setIsMatchingOpen] = useState(false);

    const [exportData, setExportData] = useState(null);

    const [selectedTender, setSelectedTender] = useState(null);

    const [isArchivedList, setIsArchivedList] = useState(false);
    const [isImportCsv, setIsImportCsv] = useState(false);
    // eslint-disable-next-line no-unused-vars
    // const [user, setUser] = useState(JSON.parse(Storage.get('profile')));

    const importBtnClickHandler = () => {
        setIsImportCsv(true);
    };

    const onCloseImportCsvModel = () => {
        setIsImportCsv(false);
    };

    const filterBtnClickHandler = () => {
        setIsFiltersModal(true);
    };

    const generateQueryString = (qs) => {
        let queryString = qs;

        queryString = buildQueryChunck(queryString, 'archive', isArchivedList);
        // const queryString = `${qs}&archive=${isArchivedList}`;

        if (searchParams.get('clientId')) {
            queryString = buildQueryChunck(queryString, 'clientId', searchParams.get('clientId'));
        }

        if (searchString.length) {
            queryString = buildQueryChunck(queryString, 'searchString', searchString);
        }

        Object.keys(filters).forEach((key) => {
            if (key === 'includedAtecoCodes' && filters[key].length) {
                const value = filters[key].map((item) => item.value).join(',');
                queryString = buildQueryChunck(queryString, key, value);
            } else if (key === 'excludedAtecoCodes' && filters[key].length) {
                const value = filters[key].map((item) => item.value).join(',');
                queryString = buildQueryChunck(queryString, key, value);
            } else if (key === 'classification' && filters[key].length) {
                const value = filters[key].map((item) => item.value).join(',');
                queryString = buildQueryChunck(queryString, key, value);
            } else {
                queryString = filters[key].length
                    ? buildQueryChunck(queryString, key, filters[key])
                    : queryString;
            }
        });

        const result = columns.find((column) => column.isSortable && column.sortStatus != null);
        if (result) {
            queryString = buildQueryChunck(queryString, 'sortColumn', result.column);
            queryString = buildQueryChunck(queryString, 'sortDirection', result.sortStatus);
        }

        return queryString;
    };

    const initTenderList = async (queryString = '') => {
        setIsLoading(true);

        const qs = generateQueryString(queryString);
        const result = await getTendersListData(qs).catch((e) => toast.error(e.message));

        setIsLoading(false);

        if (result.data.data) {
            setTenders([...result.data.data.map((item) => ({ ...item, selected: false }))]);
            setPaginateData({ ...getPaginateData(result) });
        }
    };

    const onCloseMatchingModal = (reload = false) => {
        setIsMatchingOpen(false);
        if (reload) {
            initTenderList();
        }
    };

    const matchingBtnClickHandler = () => {
        setIsMatchingOpen(true);
    };

    const createTenderBtnClickHandler = () => {
        setIsTenderCreateOpen(true);
    };

    const onCloseModelHandler = () => {
        setSelectedTender(null);
        setIsTenderCreateOpen(false);
        initTenderList();
    };

    const handlePagination = (queryString) => {
        initTenderList(queryString);
    };

    const associateOnClickHandler = (tender) => {
        setSelectedTender(tender);
        setAssociateClientOpen(true);
    };

    const editOnClickHandler = (tender) => {
        setSelectedTender(tender);
        setIsTenderCreateOpen(true);
    };
    const attachOnClickHandler = (tender) => {
        setSelectedTender(tender);
        setIsFileUploadOpen(true);
    };
    const onCloseFileUpload = () => {
        setSelectedTender(null);
        setIsFileUploadOpen(false);
    };

    // const getClientOperativeCity = (address) => {
    //     let city = '-';
    //     const operativeAdd = address.find((item) => item.type === 'operative');
    //     if (operativeAdd) {
    //         city = operativeAdd.city;
    //     }
    //     return city;
    // };

    // const getClientOperativeRegion = (address) => {
    //     let region = '-';
    //     const operativeAdd = address.find((item) => item.type === 'operative');
    //     if (operativeAdd) {
    //         region = `${operativeAdd.province.name}/${operativeAdd.region.name}`;
    //     }
    //     return region;
    // };

    // const createTenderCSVRow = (data) => {
    //     const cells = [];
    //     if (data.length) {
    //         let name = '';
    //         let city = '';
    //         let region = '';
    //         let type = '';
    //         data.forEach((client, index) => {
    //             if (index === 0) {
    //                 name = client.name;
    //                 city = getClientOperativeCity(client.addresses);
    //                 region = getClientOperativeRegion(client.addresses);
    //                 type = client.type === 'Company' ? 'Azienda' : 'Associazione';
    //             } else {
    //                 name = `${name} |&| ${client.name}`;
    //                 city = `${city} |&| ${getClientOperativeCity(client.addresses)}`;
    //                 region = `${region} |&| ${getClientOperativeRegion(client.addresses)}`;
    //                 type = `${type} |&| ${client.type === 'Company' ? 'Azienda' : 'Associazione'}`;
    //             }
    //         });
    //         cells.push(name);
    //         cells.push(city);
    //         cells.push(region);
    //         cells.push(type);
    //     } else {
    //         cells.push('-');
    //         cells.push('-');
    //         cells.push('-');
    //         cells.push('-');
    //     }
    //     console.log(cells);
    //     return cells;
    // };

    const closeExportModal = () => {
        setIsExportOpen(false);
    };

    const exportClients = (ids) => {
        const filteredTenders = tenders
            .filter((tender) => ids.includes(tender.id))
            .map((item) => {
                const regions =
                    item?.regions?.length > 0
                        ? item.regions.map((region) => region.name).join(' & ')
                        : null;
                const provinces =
                    item?.provinces?.length > 0
                        ? item.provinces.map((province) => province.name).join(' & ')
                        : null;
                const atecoCodes =
                    item?.atecoCodes?.length > 0
                        ? item.atecoCodes.map((atecoCode) => atecoCode.name).join(' & ')
                        : null;

                const revenue = item?.revenue ? item?.revenue?.name : null;

                const type =
                    item.type === 'Company'
                        ? 'Aziende'
                        : item.type === 'Association'
                        ? 'Associazioni'
                        : 'Aziende e associazioni';

                return {
                    name: item.name,
                    ceiling: item.ceiling,
                    deadline: reformatDate(item.deadline),
                    textualCeiling: item.textualCeiling,
                    matchingClientsCount: item.matchingClientsCount,
                    clientsCount: item.clientsCount,
                    type,
                    atecoCodes,
                    revenueId: revenue,
                    regions,
                    postalCode: item.postalCode,
                    provinces,
                    classification: item.classification,
                    loanPercentage: item.loanPercentage,
                    entity: item.entity,
                    tenderUrl: item.tenderUrl,
                    femaleMajority: item.femaleMajority == '1' ? 'Si' : 'No',
                    youthMajority: item.youthMajority == '1' ? 'Si' : 'No',
                    minimumYearsOfActivity: item.minimumYearsOfActivity,
                    additionalNotes: item.additionalNotes
                };
            });

        setExportData([...filteredTenders]);
        setIsExportOpen(true);

        // TODO : Need to undo below code for csv download
        // const headers = [
        //     'Data inserimento',
        //     'Titolo',
        //     'Plafond',
        //     'Scadenza',
        //     'Tipologia',
        //     'Matching clienti Ragione sociale',
        //     'Matching clienti Città sede operativa',
        //     'Matching clienti Provincia / Regione sede operatova',
        //     'Matching clienti Tipologia',
        //     'Associati clienti Ragione sociale',
        //     'Associati clienti Città sede operativa',
        //     'Associati clienti Provincia / Regione sede operatova',
        //     'Associati clienti Tipologia',
        //     '\r\n'
        // ];

        // const rows = filteredTenders.map((tender) => {
        //     let row = [];

        //     row.push(tender.createdAt);
        //     row.push(tender.name);
        //     row.push(tender.ceiling);
        //     row.push(tender.deadline);
        //     row.push(
        //         tender.type === 'Company'
        //             ? 'Aziende'
        //             : tender.type === 'Association'
        //             ? 'Associazioni'
        //             : 'Aziende e associazioni'
        //     );

        //     row = [...row, ...createTenderCSVRow(tender.matchingClients)];
        //     row = [...row, ...createTenderCSVRow(tender.clients)];

        //     row.push('\r\n');
        //     return row;
        // });

        // let csvData = headers.join(',');
        // rows.forEach((row) => {
        //     csvData += row.join(',');
        // });

        // generateCSV(csvData, 'Bandi');
    };

    const exportOnClickHandler = (tender) => {
        exportClients([tender.id]);
    };

    const exportSelected = (data) => {
        const ids = data.filter((item) => item.selected).map((item) => item.id);
        exportClients(ids);
    };

    const archiveOnClickHandler = (tender) => {
        setSelectedTender(tender);
        setIsArchiveConfirmOpen(true);
    };

    const excludeMatched = async (tender) => {
        setIsLoading(true);
        const payload = {
            clientId: searchParams.get('clientId'),
            tenderId: tender.id,
            action: 'out'
        };
        const result = await addManualMarching(payload).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            setIsLoading(false);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            setIsLoading(false);
            initTenderList();
        }
    };

    const deleteOnClickHandler = (tender) => {
        setSelectedTender(tender);
        setIsDeleteConfirmOpen(true);
    };

    const onApplyFilters = (filtersData) => {
        // setListType('');
        setSearchString('');
        setFilters({ ...filtersData });
        setIsFiltersModal(false);
    };

    const onCloseFilters = (clearFilter = false) => {
        if (clearFilter) {
            setFilters({ ...INIT_TENDER_FILTER });
        }
        setIsFiltersModal(false);
    };

    const onCancelFilters = () => {
        setFilters({ ...INIT_TENDER_FILTER });
        onCloseFilters();
    };

    useEffect(() => {
        console.log('Filters Update', filters);
    }, [filters]);

    const closeConfirmClickHandler = (type) => {
        if (type === 'archive') {
            setSelectedTender(null);
            setIsArchiveConfirmOpen(false);
        } else {
            setSelectedTender(null);
            setIsDeleteConfirmOpen(false);
        }
    };

    const archiveTenders = async (ids) => {
        const result = await archiveTender({ tenderIds: ids }).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            closeConfirmClickHandler('archive');
            initTenderList();
        }
    };

    const ArchiveConfirmBtnClickHandler = async (tender) => {
        archiveTenders([tender.id]);
    };

    const archiveSelected = (data) => {
        const ids = data.filter((item) => item.selected).map((item) => item.id);

        archiveTenders(ids);
    };

    const DeleteConfirmBtnClickHandler = async (tender) => {
        const result = await deleteTender(tender.id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            closeConfirmClickHandler('delete');
            initTenderList();
        }
    };

    /**
     *
     * @param {*} index : row index
     * @param {*} value : row selected or not
     */
    const onSelectHandler = (index, value) => {
        const item = tenders[index];
        item.selected = value;
        const tempData = [...tenders];
        tempData[index] = { ...item };
        setTenders([...tempData]);
    };

    /**
     *
     * @param {*} value : selectedAll
     * @param {*} data : clients
     */
    const selectAllChangeHandler = (value, data) => {
        const tempClients = [...data];
        if (value) {
            setTenders(tempClients.map((item) => ({ ...item, selected: true })));
        } else {
            setTenders(tempClients.map((item) => ({ ...item, selected: false })));
        }
    };

    const isAnalyzedOnChangedHandler = async (value, id) => {
        setIsLoading(true);
        const payload = { is_analyzed: value };
        const result = await updateTenderFields(id, payload).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        setIsLoading(false);
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            initTenderList();
        }
    };

    const selectClassificationForFilter = (classification) => {
        if (classification.length) {
            const newFilters = { ...INIT_TENDER_FILTER };
            newFilters.classification = [{ value: classification, label: classification }];
            setFilters({ ...newFilters });
        }
    };

    /**
     *
     * @param {*} rows
     * @returns Datatable rows
     */
    const getRows = (rows) => {
        return rows.map((tender) => ({
            className: '',
            selected: tender.selected,
            cells: [
                { template: tender.createdAt },
                {
                    template: (
                        <Link
                            className="text-primary-800 cursor-pointer"
                            to={`../tender/${tender.id}`}>
                            {tender.name}
                        </Link>
                    )
                },
                {
                    template: (
                        <input
                            type="checkbox"
                            checked={tender.isAnalyzed}
                            className="cursor-pointer"
                            onChange={(e) =>
                                isAnalyzedOnChangedHandler(e.target.checked, tender.id)
                            }
                        />
                    )
                },
                { template: tender.ceiling },
                { template: reformatDate(tender.deadline) },
                {
                    template: (
                        <Link to={`../clients?tenderId=${tender.id}`} className="text-primary-800">
                            {tender.matchingClientsCount}
                        </Link>
                    )
                },
                {
                    template: (
                        <Link
                            to={`../clients?tenderId=${tender.id}&subscription=true`}
                            className="text-primary-800">
                            {tender.subscribedMatchingClients}
                        </Link>
                    )
                },
                {
                    template:
                        tender.clientsCount > 0 ? (
                            <Link
                                to={`../tender/${tender.id}?tabIndex=1`}
                                className="text-primary-800">
                                {tender.clientsCount}
                            </Link>
                        ) : (
                            <span className="text-primary-800">0</span>
                        )
                },
                {
                    template: (
                        <span
                            aria-hidden="true"
                            className="text-primary-800 cursor-pointer"
                            onClick={() => selectClassificationForFilter(tender?.classification)}>
                            {tender?.classification}
                        </span>
                    )
                },
                {
                    template:
                        tender.type === 'Company'
                            ? 'Aziende'
                            : tender.type === 'Association'
                            ? 'Associazioni'
                            : 'Aziende e associazioni'
                },
                {
                    template: (
                        <div className="relative inline-block">
                            <RoundButton className="bg-grey-700 text-black peer text-mainBlack hover:bg-grey-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-grey-700 font-medium rounded-full text-sm p-2.5 text-center">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                    />
                                </svg>
                            </RoundButton>
                            <div className="absolute right-[0%] z-10 hidden w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                                {!tender.archive && tender.matchingClientsCount > 0 ? (
                                    <span
                                        className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                        onClick={() => associateOnClickHandler(tender)}
                                        aria-hidden="true">
                                        Associa cliente
                                    </span>
                                ) : null}
                                {/* <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => detailOnClickHandler(tender)}
                                    aria-hidden="true">
                                    Dettaglio bando
                                </span> */}
                                <Link
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    to={`../tender/${tender.id}`}>
                                    Dettaglio bando
                                </Link>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => editOnClickHandler(tender)}
                                    aria-hidden="true">
                                    Modifica bando
                                </span>
                                {!tender.archive ? (
                                    <span
                                        className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                        onClick={() => attachOnClickHandler(tender)}
                                        aria-hidden="true">
                                        Allega file
                                    </span>
                                ) : null}
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => exportOnClickHandler(tender)}
                                    aria-hidden="true">
                                    Esporta
                                </span>
                                {!tender.archive ? (
                                    <span
                                        className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                        onClick={() => archiveOnClickHandler(tender)}
                                        aria-hidden="true">
                                        Archivia bando
                                    </span>
                                ) : null}

                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => deleteOnClickHandler(tender)}
                                    aria-hidden="true">
                                    Elimina bando
                                </span>
                                {showMatchingBtn && (
                                    <span
                                        className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                        onClick={() => excludeMatched(tender)}
                                        aria-hidden="true">
                                        Escludi da match
                                    </span>
                                )}
                            </div>
                        </div>
                    )
                }
            ]
        }));
    };

    /**
     *
     * @param {*} index : of column
     */
    const onChangeSortBtnClick = (index) => {
        let tempData = [...columns];
        const singleColumn = { ...tempData[index] };
        if (!singleColumn.sortStatus) {
            singleColumn.sortStatus = 'asc';
        } else if (singleColumn.sortStatus === 'asc') {
            singleColumn.sortStatus = 'desc';
        } else {
            singleColumn.sortStatus = null;
        }
        tempData = tempData.map((item) => ({ ...item, sortStatus: null }));
        tempData[index] = { ...singleColumn };
        setColumns([...tempData]);

        // TODo : Set State of sorting column
        // TODo : make api call
    };

    /**
     *
     * @param {*} value
     * Set search string
     */
    const onChangeSearchString = (event) => {
        setSearchString(event.target.value);
    };

    /**
     * Clear search string
     */
    const onClearSearchClick = () => {
        setSearchString('');
        // TODo : make api call
    };

    const onPressEnterForSearch = (event) => {
        if (event.which === 13 || event.key === 'Enter') {
            setFilters({ ...INIT_TENDER_FILTER });
            initTenderList();
        }
    };

    const onAssociateModalClose = () => {
        setSelectedTender(null);
        setAssociateClientOpen(false);
        initTenderList();
    };

    const uploadCsv = async (file) => {
        const result = await importCsvForConsultancy({ csv: file }).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            onCloseImportCsvModel();
            initTenderList();
        }
    };

    /**
     * Based On selected rows change state of selectAll
     */
    useEffect(() => {
        let result = true;
        if (tenders.length) {
            for (let i = 0; i < tenders.length; i++) {
                const element = tenders[i];
                if (!element.selected) {
                    result = false;
                    break;
                }
            }
        } else {
            result = false;
        }
        setSelectAll(result);
    }, [tenders]);

    useEffect(() => {
        initTenderList();
    }, [filters, columns, isArchivedList]);

    useEffect(() => {
        if (searchParams.get('clientId')) {
            setShowMatchingBtn(true);
        } else {
            setShowMatchingBtn(false);
        }
    }, [searchParams.get('clientId')]);

    return (
        <section>
            {isLoading ? <Loader /> : null}

            {isImportCsv ? (
                <CsvUpload
                    isOpen={isImportCsv}
                    closeModel={onCloseImportCsvModel}
                    onSubmit={uploadCsv}
                />
            ) : null}

            {isMatchingModalOpen && (
                <MatchingClientTender
                    isOpen={isMatchingModalOpen}
                    clientId={searchParams.get('clientId')}
                    closeModel={onCloseMatchingModal}
                />
            )}

            {isExportOpen ? (
                <ExportModal
                    isOpen={isExportOpen}
                    data={exportData}
                    fields={TENDER_FIELDS.map((item) => ({ ...item }))}
                    fileName="Bandi"
                    closeModel={closeExportModal}
                />
            ) : null}

            {isFiltersModal ? (
                <TenderFilters
                    isOpen={isFiltersModal}
                    filters={filters}
                    applyFilter={onApplyFilters}
                    closeModel={onCloseFilters}
                    clearModel={onCancelFilters}
                />
            ) : null}
            {isAssociateClientOpen ? (
                <AssociateClient
                    isOpen={isAssociateClientOpen}
                    selectedTender={selectedTender}
                    closeModel={onAssociateModalClose}
                />
            ) : null}

            {isFileUploadOpen ? (
                <FileUpload
                    isOpen={isFileUploadOpen}
                    type="tender"
                    id={selectedTender?.id}
                    closeModel={onCloseFileUpload}
                />
            ) : null}

            {isArchiveConfirmOpen ? (
                <ConfirmationModal
                    isOpen={isArchiveConfirmOpen}
                    onConfirmAction={() => ArchiveConfirmBtnClickHandler(selectedTender)}
                    title="Archivia"
                    message="Sei sicuro di voler procedere con l’archiviazione?"
                    closeModel={() => closeConfirmClickHandler('archive')}
                />
            ) : null}

            {isDeleteConfirmOpen ? (
                <ConfirmationModal
                    isOpen={isDeleteConfirmOpen}
                    onConfirmAction={() => DeleteConfirmBtnClickHandler(selectedTender)}
                    title="Eliminazione"
                    message="Sei sicuro di voler procedere con l’eliminazione?"
                    closeModel={() => closeConfirmClickHandler('delete')}
                />
            ) : null}

            {isTenderCreateOpen ? (
                <CreateTender
                    isOpen={isTenderCreateOpen}
                    closeModel={onCloseModelHandler}
                    selectedTender={selectedTender}
                />
            ) : null}

            {/* <CreateTender isOpen={isTenderCreateOpen} closeModel={onCloseModelHandler} /> */}
            <PageHeader
                title="Bandi"
                importClickHandler={importBtnClickHandler}
                filterClickHandler={filterBtnClickHandler}
                filters={filters}>
                {showMatchingBtn && (
                    <PrimaryButton onClick={matchingBtnClickHandler}>+ MATCHING</PrimaryButton>
                )}

                <PrimaryButton onClick={createTenderBtnClickHandler}>+ BANDO</PrimaryButton>
            </PageHeader>

            <TableHeader
                isSearch
                searchString={searchString}
                onChangeSearchString={onChangeSearchString}
                onClearSearchClick={onClearSearchClick}
                onPressEnter={onPressEnterForSearch}>
                <div className="flex items-center font-bold text-sm space-x-2">
                    <input
                        id="checked-checkbox"
                        type="checkbox"
                        value=""
                        className="w-4 h-4"
                        checked={selectAll}
                        onChange={(event) => selectAllChangeHandler(event.target.checked, tenders)}
                    />
                    <span
                        className={`cursor-pointer ${isArchivedList ? '' : 'text-primary-800'}`}
                        onClick={() => setIsArchivedList(false)}
                        aria-hidden="true">
                        ATTIVI
                    </span>
                    <span>|</span>
                    <span
                        className={`cursor-pointer ${isArchivedList ? 'text-primary-800' : ''}`}
                        onClick={() => setIsArchivedList(true)}
                        aria-hidden="true">
                        ARCHIVIATI
                    </span>
                    <span>|</span>
                    <span>
                        <div className="relative inline-block">
                            <RoundButton className="peer bg-transparent text-black hover:bg-transparent font-medium rounded-full text-sm p-2.5 text-center">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                    />
                                </svg>
                            </RoundButton>
                            <div className="absolute right-[0%] z-10 hidden w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => exportSelected(tenders)}
                                    aria-hidden="true">
                                    Esporta
                                </span>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => archiveSelected(tenders)}
                                    aria-hidden="true">
                                    Archivia
                                </span>
                            </div>
                        </div>
                    </span>
                </div>
            </TableHeader>

            <DataTable
                columns={columns}
                rows={getRows(tenders)}
                paginateData={paginateData}
                handlePagination={handlePagination}
                isSelectable
                onSelectChangeHandler={onSelectHandler}
                sortBtnClick={onChangeSortBtnClick}
            />
        </section>
    );
}

export default Tenders;
