/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants';
import { clientAssignConsultancy } from '../../helper/services/apis/client-services';
import { getClientDropdown } from '../../helper/services/apis/common-services';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

function AssociateClientWithConsultancy({
    isOpen = false,
    type = '',
    closeModel = null,
    selectedConsultancy
}) {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const [clientOptions, setClientOptions] = useState([]);

    const onSubmitHandler = async (data) => {
        clientAssignConsultancy(data.clientId, { consultancyId: selectedConsultancy.id })
            .then((resp) => {
                toast.success(MESSAGES.SUCCESS);
                closeModel();
            })
            .catch((e) => {
                const message =
                    e.message.indexOf('Duplicate entry') > 0
                        ? 'Il associa è già associato al cliente selezionato.'
                        : e.message;
                toast.error(message);
                return false;
            });
    };

    const loadClients = async (type, id) => {
        const result = await getClientDropdown(type, id).catch((e) => toast.error(e.message));
        if (result.data) {
            setClientOptions([...result.data]);
        }
    };

    useEffect(() => {
        loadClients(selectedConsultancy?.id);
    }, []);

    return (
        <ModalComponent isOpen={isOpen}>
            {/* Modal header  */}
            <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                <h3 className="text-xl font-semibold text-white">Associa cliente</h3>
                <button
                    type="button"
                    className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={closeModel}>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    <div className="flex flex-col w-full space-y-4">
                        <div className="w-full input-field-group">
                            <label>
                                {selectedConsultancy?.type === 'marketing'
                                    ? 'Consulenza marketing'
                                    : 'Consulenze finanziarie'}
                            </label>
                            <input type="text" disabled value={selectedConsultancy?.description} />
                        </div>

                        <div
                            className={`w-full input-field-group ${
                                errors?.clientId ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="clientId">Cliente*</label>
                            <select
                                id="clientId"
                                name="clientId"
                                {...register('clientId', { required: true })}>
                                <option value="">Seleziona...</option>
                                {clientOptions.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            {errors?.clientId?.type === 'required' && (
                                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                    <PrimaryButton type="submit">AGGIUNGI CLIENTE</PrimaryButton>
                    <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default AssociateClientWithConsultancy;
