/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants';
import { uploadDocument } from '../../helper/services/apis/common-services';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

function FileUpload({ isOpen, type, closeModel = null, id }) {
    const [dragActive, setDragActive] = React.useState(false);
    const [files, setFiles] = useState([]);

    const onChange = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setFiles([...event.target.files]);
    };

    const onSubmitHandler = async () => {
        const result = await uploadDocument({ media: files[0], type, id }).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            closeModel();
        }
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFiles([...e.dataTransfer.files]);
        }
    };

    return (
        <ModalComponent isOpen={isOpen}>
            {/* Modal header  */}
            <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                <h3 className="text-xl font-semibold text-white">Allega file</h3>
                <button
                    type="button"
                    className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={closeModel}>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>

            {/* Modal Body */}
            <div className="p-4">
                {files.length > 0 ? (
                    <div className="flex flex-col justify-center items-center">
                        {files.map((item) => (
                            <span key={item.name} className="font-bold">
                                {item.name}
                            </span>
                        ))}
                    </div>
                ) : (
                    <div
                        className={`transition ease-in-out space-y-6 flex flex-col w-full justify-center items-center p-12 border-dashed border-2 box-border ${
                            dragActive ? 'border-4 border-primary-800' : ''
                        }`}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}>
                        <label htmlFor="photo-upload-input">
                            <svg
                                width="71"
                                height="64"
                                viewBox="0 0 71 64"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer">
                                <path
                                    d="M39.05 24.8889H56.8L35.5 46.2222L14.2 24.8889H31.95V0H39.05V24.8889ZM7.1 56.8889H63.9V32H71V60.4444C71 61.3874 70.626 62.2918 69.9602 62.9586C69.2945 63.6254 68.3915 64 67.45 64H3.55C2.60848 64 1.70552 63.6254 1.03977 62.9586C0.374017 62.2918 0 61.3874 0 60.4444V32H7.1V56.8889Z"
                                    fill="#333333"
                                />
                            </svg>
                        </label>
                        <input
                            id="photo-upload-input"
                            type="file"
                            onChange={onChange}
                            className="hidden"
                            multiple="false"
                        />
                        <span className="font-bold">
                            <span className="text-primary-800">Seleziona file</span> o trascina qui
                        </span>
                    </div>
                )}
            </div>

            {/* Modal Footer */}
            {files.length > 0 ? (
                <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                    <PrimaryButton onClick={onSubmitHandler}>ALLEGA FILE</PrimaryButton>
                    <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
                </div>
            ) : null}
        </ModalComponent>
    );
}

export default FileUpload;
