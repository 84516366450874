/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import AssociateAction from '../../component/modal/AssociateAction';
import ClientFilters from '../../component/modal/ClientFilter';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import CreateClient from '../../component/modal/CreateClient';
import CsvUpload from '../../component/modal/CsvUpload';
import FileUpload from '../../component/modal/FileUpload';
import PageHeader from '../../component/PageHeader';
import PrimaryButton from '../../component/PrimaryButton';
import RoundButton from '../../component/RoundButton';
import TableHeader from '../../component/TableHeader';
import { CLIENT_FIELDS, CLIENT_TABLE_COLUMNS, INIT_CLIENT_FILTER, MESSAGES } from '../../constants';
import {
    deleteClient,
    getClientListData,
    importCsvForConsultancy
} from '../../helper/services/apis/client-services';
import { buildQueryChunck, generateCSV, getPaginateData, reformatDate } from '../../helper/utility';
import ExportModal from '../../component/modal/exportModal';
import MatchingClientTender from '../../component/modal/MatchingClientTender';
import { addManualMarching } from '../../helper/services/apis/common-services';

function Clients() {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Define data table state
     */
    const [column, setColumn] = useState([...CLIENT_TABLE_COLUMNS]);
    const [paginateData, setPaginateData] = useState(null);
    const [filters, setFilters] = useState({ ...INIT_CLIENT_FILTER });
    const [selectAll, setSelectAll] = useState(false);
    const [searchString, setSearchString] = useState('');

    const [clients, setClients] = useState([]);
    const [showMatchingBtn, setShowMatchingBtn] = useState(false);

    /**
     * Modal status handler
     */
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [isCompanyCreateOpen, setIsCompanyCreateOpen] = useState(false);
    const [isAssociateClientOpen, setAssociateClientOpen] = useState(false);
    const [isFiltersModal, setIsFiltersModal] = useState(false);
    const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);
    const [isExportOpen, setIsExportOpen] = useState(false);
    const [exportData, setExportData] = useState(null);
    const [isMatchingModalOpen, setIsMatchingOpen] = useState(false);

    const [selectedClient, setSelectedClient] = useState(null);
    const [isSubscription, setIsSubscription] = useState(false);

    const [isCompany, setIsCompany] = useState(false);
    const [listType, setListType] = useState(null);
    const [isImportCsv, setIsImportCsv] = useState(false);

    const importBtnClickHandler = () => {
        setIsImportCsv(true);
    };

    const onCloseImportCsvModel = () => {
        setIsImportCsv(false);
    };

    const filterBtnClickHandler = () => {
        setIsFiltersModal(true);
    };

    const generateQueryString = (qs) => {
        let queryString = qs;

        /**
         * Filter based on type
         */
        if (listType) {
            // queryString = `${queryString}type=${listType}`;
            queryString = buildQueryChunck(queryString, 'type', listType);
        }
        if (searchParams.get('tenderId')) {
            queryString = buildQueryChunck(queryString, 'tenderId', searchParams.get('tenderId'));
        }
        if (searchParams.get('subscription')) {
            queryString = buildQueryChunck(queryString, 'subscription', 1);
        }

        if (searchParams.get('consultancyId')) {
            queryString = buildQueryChunck(
                queryString,
                'consultancyId',
                searchParams.get('consultancyId')
            );
        }

        if (searchString.length) {
            queryString = buildQueryChunck(queryString, 'searchString', searchString);
        }

        Object.keys(filters).forEach((key) => {
            if (key === 'atecoCodes' && filters[key].length) {
                const value = filters[key].map((item) => item.value).join(',');
                queryString = buildQueryChunck(queryString, key, value);
            } else {
                queryString = filters[key].length
                    ? buildQueryChunck(queryString, key, filters[key])
                    : queryString;
            }
        });

        const result = column.find((column) => column.isSortable && column.sortStatus != null);
        if (result) {
            queryString = buildQueryChunck(queryString, 'sortColumn', result.column);
            queryString = buildQueryChunck(queryString, 'sortDirection', result.sortStatus);
        }

        return queryString;
    };

    const initClientList = async (queryString = '') => {
        setIsLoading(true);

        const qs = generateQueryString(queryString);
        const result = await getClientListData(qs).catch((e) => toast.error(e.message));

        setIsLoading(false);

        if (result.data.data) {
            setClients([
                ...result.data.data.map((item) => {
                    const addresses = { legal: [], operative: [] };
                    item.addresses.forEach((item) => {
                        if (item.type === 'operative') {
                            addresses.operative.push({ ...item });
                        } else if (item.type === 'legal') {
                            addresses.legal.push({ ...item });
                        }
                    });

                    return { ...item, selected: false, addresses: { ...addresses } };
                })
            ]);
            setPaginateData({ ...getPaginateData(result) });
        }
    };

    const onCloseMatchingModal = (reload = false) => {
        setIsMatchingOpen(false);
        if (reload) {
            initClientList();
        }
    };

    const matchingBtnClickHandler = () => {
        setIsMatchingOpen(true);
    };

    const createOnClickHandler = (company) => {
        setIsCompany(company);
        setIsCompanyCreateOpen(true);
    };

    const onCloseModelHandler = () => {
        setSelectedClient(null);
        setIsCompany(true);
        setIsCompanyCreateOpen(false);
        initClientList();
    };

    const handlePagination = (queryString) => {
        initClientList(queryString);
    };

    /**
     * Get Addresses, region, province, city based on selected type
     * @param {*} address
     * @param {'legal' || 'operative'} type
     * @param {'city' || 'region' || 'province' || 'full'} field
     */
    const getAddress = (addresses, type, field) => {
        let result = '-';
        // const address = addresses[type].find((item) => item.type === type);
        const address = addresses[type];
        if (field === 'city') {
            // result = address[field];
            const temp = address.map((item) => item?.city);
            if (temp.length) {
                result = temp.join('| ');
            }
        } else if (field === 'region' || field === 'province') {
            // result = address[field].name;
            const temp = address.map((item) => item[field].name);
            if (temp.length) {
                result = temp.join('| ');
            }
        } else if (field === 'full') {
            // result = `${address.houseNumber}-${address.postalCode} ${address.province.name} ${address.region.name} ${address.city}`;
            const temp = address.map(
                (item) =>
                    `${item.houseNumber}-${item.postalCode} ${item.province.name} ${item.region.name} ${item.city}`
            );
            if (temp.length) {
                result = temp.join('| ');
            }
        }
        return result;
    };

    const getClientOperativeCity = (address) => {
        let city = '-';
        const operativeAdd = address
            .filter((item) => item.type === 'operative')
            .map((item) => item?.city);
        if (operativeAdd.length) {
            city = operativeAdd.join(', ');
        }
        return city;
    };

    const getClientOperativeRegion = (address) => {
        let region = '-';
        const operativeAdd = address
            .filter((item) => item.type === 'operative')
            .map((item) => `${item?.province?.name}/${item?.region?.name}`);
        if (operativeAdd.length) {
            region = operativeAdd.join(', ');
        }
        return region;
    };

    const associateOnClickHandler = (client) => {
        setSelectedClient(client);
        setAssociateClientOpen(true);
    };

    const onAssociateModalClose = () => {
        setSelectedClient(null);
        setAssociateClientOpen(false);
        initClientList();
    };

    const editOnClickHandler = (client) => {
        setIsCompany(client.type === 'Company');
        setSelectedClient(client);
        setIsCompanyCreateOpen(true);
    };

    const closeExportModal = () => {
        setIsExportOpen(false);
    };

    const exportClients = (ids) => {
        const filteredClients = clients
            .filter((client) => ids.includes(client.id))
            .map((item) => {
                const atecoCodes =
                    item?.atecoCodes?.length > 0
                        ? item?.atecoCodes.map((atecoCode) => atecoCode.name).join(' & ')
                        : null;

                const registeries =
                    item?.registeries?.length > 0
                        ? item?.registeries.map((item) => item.name).join(' & ')
                        : null;

                const sectors =
                    item?.sectors?.length > 0
                        ? item?.sectors.map((item) => item.name).join(' & ')
                        : null;

                return {
                    createdAt: item.createdAt,
                    name: item.name,
                    operativeAddressCity: getAddress(item.addresses, 'operative', 'city'),
                    operativeAddressRegion: `${getAddress(
                        item.addresses,
                        'operative',
                        'province'
                    )} / ${getAddress(item.addresses, 'operative', 'region')}`,
                    matchingTendersCount: item.matchingTendersCount,
                    tendersCount: item.tendersCount,
                    documentsCount: item.documentsCount,
                    type: item.type === 'Company' ? 'Azienda' : 'Associazione',
                    legalAddress: getAddress(item.addresses, 'legal', 'full'),
                    operativeAddress: getAddress(item.addresses, 'operative', 'full'),
                    atecoCodes,
                    firstContactWithAbifin: item.firstContactWithAbifin,
                    abifinReference: item.abifinReference,
                    companyFiscalCode: item.companyFiscalCode,
                    vatNumber: item.vatNumber,
                    email: item.email,
                    phoneNumber: item.phoneNumber,
                    legalEmail: item.legalEmail,
                    uniqueCode: item.uniqueCode,
                    subscription: item.subscription,
                    subscriptionCost: item.subscriptionCost,
                    referanceName: item.referanceName,
                    referanceEmail: item.referanceEmail,
                    referancePhoneNumber: item.referancePhoneNumber,
                    yearOfEstablishment: item.yearOfEstablishment,
                    yearOfStartBusiness: item.yearOfStartBusiness,
                    lastYearTurnover: item.lastYearTurnover,
                    bank: item.bank,
                    bankAccount: item.bankAccount,
                    registeries,
                    sectors,
                    registrationRegisters: item.registrationRegisters,
                    registrationRegistersNumber: item.registrationRegistersNumber,
                    federationAffiliations: item.federationAffiliations,
                    registrationRegistersDate: item.registrationRegistersDate,
                    companySize: item.companySize ? `${item.companySize} Impresa` : null,
                    femaleMajority: item.femaleMajority == '1' ? 'Si' : 'No',
                    youthMajority: item.youthMajority == '1' ? 'Si' : 'No'
                };
            });

        setExportData([...filteredClients]);
        setIsExportOpen(true);

        // const headers = [
        //     'Data inserimento',
        //     'Ragione sociale',
        //     'Città sede operativa',
        //     'Provincia / Regione sede operatova',
        //     'Tipologia',
        //     'Matching Bandi Data inserimento',
        //     'Matching Bandi Titolo',
        //     'Matching Bandi Plafond',
        //     'Matching Bandi Scadenza',
        //     'Matching Bandi Per',
        //     'Matching Bandi Tipologia',
        //     'Associati Bandi Data inserimento',
        //     'Associati Bandi Titolo',
        //     'Associati Bandi Plafond',
        //     'Associati Bandi Scadenza',
        //     'Associati Bandi Per',
        //     'Associati Bandi Tipologia',
        //     'Associati Consulenze Data inserimento',
        //     'Associati Consulenze Descrizione consulenza',
        //     'Associati Consulenze Tipologia',
        //     '\r\n'
        // ];

        // const rows = filteredClients.map((client) => {
        //     const row = [];
        //     row.push(client.createdAt);
        //     row.push(client.name);
        //     row.push(getClientOperativeCity(client.addresses));
        //     row.push(getClientOperativeRegion(client.addresses));
        //     row.push(client.type === 'Company' ? 'Azienda' : 'Associazione');

        //     // Set matching tenders columns
        //     if (client.matchingTenders.length) {
        //         let createdAt = '';
        //         let title = '';
        //         let plafond = '';
        //         let deadline = '';
        //         let per = '';
        //         let type = '';
        //         client.matchingTenders.forEach((tender, index) => {
        //             if (index === 0) {
        //                 createdAt = tender.createdAt;
        //                 title = tender.name;
        //                 plafond = tender.ceiling;
        //                 deadline = reformatDate(tender.deadline);
        //                 per = tender.tenderFor;
        //                 type =
        //                     tender.type === 'Company'
        //                         ? 'Aziende'
        //                         : tender.type === 'Association'
        //                         ? 'Associazioni'
        //                         : 'Aziende e associazioni';
        //             } else {
        //                 createdAt = `${createdAt} |&| ${tender.createdAt}`;
        //                 title = `${title} |&| ${tender.name}`;
        //                 plafond = `${plafond} |&| ${tender.ceiling}`;
        //                 deadline = `${deadline} |&| ${reformatDate(tender.deadline)}`;
        //                 per = `${per} |&| ${tender.tenderFor}`;
        //                 type = `${type} |&| ${
        //                     tender.type === 'Company'
        //                         ? 'Aziende'
        //                         : tender.type === 'Association'
        //                         ? 'Associazioni'
        //                         : 'Aziende e associazioni'
        //                 }`;
        //             }
        //         });
        //         row.push(createdAt);
        //         row.push(title);
        //         row.push(plafond);
        //         row.push(deadline);
        //         row.push(per);
        //         row.push(type);
        //     } else {
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //     }

        //     // Set associate tenders columns
        //     if (client.tenders.length) {
        //         let createdAt = '';
        //         let title = '';
        //         let plafond = '';
        //         let deadline = '';
        //         let per = '';
        //         let type = '';
        //         client.tenders.forEach((tender, index) => {
        //             if (index === 0) {
        //                 createdAt = tender.createdAt;
        //                 title = tender.name;
        //                 plafond = tender.ceiling;
        //                 deadline = reformatDate(tender.deadline);
        //                 per = tender.tenderFor;
        //                 type =
        //                     tender.type === 'Company'
        //                         ? 'Aziende'
        //                         : tender.type === 'Association'
        //                         ? 'Associazioni'
        //                         : 'Aziende e associazioni';
        //             } else {
        //                 createdAt = `${createdAt} |&| ${tender.createdAt}`;
        //                 title = `${title} |&| ${tender.name}`;
        //                 plafond = `${plafond} |&| ${tender.ceiling}`;
        //                 deadline = `${deadline} |&| ${reformatDate(tender.deadline)}`;
        //                 per = `${per} |&| ${tender.tenderFor}`;
        //                 type = `${type} |&| ${
        //                     tender.type === 'Company'
        //                         ? 'Aziende'
        //                         : tender.type === 'Association'
        //                         ? 'Associazioni'
        //                         : 'Aziende e associazioni'
        //                 }`;
        //             }
        //         });
        //         row.push(createdAt);
        //         row.push(title);
        //         row.push(plafond);
        //         row.push(deadline);
        //         row.push(per);
        //         row.push(type);
        //     } else {
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //     }

        //     // Set associate cons columns
        //     if (client.consultancies.length) {
        //         let createdAt = '';
        //         let description = '';
        //         let type = '';

        //         client.consultancies.forEach((consultancy, index) => {
        //             if (index === 0) {
        //                 createdAt = consultancy.createdAt;
        //                 description = consultancy.description;
        //                 type = consultancy.type === 'Marketing' ? 'Marketing' : 'Finanziarie';
        //             } else {
        //                 createdAt = `${createdAt} |&| ${consultancy.createdAt}`;
        //                 description = `${description} |&| ${consultancy.description}`;
        //                 type = `${type} |&| ${
        //                     consultancy.type === 'Marketing' ? 'Marketing' : 'Finanziarie'
        //                 }`;
        //             }
        //         });

        //         row.push(createdAt);
        //         row.push(description);
        //         row.push(type);
        //     } else {
        //         row.push('-');
        //         row.push('-');
        //         row.push('-');
        //     }
        //     row.push('\r\n');
        //     return row;
        // });

        // let csvData = headers.join(',');
        // rows.forEach((row) => {
        //     csvData += row.join(',');
        // });

        // generateCSV(csvData, 'Clienti');
    };

    const exportOnClickHandler = (client) => {
        exportClients([client.id]);
    };

    const exportSelected = (data) => {
        const ids = data.filter((item) => item.selected).map((item) => item.id);

        exportClients(ids);
    };

    const attachOnClickHandler = (client) => {
        setSelectedClient(client);
        setIsFileUploadOpen(true);
    };
    const onCloseFileUpload = (client) => {
        setSelectedClient(null);
        setIsFileUploadOpen(false);
    };

    const excludeMatched = async (client) => {
        setIsLoading(true);
        const payload = {
            clientId: client.id,
            tenderId: searchParams.get('tenderId'),
            action: 'out'
        };
        const result = await addManualMarching(payload).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            setIsLoading(false);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            setIsLoading(false);
            initClientList();
        }
    };

    const deleteOnClickHandler = (client) => {
        setSelectedClient(client);
        setIsDeleteConfirmOpen(true);
    };

    const closeConfirmClickHandler = () => {
        setSelectedClient(null);
        setIsDeleteConfirmOpen(false);
    };

    const onApplyFilters = (filtersData) => {
        setListType('');
        setSearchString('');
        setFilters({ ...filtersData });
        setIsFiltersModal(false);
    };

    const onCloseFilters = (clearFilter = false) => {
        if (clearFilter) {
            setFilters({ ...INIT_CLIENT_FILTER });
        }
        setIsFiltersModal(false);
    };

    const onCancelFilters = () => {
        setFilters({ ...INIT_CLIENT_FILTER });
        onCloseFilters();
    };

    const confirmBtnClickHandler = async (client) => {
        const result = await deleteClient(client.id).catch((e) => {
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            closeConfirmClickHandler();
            initClientList();
        }
    };

    /**
     *
     * @param {*} index : row index
     * @param {*} value : row selected or not
     */
    const onSelectHandler = (index, value) => {
        const item = clients[index];
        item.selected = value;
        const tempData = [...clients];
        tempData[index] = { ...item };
        setClients([...tempData]);
    };

    /**
     *
     * @param {*} value : selectedAll
     * @param {*} data : clients
     */
    const selectAllChangeHandler = (value, data) => {
        const tempClients = [...data];
        if (value) {
            setClients(tempClients.map((item) => ({ ...item, selected: true })));
        } else {
            setClients(tempClients.map((item) => ({ ...item, selected: false })));
        }
    };

    const getAssociationCount = (client) => {
        // let count = 0;
        // count += client.consultancies ? client.consultancies.length : 0;
        // count += client.tenders ? client.tenders.length : 0;
        return client.tendersCount > 0 ? (
            <Link to={`../client/${client.id}?tabIndex=1`} className="text-primary-800">
                {client.tendersCount}
            </Link>
        ) : (
            <span className="text-primary-800">{client.tendersCount}</span>
        );
    };

    const getMatchingCount = (client) => {
        // const count = client.matchingTenders ? client.matchingTenders.length : 0;
        // return client.matchingTendersCount > 0 ? (
        //     <Link to={`../tenders?clientId=${client.id}`} className="text-primary-800">
        //         {client.matchingTendersCount}
        //     </Link>
        // ) : (
        //     <span className="text-primary-800">{client.matchingTendersCount}</span>
        // );
        return (
            <Link to={`../tenders?clientId=${client.id}`} className="text-primary-800">
                {client.matchingTendersCount}
            </Link>
        );
    };

    /**
     *
     * @param {*} rows
     * @returns Datatable rows
     */
    const getRows = (rows) => {
        return rows.map((client) => {
            const cells = [
                { template: client.createdAt },
                {
                    template: (
                        <Link className="text-primary-800" to={`../client/${client.id}`}>
                            {client.name}
                        </Link>
                    )
                },
                { template: getClientOperativeCity(client.addresses.operative) },
                { template: getClientOperativeRegion(client.addresses.operative) },
                { template: getMatchingCount(client) },
                { template: getAssociationCount(client) },
                { template: client.type === 'Company' ? 'Azienda' : 'Associazione' },
                {
                    template: (
                        <div className="relative inline-block">
                            <RoundButton className="bg-grey-700 text-black peer text-mainBlack hover:bg-grey-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-grey-700 font-medium rounded-full text-sm p-2.5 text-center">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                    />
                                </svg>
                            </RoundButton>
                            <div className="absolute right-[0%] z-10 hidden w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => associateOnClickHandler(client)}
                                    aria-hidden="true">
                                    Associa azione
                                </span>
                                <Link
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    to={`../client/${client.id}`}>
                                    Dettaglio cliente
                                </Link>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => editOnClickHandler(client)}
                                    aria-hidden="true">
                                    Modifica cliente
                                </span>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => attachOnClickHandler(client)}
                                    aria-hidden="true">
                                    Allega file
                                </span>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => exportOnClickHandler(client)}
                                    aria-hidden="true">
                                    Esporta
                                </span>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => deleteOnClickHandler(client)}
                                    aria-hidden="true">
                                    Elimina cliente
                                </span>
                                {showMatchingBtn && (
                                    <span
                                        className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                        onClick={() => excludeMatched(client)}
                                        aria-hidden="true">
                                        Escludi da match
                                    </span>
                                )}
                            </div>
                        </div>
                    )
                }
            ];
            if (searchParams.get('tenderId')) {
                cells.splice(
                    4,
                    0,
                    { template: client?.firstContactWithAbifin },
                    { template: client?.abifinReference }
                );
            }
            return {
                className: !isSubscription && client.subscription ? 'bg-[#f0f8ff]' : '',
                selected: client.selected,
                id: client.id,
                cells
            };
        });
    };

    /**
     *
     * @param {*} index : of column
     */
    const onChangeSortBtnClick = (index) => {
        let tempData = [...column];
        const singleColumn = { ...tempData[index] };
        if (!singleColumn.sortStatus) {
            singleColumn.sortStatus = 'asc';
        } else if (singleColumn.sortStatus === 'asc') {
            singleColumn.sortStatus = 'desc';
        } else {
            singleColumn.sortStatus = null;
        }
        tempData = tempData.map((item) => ({ ...item, sortStatus: null }));
        tempData[index] = { ...singleColumn };
        setColumn([...tempData]);
    };

    /**
     *
     * @param {*} value
     * Set search string
     */
    const onChangeSearchString = (event) => {
        setSearchString(event.target.value);
    };

    /**
     * Clear search string
     */
    const onClearSearchClick = () => {
        setSearchString('');
        // TODo : make api call
    };

    const onPressEnterForSearch = (event) => {
        if (event.which === 13 || event.key === 'Enter') {
            setFilters({ ...INIT_CLIENT_FILTER });
            initClientList();
        }
    };

    const uploadCsv = async (file) => {
        const result = await importCsvForConsultancy({ csv: file }).catch((e) => {
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            onCloseImportCsvModel();
            initClientList();
        }
    };

    /**
     * When ever list type change retrieve list.
     */
    useEffect(() => {
        initClientList();
        if (listType === 'Company' || listType === 'Association') {
            setFilters({ ...INIT_CLIENT_FILTER });
        }
    }, [listType]);

    /**
     * When ever list type change retrieve list.
     */
    useEffect(() => {
        initClientList();
    }, [filters, column]);

    /**
     * Based On selected rows change state of selectAll
     */
    useEffect(() => {
        let result = true;
        if (clients.length) {
            for (let i = 0; i < clients.length; i++) {
                const element = clients[i];
                if (!element.selected) {
                    result = false;
                    break;
                }
            }
        } else {
            result = false;
        }
        setSelectAll(result);
    }, [clients]);

    useEffect(() => {
        if (searchParams.get('tenderId')) {
            setShowMatchingBtn(true);
            const newColumns = [...CLIENT_TABLE_COLUMNS];
            newColumns.splice(
                4,
                0,
                {
                    className: '',
                    template: 'Primo contatto Abifin',
                    column: 'first_contact_with_abifin',
                    isSortable: true,
                    sortStatus: null
                },
                {
                    className: '',
                    template: 'Referente Abifin',
                    column: 'abifin_reference',
                    isSortable: true,
                    sortStatus: null
                }
            );
            setColumn([...newColumns]);
        } else {
            setShowMatchingBtn(false);
        }
    }, [searchParams.get('tenderId')]);

    useEffect(() => {
        if (searchParams.get('subscription')) {
            setIsSubscription(true);
        } else {
            setIsSubscription(false);
        }
    }, [searchParams.get('subscription')]);

    return (
        <section>
            {/* <Loader /> */}
            {isLoading ? <Loader /> : null}

            {isImportCsv ? (
                <CsvUpload
                    isOpen={isImportCsv}
                    closeModel={onCloseImportCsvModel}
                    onSubmit={uploadCsv}
                />
            ) : null}

            {isMatchingModalOpen && (
                <MatchingClientTender
                    isOpen={isMatchingModalOpen}
                    tenderId={searchParams.get('tenderId')}
                    closeModel={onCloseMatchingModal}
                />
            )}

            {isExportOpen ? (
                <ExportModal
                    isOpen={isExportOpen}
                    data={exportData}
                    fields={CLIENT_FIELDS.map((item) => ({ ...item }))}
                    fileName="Bandi"
                    closeModel={closeExportModal}
                />
            ) : null}

            {isFiltersModal ? (
                <ClientFilters
                    isOpen={isFiltersModal}
                    filters={filters}
                    applyFilter={onApplyFilters}
                    closeModel={onCloseFilters}
                    clearModel={onCancelFilters}
                />
            ) : null}

            {isAssociateClientOpen ? (
                <AssociateAction
                    isOpen={isAssociateClientOpen}
                    selectedClient={selectedClient}
                    closeModel={onAssociateModalClose}
                />
            ) : null}

            {isFileUploadOpen ? (
                <FileUpload
                    isOpen={isFileUploadOpen}
                    type="client"
                    id={selectedClient?.id}
                    closeModel={onCloseFileUpload}
                />
            ) : null}

            {isDeleteConfirmOpen ? (
                <ConfirmationModal
                    isOpen={isDeleteConfirmOpen}
                    onConfirmAction={() => confirmBtnClickHandler(selectedClient)}
                    title="Eliminazione"
                    message="Sei sicuro di voler procedere con l’eliminazione?"
                    closeModel={() => closeConfirmClickHandler()}
                />
            ) : null}

            {isCompanyCreateOpen ? (
                <CreateClient
                    isOpen={isCompanyCreateOpen}
                    isCompany={isCompany}
                    closeModel={onCloseModelHandler}
                    selectedClient={selectedClient}
                />
            ) : null}

            <PageHeader
                title="Clienti"
                importClickHandler={importBtnClickHandler}
                filterClickHandler={filterBtnClickHandler}
                filters={filters}>
                {showMatchingBtn && (
                    <PrimaryButton onClick={matchingBtnClickHandler}>+ MATCHING</PrimaryButton>
                )}
                <PrimaryButton onClick={() => createOnClickHandler(true)}>+ AZIENDA</PrimaryButton>
                <PrimaryButton onClick={() => createOnClickHandler(false)}>
                    + ASSOCIAZIONE
                </PrimaryButton>
            </PageHeader>

            <TableHeader
                isSearch
                searchString={searchString}
                onChangeSearchString={onChangeSearchString}
                onClearSearchClick={onClearSearchClick}
                onPressEnter={onPressEnterForSearch}>
                <div className="flex items-center font-bold text-sm space-x-2">
                    <input
                        id="checked-checkbox"
                        type="checkbox"
                        value=""
                        className="w-4 h-4"
                        checked={selectAll}
                        onChange={(event) => selectAllChangeHandler(event.target.checked, clients)}
                    />
                    <span
                        className={`cursor-pointer ${listType ? '' : 'text-primary-800'}`}
                        onClick={() => setListType(null)}
                        aria-hidden="true">
                        TUTTI
                    </span>
                    <span>|</span>
                    <span
                        className={`cursor-pointer ${
                            listType === 'Company' ? 'text-primary-800' : ''
                        }`}
                        onClick={() => setListType('Company')}
                        aria-hidden="true">
                        AZIENDA
                    </span>
                    <span>|</span>
                    <span
                        className={`cursor-pointer ${
                            listType === 'Association' ? 'text-primary-800' : ''
                        }`}
                        onClick={() => setListType('Association')}
                        aria-hidden="true">
                        ASSOCIAZIONE
                    </span>
                    <span>|</span>
                    <span>
                        <div className="relative inline-block">
                            <RoundButton className="peer bg-transparent text-black hover:bg-transparent font-medium rounded-full text-sm p-2.5 text-center">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                    />
                                </svg>
                            </RoundButton>
                            <div className="absolute right-[0%] z-10 hidden w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => exportSelected(clients)}
                                    aria-hidden="true">
                                    Esporta
                                </span>
                            </div>
                        </div>
                    </span>
                </div>
            </TableHeader>

            <DataTable
                columns={column}
                rows={getRows(clients)}
                isSelectable
                paginateData={paginateData}
                handlePagination={handlePagination}
                onSelectChangeHandler={onSelectHandler}
                sortBtnClick={onChangeSortBtnClick}
            />
        </section>
    );
}

export default Clients;
