export const API_ENDPOINT = {
    LOGIN: 'auth/login',
    USER: 'user',
    CONSULTANCY: 'consultancy',
    ROLE: 'role',
    REVENUE: 'revenue',
    REGION: 'region',
    PROVINCE: 'province',
    REGISTERIES: 'registeries',
    SECTOR: 'sector',
    ATECO_CODE: 'ateco-code',
    TENDER: 'tender',
    CLIENT: 'client'
};

export const MESSAGES = {
    SUCCESS: 'Completato con successo',
    SERVER_ERROR: 'Errore del server',
    FIELD_REQUIRED: 'Il campo è obbligatorio',
    maxLimitMessage: (limit = '255') => `Questo campo non può superare i ${limit} caratteri`,
    INVALID_EMAIL: `L' email non è valida`,
    INVALID_PHONE_NUMBER: `Il numero di telefono non è valido`
};

export const TENDER_STATUS = {
    Required: { color: '#BBBBBB', label: 'Richiesto' },
    Won: { color: '#72B05D', label: 'Vinto' },
    Waiting: { color: '#D9A24F', label: 'In attesa' },
    Lost: { color: '#E40000', label: 'Perso' },
    Reported: { color: '#BBBBBB', label: 'Segnalato' }
};

export const PHONE_REGEX =
    /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/gm;

export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}/g;

// Valid Examples :  00199, V-00120, I-00120
export const CAP_REGEX = /^(V-|I-)?[0-9]{5}$/;
export const MULTI_CAP_REGEX = /^(V-|I-)?[0-9]{5}([,]{1}(V-|I-)?[0-9]{5})*?$/;

export const NUMBER_REGEX = /[^\d]+/gim;

export const TWO_DECIMAL_NUMBER_REGEX = /[^\d]+/gim;

/**
 * Export fields
 */
export const TENDER_FIELDS = [
    { isSelected: false, header: 'Titolo', field: 'name' },
    { isSelected: false, header: 'Plafond', field: 'ceiling' },
    { isSelected: false, header: 'Scadenza', field: 'deadline' },
    { isSelected: false, header: 'Plafond testuale', field: 'textualCeiling' },
    { isSelected: false, header: 'Matching', field: 'matchingClientsCount' },
    { isSelected: false, header: 'Clienti associati', field: 'clientsCount' },
    { isSelected: false, header: 'Tipologia', field: 'type' },
    { isSelected: false, header: 'Codici ateco', field: 'atecoCodes' },
    { isSelected: false, header: 'Fatturato', field: 'revenueId' },
    { isSelected: false, header: 'Regione', field: 'regions' },
    { isSelected: false, header: 'CAP', field: 'postalCode' },
    { isSelected: false, header: 'Provincia', field: 'provinces' },
    { isSelected: false, header: 'Classificazione', field: 'classification' },
    { isSelected: false, header: 'Percentuale finanziamento', field: 'loanPercentage' },
    { isSelected: false, header: 'Ente', field: 'entity' },
    { isSelected: false, header: 'URl fondo bando', field: 'tenderUrl' },
    { isSelected: false, header: 'Maggioranza femminile', field: 'femaleMajority' },
    { isSelected: false, header: 'Maggioranza giovanile', field: 'youthMajority' },
    { isSelected: false, header: 'Minimo anni di atti', field: 'minimumYearsOfActivity' },
    { isSelected: false, header: 'Note aggiuntive', field: 'additionalNotes' }
];
export const CLIENT_FIELDS = [
    { isSelected: false, header: 'Data inserimento', field: 'createdAt' },
    { isSelected: false, header: 'Ragione sociale', field: 'name' },
    { isSelected: false, header: 'Città sede operativa', field: 'operativeAddressCity' },
    {
        isSelected: false,
        header: 'Provincia / Regione sede operativa',
        field: 'operativeAddressRegion'
    },
    { isSelected: false, header: 'Matching', field: 'matchingTendersCount' },
    { isSelected: false, header: 'Azioni associate', field: 'tendersCount' },
    { isSelected: false, header: 'Allegati', field: 'documentsCount' },
    { isSelected: false, header: 'Tipologia', field: 'type' },
    { isSelected: false, header: 'Indirizzo sede legale', field: 'legalAddress' },
    { isSelected: false, header: 'Sede operativa', field: 'operativeAddress' },
    { isSelected: false, header: 'Codici ateco', field: 'atecoCodes' },
    { isSelected: false, header: 'Primo contatto Abifin', field: 'firstContactWithAbifin' },
    { isSelected: false, header: 'Referente Abifin', field: 'abifinReference' },
    { isSelected: false, header: 'Codice fiscale azienda', field: 'companyFiscalCode' },
    { isSelected: false, header: 'PIVA', field: 'vatNumber' },
    { isSelected: false, header: 'Email Azienda', field: 'email' },
    { isSelected: false, header: 'Telefono azienda', field: 'phoneNumber' },
    { isSelected: false, header: 'PEC Azienda', field: 'legalEmail' },
    { isSelected: false, header: 'Codice Univoco', field: 'uniqueCode' },
    { isSelected: false, header: 'Abbonato', field: 'subscription' },
    { isSelected: false, header: 'Abbonamento (€)', field: 'subscriptionCost' },
    { isSelected: false, header: 'Referente', field: 'referanceName' },
    { isSelected: false, header: 'Email referente', field: 'referanceEmail' },
    { isSelected: false, header: 'Telefono referente', field: 'referancePhoneNumber' },
    { isSelected: false, header: 'Anno costituzione', field: 'yearOfEstablishment' },
    { isSelected: false, header: 'Anno avvio attività', field: 'yearOfStartBusiness' },
    { isSelected: false, header: 'Anno ultimo fatturato', field: 'lastYearTurnover' },
    { isSelected: false, header: 'Banca', field: 'bank' },
    { isSelected: false, header: 'IBAN', field: 'bankAccount' },
    { isSelected: false, header: 'Registri', field: 'registeries' },
    { isSelected: false, header: 'Settore', field: 'sectors' },
    { isSelected: false, header: 'Iscrizione registri', field: 'registrationRegisters' },
    {
        isSelected: false,
        header: 'Numero iscrizione registro',
        field: 'registrationRegistersNumber'
    },
    { isSelected: false, header: 'Affiliazioni federazioni', field: 'federationAffiliations' },
    { isSelected: false, header: 'Data iscrizione registro', field: 'registrationRegistersDate' },
    { isSelected: false, header: 'Grandezza impresa', field: 'companySize' },
    { isSelected: false, header: 'Maggioranza femminile', field: 'femaleMajority' },
    { isSelected: false, header: 'Maggioranza giovanile', field: 'youthMajority' }
];

/**
 * Tables
 */
export const USER_TABLE_COLUMNS = [
    {
        className: '',
        template: 'Data inserimento',
        column: 'created_at',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        template: 'Username',
        column: 'user_name',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        template: 'Email',
        column: 'email',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        template: 'Permessi',
        isSortable: false,
        sortStatus: null
    },
    {
        className: '',
        template: 'Azioni',
        isSortable: false,
        sortStatus: null
    }
];

export const CONSULTANCY_TABLE_COLUMNS = [
    {
        className: '',
        template: 'Data inserimento',
        isSortable: true,
        column: 'created_at',
        sortStatus: null
    },
    {
        className: '',
        template: 'Descrizione consulenza',
        isSortable: true,
        column: 'description',
        sortStatus: null
    },
    {
        className: '',
        template: 'Durata',
        isSortable: true,
        column: 'duration_hours',
        sortStatus: null
    },
    {
        className: '',
        template: 'Costo (€)',
        isSortable: true,
        column: 'cost',
        sortStatus: null
    },
    {
        className: '',
        template: 'Ricavo (€)',
        isSortable: true,
        column: 'revenue',
        sortStatus: null
    },
    {
        className: '',
        template: 'Clienti associati',
        column: 'clients_count',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        template: 'Azioni',
        isSortable: false,
        sortStatus: null
    }
];

export const TENDER_TABLE_COLUMNS = [
    {
        className: '',
        column: 'created_at',
        template: 'Data inserimento',
        isSortable: true,
        sortStatus: null
    },
    { className: '', column: 'name', template: 'Titolo', isSortable: true, sortStatus: null },
    {
        className: '',
        column: 'is_analyzed',
        template: 'Letto e Analizzato',
        isSortable: false,
        sortStatus: null
    },
    {
        className: '',
        column: 'ceiling',
        template: 'Plafond (€)',
        isSortable: true,
        sortStatus: null
    },
    { className: '', column: 'deadline', template: 'Scadenza', isSortable: true, sortStatus: null },
    {
        className: '',
        column: 'matching_clients_count',
        template: 'Matching',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'subscribed_matching_clients',
        template: 'Matching clienti abbonati',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'clients_count',
        template: 'Clienti associati',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'classification',
        template: 'Classificazione',
        isSortable: true,
        sortStatus: null
    },
    { className: '', template: 'Tipologia', isSortable: false, sortStatus: null },
    { className: '', template: 'Azioni', isSortable: false, sortStatus: null }
];

export const CLIENT_TABLE_COLUMNS = [
    {
        className: '',
        template: 'Data inserimento',
        column: 'created_at',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        template: 'Ragione sociale',
        column: 'name',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        template: 'Città sede operativa',
        column: 'client_addresses.city',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        template: 'Provincia / Regione sede operatova',
        column: 'provinces.name',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        template: 'Matching',
        column: 'matching_tenders_count',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        template: 'Azioni associate',
        column: 'tenders_count',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        template: 'Tipologia',
        column: 'type',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        template: 'Azioni',
        isSortable: false
    }
];

export const TENDER2_TABLE_COLUMNS = [
    { className: '', template: 'Data', isSortable: true, sortStatus: null },
    { className: '', template: 'Mese', isSortable: true, sortStatus: null },
    { className: '', template: 'Partecipante', isSortable: true, sortStatus: null },
    { className: '', template: 'Status', isSortable: true, sortStatus: null },
    { className: '', template: 'Richiesta', isSortable: false, sortStatus: null },
    { className: '', template: 'Finanzimento ricevuto', isSortable: true, sortStatus: null },
    { className: '', template: '%', isSortable: true, sortStatus: null },
    { className: '', template: 'Totale', isSortable: true, sortStatus: null },
    { className: '', template: 'Con IVA', isSortable: true, sortStatus: null },
    { className: '', template: 'Rich. in attesa', isSortable: true, sortStatus: null },
    { className: '', template: 'Generato previsto', isSortable: true, sortStatus: null },
    { className: '', template: 'ABIFIN Richiesto', isSortable: true, sortStatus: null },
    { className: '', template: 'Mail info esiti', isSortable: true, sortStatus: null },
    { className: '', template: 'Tel info esiti', isSortable: true, sortStatus: null },
    { className: '', template: 'Azioni', isSortable: true, sortStatus: null }
];

export const FILES_TABLE_COLUMNS = [
    { className: '', template: 'Nome file', isSortable: false, sortStatus: null },
    { className: '', template: 'Data caricamento', isSortable: false, sortStatus: null }
];

export const CLIENT_CONSULTANCY_HEADERS = [
    {
        className: '',
        column: 'description',
        template: 'Descrizione consulenza',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'createdAt',
        template: 'Data inserimento',
        isSortable: true,
        sortStatus: null
    },
    { className: '', column: 'type', template: 'Tipologia', isSortable: true, sortStatus: null },
    {
        className: '',
        column: 'durationHours',
        template: 'Durata ore',
        isSortable: true,
        sortStatus: null
    },
    { className: '', column: 'cost', template: 'Costo (€)', isSortable: true, sortStatus: null },
    {
        className: '',
        column: 'revenue',
        template: 'Ricavo (€)',
        isSortable: true,
        sortStatus: null
    },
    { className: '', template: 'Azioni' }
];

export const CLIENT_TENDER_HEADERS = [
    {
        className: '',
        column: 'createdAt',
        template: 'Servizio',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'displayCreatedAt',
        template: 'Data',
        isSortable: true,
        sortStatus: null
    },
    { className: '', column: 'status', template: 'Status', isSortable: true, sortStatus: null },
    {
        className: '',
        column: 'percentageOfWiningTender',
        template: '% di vittoria',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'subscriptionCost',
        template: 'Abbonamento',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'payedCostForTender',
        template: 'Fisso',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'costEnteredByAbifin',
        template: 'Rendicontazione',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'requiredAmount',
        template: 'Richiesta',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'obtainedAmount',
        template: 'Finanzimento ricevuto',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'percentageAbifinRequest',
        template: '% Abifin',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'imponibile',
        template: 'Ricavi Abifin',
        isSortable: true,
        sortStatus: null
    },
    { className: '', column: 'coniva', template: 'Con IVA', isSortable: true, sortStatus: null },
    {
        className: '',
        column: 'previsto',
        template: 'Generato previsto',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'abifinRichiesto',
        template: 'ABIFIN Richiesto',
        isSortable: true,
        sortStatus: null
    },
    { className: '', template: 'Azioni', isSortable: false, sortStatus: null }
];

export const TENDER_CLIENT_HEADERS = [
    {
        className: '',
        column: 'createdAt',
        template: 'Data',
        isSortable: true,
        sortStatus: null
    },
    { className: '', column: 'name', template: 'Partecipante', isSortable: true, sortStatus: null },
    { className: '', column: 'status', template: 'Status', isSortable: true, sortStatus: null },
    {
        className: '',
        column: 'percentageOfWiningTender',
        template: '% di vittoria',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'subscriptionCost',
        template: 'Abbonamento',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'payedCostForTender',
        template: 'Fisso',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'costEnteredByAbifin',
        template: 'Rendicontazione',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'requiredAmount',
        template: 'Richiesta',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'obtainedAmount',
        template: 'Finanzimento ricevuto',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'percentageAbifinRequest',
        template: '% Abifin',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'imponibile',
        template: 'Ricavi Abifin',
        isSortable: true,
        sortStatus: null
    },
    { className: '', column: 'coniva', template: 'Con IVA', isSortable: true, sortStatus: null },
    {
        className: '',
        column: 'previsto',
        template: 'Generato previsto',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'abifinRichiesto',
        template: 'ABIFIN Richiesto',
        isSortable: true,
        sortStatus: null
    },
    { className: '', template: 'Azioni', isSortable: true, sortStatus: null }
];

export const TENDER_CLIENT_ANALYSIS_HEADERS = [
    { className: '', column: 'name', template: 'Servizio', isSortable: true, sortStatus: null },
    {
        className: '',
        column: 'createdAt',
        template: 'Data',
        isSortable: true,
        sortStatus: null
    },
    // { className: '', column: 'month', template: 'Mese', isSortable: true, sortStatus: null },
    {
        className: '',
        column: 'clientName',
        template: 'Partecipante',
        isSortable: true,
        sortStatus: null
    },
    { className: '', column: 'status', template: 'Status', isSortable: true, sortStatus: null },
    {
        className: '',
        column: 'percentageOfWiningTender',
        template: '% di vittoria',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'subscriptionCost',
        template: 'Abbonamento',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'payedCostForTender',
        template: 'Fisso',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'costEnteredByAbifin',
        template: 'Rendicontazione',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'requiredAmount',
        template: 'Richiesta',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'obtainedAmount',
        template: 'Finanziamento ricevuto',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'percentageAbifinRequest',
        template: '% Abifin',
        isSortable: true,
        sortStatus: null
    },

    {
        className: '',
        column: 'imponibile',
        template: 'Ricavi Abifin',
        isSortable: true,
        sortStatus: null
    },
    { className: '', column: 'coniva', template: 'Con IVA', isSortable: true, sortStatus: null },
    {
        className: '',
        column: 'previsto',
        template: 'Generato previsto',
        isSortable: true,
        sortStatus: null
    },
    {
        className: '',
        column: 'abifinRichiesto',
        template: 'ABIFIN Richiesto',
        isSortable: true,
        sortStatus: null
    }
];

export const REPORT_DATATABLE_COLUMN = [
    { className: 'border-x-2', template: 'Mese', isSortable: false, sortStatus: null },
    {
        className: 'border-x-2',
        template: 'RICHIESTO DAI CLIENTI',
        isSortable: false,
        sortStatus: null
    },
    {
        className: 'border-x-2',
        template: 'BANDI CONSEGNATI',
        isSortable: false,
        sortStatus: null
    },
    {
        className: 'border-x-2',
        template: 'RICHIESTO DA ABIFIN',
        isSortable: false,
        sortStatus: null
    },
    {
        className: 'border-x-2',
        template: 'ABBONAMENTI',
        isSortable: false,
        sortStatus: null
    },
    { className: 'border-x-2', template: 'FISSO', isSortable: false, sortStatus: null },
    {
        className: 'border-x-2',
        template: 'RENDICONTAZIONE',
        isSortable: false,
        sortStatus: null
    },
    {
        className: 'border-x-2',
        template: 'RICAVI',
        isSortable: false,
        sortStatus: null
    },
    {
        className: 'border-x-2',
        template: 'PREVISTO',
        isSortable: false,
        sortStatus: null
    },
    { className: 'border-x-2', template: '% REALIZZO', isSortable: false, sortStatus: null }
];

/**
 * Forms
 */
export const INIT_CLIENT_FILTER = {
    type: '',
    legalAddressCity: '',
    legalAddressRegionId: '',
    legalAddressProvinceId: '',
    subscription: '',
    startUpFromYear: '',
    startUpToYear: '',
    turnoverFromYear: '',
    turnoverToYear: '',
    companySize: '',
    femaleMajority: '',
    youthMajority: '',
    registered: '',
    sectorId: '',
    atecoCodes: [],
    isInclude: ''
};

export const INIT_TENDER_FILTER = {
    type: '',
    femaleMajority: '',
    youthMajority: '',
    minimumYearsOfActivity: '',
    minimumRequired: '',
    loanPercentage: '',
    legalAddressRegionId: '',
    legalAddressProvinceId: '',
    excludeAtecoCodes: '',
    maximumRequested: '',
    includedAtecoCodes: [],
    excludedAtecoCodes: [],
    revenueId: '',
    registerId: '',
    sectorId: '',
    deadlineStartDate: '',
    deadlineEndDate: '',
    classification: [],
    ceiling: ''
};

export const INIT_CLIENT = {
    name: '',
    firstContactWithAbifin: '',
    abifinReference: '',
    signalman: '',
    companyFiscalCode: '',
    vatNumber: '',
    email: '',
    phoneNumber: '',
    legalEmail: '',
    uniqueCode: '',
    subscription: '0',
    subscriptionCost: '',
    legalRepresentative: '',
    legalRepresentativeTaxCode: '',
    referanceName: '',
    referanceEmail: '',
    referancePhoneNumber: '',
    invoiceAmount: '',
    bank: '',
    bankAccount: '',
    reaCode: '',
    inpsCode: '',
    companySize: '',
    registerId: '',
    registrationRegisters: '',
    registrationRegistersDate: '',
    registrationRegistersNumber: '',
    sectorId: '',
    federationAffiliations: '',
    femaleMajority: '',
    youthMajority: '',
    yearOfEstablishment: '',
    yearOfStartBusiness: '',
    lastYearTurnover: '',
    atecoCodes: [],
    legalAddressCity: '',
    legalAddressRegionId: '',
    legalAddressProvinceId: '',
    legalAddressHouseNumber: '',
    legalPostalCode: '',
    // operativeAddressCity: '',
    // operativeAddressRegionId: '',
    // operativeAddressProvinceId: '',
    // operativeAddressHouseNumber: '',
    // operativePostalCode: '',
    operative: [],
    isOperativeSameAsLegal: '',
    driveLink: '',
    firstSubscription: ''
};

export const INIT_TENDER = {
    type: '',
    name: '',
    tenderFor: '',
    deadline: '',
    ceiling: '',
    textualCeiling: '',
    atecoCodes: [],
    excludeAtecoCodes: '',
    revenueId: '',
    registerId: '',
    sectorId: '',
    maximumRequested: '',
    regionId: '',
    postalCode: '',
    locationForMatching: '',
    provinceId: '',
    classification: '',
    loanPercentage: '',
    entity: '',
    tenderUrl: '',
    femaleMajority: '',
    youthMajority: '',
    minimumYearsOfActivity: '',
    introduction: '',
    isAnalyzed: '0',
    additionalNotes: '',
    driveLink: ''
};

export const MONTH = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
];

export const CLASSIFICATIONS = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' },
    { value: 'G', label: 'G' },
    { value: 'H', label: 'H' },
    { value: 'I', label: 'I' },
    { value: 'L', label: 'L' }
];
