/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React from 'react';

function SecondaryButton({
    children,
    title,
    onClick,
    type = 'button',
    className,
    disable = false
}) {
    return (
        <button
            type={type}
            title={title}
            onClick={onClick}
            className={`bg-greyLine hover:bg-grey-800 focus:outline-none focus:ring-4 focus:ring-grey-700 font-medium rounded-full text-sm px-5 py-2.5 text-center ${className}`}
            disabled={disable}>
            {children}
        </button>
    );
}

export default SecondaryButton;
