/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { EMAIL_REGEX, MESSAGES, PHONE_REGEX } from '../../constants';
import AtecoDropdown from '../dropdown/AtecoDropdown';
import ProvinveDropdown from '../dropdown/ProvinveDropdown';
import RegionDropDown from '../dropdown/RegionDropDown';
import SectorsDropdown from '../dropdown/SectorsDropdown';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

function ClientFilters({
    isOpen = false,
    applyFilter = null,
    closeModel = null,
    selectedClient = null,
    filters
}) {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors }
    } = useForm();

    const companyType = watch('type');

    // const [legaleDefaultQuery, setLegaleDefaultQuery] = useState('');
    // const [operativeDefaultQuery, setOperativeDefaultQuery] = useState('');

    const [loading, setLoading] = useState(false);
    const [yearOptions, setYearOptions] = useState([]);

    // const [isOperativeSameAsLegal, setisOperativeSameAsLegal] = useState('1');

    const onSubmitHandler = async (data) => {
        // console.log(data);
        // let atecoCode = [];

        // if (data.atecoCodes.length) {
        //     atecoCode = data.atecoCodes.map((code) => code.value);
        // }
        // data.atecoCodes = atecoCode;

        applyFilter({ ...data });
    };

    const setClientFilters = (clientFilters) => {
        console.log(clientFilters);
        reset({ ...clientFilters });
    };

    const initYears = async () => {
        const years = [];
        const min = 1900;
        for (let year = min; year <= new Date().getFullYear(); year++) {
            years.push({ id: year, name: year });
        }

        setYearOptions([...years.reverse()]);
    };

    useEffect(() => {
        console.log(filters);
        setClientFilters(filters);
    }, [filters]);

    useEffect(() => {
        initYears();
    }, []);

    return (
        <ModalComponent isOpen={isOpen} size="max-w-6xl">
            {/* Modal header  */}
            <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                <h3 className="text-xl font-semibold text-white">Filtra risultati</h3>
                <button
                    type="button"
                    className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => closeModel()}>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>

            {/* { !selectedClient  } */}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6">
                    <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                        <div className="my-4">
                            <p className="font-bold w-full">Cliente</p>
                            <div className="flex w-full flex-wrap space-x-4">
                                <div className="flex items-center space-x-2">
                                    <input
                                        id="inline-radio"
                                        type="radio"
                                        value="Company"
                                        name="tender-type"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        {...register('type')}
                                    />
                                    <label htmlFor="tender-type">Aziende</label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <input
                                        id="inline-radio"
                                        type="radio"
                                        value="Association"
                                        name="tender-type"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        {...register('type')}
                                    />
                                    <label htmlFor="tender-type">Associazioni</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                        <RegionDropDown
                            register={register}
                            name="legalAddressRegionId"
                            errors={errors}
                            config={{ required: false }}
                            className="md:w-1/2"
                        />

                        <ProvinveDropdown
                            register={register}
                            name="legalAddressProvinceId"
                            errors={errors}
                            config={{ required: false }}
                            className="md:w-1/2"
                        />
                    </div>

                    <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                        <div
                            className={`w-full input-field-group ${
                                errors?.legalAddressCity ? 'invalid-field' : ''
                            }`}>
                            <label>Città</label>
                            <input
                                type="text"
                                placeholder="Città"
                                {...register('legalAddressCity', {
                                    maxLength: 255
                                })}
                            />
                            {errors?.legalAddressCity?.type === 'maxLength' && (
                                <p>{MESSAGES.maxLimitMessage()}</p>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                        <div className="w-full md:w-1/3">
                            <p className="font-bold w-full">Abbonato?</p>
                            <div className="flex w-full flex-wrap space-x-4">
                                <div className="flex items-center space-x-2">
                                    <input
                                        id="inline-radio"
                                        type="radio"
                                        value="1"
                                        name="subscription"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        {...register('subscription')}
                                    />
                                    <label htmlFor="subscription2">Si</label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <input
                                        id="inline-radio"
                                        type="radio"
                                        value="0"
                                        name="subscription"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        {...register('subscription')}
                                    />
                                    <label htmlFor="subscription">No</label>
                                </div>
                            </div>
                        </div>

                        <div className="w-full input-field-group md:w-1/3">
                            <label htmlFor="companySize">Anno avvio attività</label>
                            <div className="w-full flex">
                                <div className="w-1/2 relative">
                                    <span className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        Da:
                                    </span>
                                    <select
                                        id="startUpFromYear"
                                        name="startUpFromYear"
                                        className="!pl-10"
                                        {...register('startUpFromYear')}>
                                        <option value="">Seleziona...</option>
                                        {yearOptions.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="w-1/2 relative">
                                    <span className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        A:
                                    </span>
                                    <select
                                        id="startUpToYear"
                                        name="startUpToYear"
                                        className="!pl-10"
                                        {...register('startUpToYear')}>
                                        <option value="">Seleziona...</option>
                                        {yearOptions.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="w-full input-field-group md:w-1/3">
                            <label htmlFor="companySize">Anno ultimo fatturaro</label>
                            <div className="w-full flex">
                                <div className="w-1/2 relative">
                                    <span className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        Da:
                                    </span>
                                    <select
                                        id="turnoverFromYear"
                                        name="turnoverFromYear"
                                        className="!pl-10"
                                        {...register('turnoverFromYear')}>
                                        <option value="">Seleziona...</option>
                                        {yearOptions.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="w-1/2 relative">
                                    <span className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        A:
                                    </span>
                                    <select
                                        id="turnoverToYear"
                                        name="turnoverToYear"
                                        className="!pl-10"
                                        {...register('turnoverToYear')}>
                                        <option value="">Seleziona...</option>
                                        {yearOptions.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                        <div className="w-full input-field-group md:w-1/3">
                            <label htmlFor="companySize">Grandezza impresa</label>
                            <select
                                id="companySize"
                                name="companySize"
                                {...register('companySize')}>
                                <option value="">Non dichiarato</option>
                                <option value="Micro">Micro Impresa</option>
                                <option value="Piccola">Piccola Impresa</option>
                                <option value="Media">Media Impresa</option>
                                <option value="Grande">Grande Impresa</option>
                            </select>
                            <p className="hidden">Test</p>
                        </div>

                        <div className="w-full input-field-group md:w-1/3">
                            <label htmlFor="femaleMajority">Maggioranza femminile</label>
                            <select
                                id="femaleMajority"
                                name="femaleMajority"
                                {...register('femaleMajority')}>
                                <option value="">Non dichiarato</option>
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </select>
                        </div>

                        <div className="w-full input-field-group md:w-1/3">
                            <label htmlFor="youthMajority">Maggioranza giovanile</label>
                            <select
                                id="youthMajority"
                                name="youthMajority"
                                {...register('youthMajority')}>
                                <option value="">Non dichiarato</option>
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </select>
                        </div>
                    </div>

                    {companyType === 'Company' ? (
                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <AtecoDropdown
                                register={register}
                                label="Codice Ateco (se più di uno separare con virgola)"
                                name="atecoCodes"
                                errors={errors}
                                className="md:w-8/12"
                                defaultValue={[...filters.atecoCodes]}
                                setValue={setValue}
                            />
                            <div className="w-full md:w-1/3">
                                <p className="font-bold w-full">Escludi codici ATECO?</p>
                                <div className="flex w-full flex-wrap space-x-4">
                                    <div className="flex items-center space-x-2">
                                        <input
                                            id="inline-radio"
                                            type="radio"
                                            value="1"
                                            name="isInclude"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            {...register('isInclude')}
                                        />
                                        <label htmlFor="subscription2">Si escludi</label>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <input
                                            id="inline-radio"
                                            type="radio"
                                            value="0"
                                            name="isInclude"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            {...register('isInclude')}
                                        />
                                        <label htmlFor="subscription">No includi</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {companyType === 'Association' ? (
                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div className="w-full md:w-1/3">
                                <p className="font-bold w-full">Iscritto ai registri?</p>
                                <div className="flex w-full flex-wrap space-x-4">
                                    <div className="flex items-center space-x-2">
                                        <input
                                            id="inline-radio"
                                            type="radio"
                                            value="1"
                                            name="registered"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            {...register('registered')}
                                        />
                                        <label htmlFor="registered">Si</label>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <input
                                            id="inline-radio"
                                            type="radio"
                                            value="0"
                                            name="registered"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            {...register('registered')}
                                        />
                                        <label htmlFor="registered">No</label>
                                    </div>
                                </div>
                            </div>
                            <SectorsDropdown
                                register={register}
                                name="sectorId"
                                errors={errors}
                                config={{ required: false }}
                                className="md:w-8/12"
                            />
                        </div>
                    ) : null}
                </div>

                {/* Modal Footer */}
                <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                    <PrimaryButton type="submit">FILTRA</PrimaryButton>
                    <SecondaryButton onClick={() => closeModel(true)}>
                        RIMUOVI FILTRI
                    </SecondaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default ClientFilters;
