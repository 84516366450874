/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';

function DataTable({
    columns = [],
    rows = [],
    isSelectable = false,
    className = '',
    headerClassName = '',
    rowClassName = '',
    paginateData = null,
    onSelectChangeHandler,
    sortBtnClick = null,
    handlePagination
}) {
    return (
        <div className="relative overflow-x-auto">
            <table
                className={`w-full text-left text-sm font-bold mb-44 text-mainBlack${className}`}>
                <thead className="border-b-greyLine border-b">
                    <tr className={headerClassName}>
                        {isSelectable ? (
                            <th className="border-greyLine border py-3 px-6">#</th>
                        ) : null}
                        {columns.map((column, index) => (
                            <th
                                key={`h-${index}`}
                                className={`border-greyLine border py-3 px-6 ${column?.className}`}>
                                <div className="flex items-center">
                                    {column?.template}
                                    {column?.isSortable ? (
                                        <div
                                            className="inline-flex flex-col space-y-1 ml-2 cursor-pointer"
                                            onClick={() => sortBtnClick(index)}
                                            aria-hidden="true">
                                            <svg
                                                className={`${
                                                    column.sortStatus === 'asc'
                                                        ? 'text-mainBlack'
                                                        : 'text-grey-800'
                                                }`}
                                                width="10"
                                                height="5"
                                                viewBox="0 0 10 5"
                                                fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    fill="currentColor"
                                                    d="M4.767.276L8.395 4.04c.142.147.138.382-.01.524-.069.066-.16.104-.257.104H.872c-.205 0-.37-.166-.37-.37 0-.097.036-.189.103-.258L4.233.276c.142-.147.377-.151.524-.009l.01.01z"
                                                />
                                            </svg>
                                            <svg
                                                className={`${
                                                    column.sortStatus === 'desc'
                                                        ? 'text-mainBlack'
                                                        : 'text-grey-800'
                                                }`}
                                                width="10"
                                                height="5"
                                                viewBox="0 0 10 5"
                                                fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    fill="currentColor"
                                                    d="M4.233 4.724L.605.96C.463.814.467.579.615.437.684.371.775.333.872.333h7.256c.205 0 .37.166.37.37 0 .097-.036.189-.103.258L4.767 4.724c-.142.147-.377.151-.524.009l-.01-.01z"
                                                />
                                            </svg>
                                        </div>
                                    ) : null}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody className="text-sm">
                    {rows.map((row, index) => (
                        <tr key={`r-${index}`} className={`${row.className} ${rowClassName}`}>
                            {isSelectable ? (
                                <th scope="col" className="border-greyLine border p-4">
                                    <div className="flex items-center">
                                        <input
                                            id="checkbox-all"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
                                            checked={row?.selected}
                                            onChange={(event) =>
                                                onSelectChangeHandler(index, event.target.checked)
                                            }
                                        />
                                        <label htmlFor="checkbox-all" className="sr-only">
                                            checkbox
                                        </label>
                                    </div>
                                </th>
                            ) : null}
                            {row.cells?.map((cell, cellIndex) => (
                                <td
                                    key={`c-${cellIndex}`}
                                    className={`border-greyLine border px-6 py-1 ${cell?.className}`}>
                                    {cell?.template}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            {paginateData ? (
                <div className="flex flex-col items-left mt-4 mb-4">
                    <span className="text-sm text-gray-700">
                        Mostra da{' '}
                        <span className="font-semibold text-gray-900">{paginateData?.from}</span> a{' '}
                        <span className="font-semibold text-gray-900">{paginateData?.to}</span> di{' '}
                        <span className="font-semibold text-gray-900">{paginateData?.total}</span>{' '}
                        Inserimenti
                    </span>
                    <div className="inline-flex mt-2 xs:mt-0">
                        <button
                            type="button"
                            onClick={() => handlePagination(paginateData.previousPage)}
                            disabled={!paginateData.previousPage}
                            className="inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                            <svg
                                aria-hidden="true"
                                className="mr-2 w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            Prev
                        </button>
                        <button
                            type="button"
                            onClick={() => handlePagination(paginateData.nextPage)}
                            disabled={!paginateData.nextPage}
                            className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                            Next
                            <svg
                                aria-hidden="true"
                                className="ml-2 w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default DataTable;
