/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../PrimaryButton';
import ModalComponent from './ModalComponent';
import {
    generateAndDownloadCSV,
    generateAndDownloadPdf,
    generateAndDownloadXlsx
} from '../../helper/utility';

function ExportModal({
    isOpen = false,
    closeModel = null,
    fields = [],
    data = null,
    fileName = 'download-file'
}) {
    const [headers, setHeaders] = useState([...fields]);
    const [isSelected, setIsSelected] = useState(false);
    /**
     *
     * @param {*} result
     * @param {*} index
     */
    const selectHeaderChangeHandler = (columns, isChecked, index) => {
        const newHeader = [...columns];
        const header = newHeader[index];
        header.isSelected = isChecked;
        newHeader[index] = { ...header };
        setHeaders([...newHeader]);
    };

    const exportPdf = (columns, jsonData) => {
        const selectedColumns = columns.filter((item) => item.isSelected);
        generateAndDownloadPdf(selectedColumns, jsonData, fileName);
    };

    const exportXlxs = (columns, jsonData) => {
        const selectedColumns = columns
            .filter((item) => item.isSelected)
            .map((item) => ({ label: item.header, value: item.field }));
        generateAndDownloadXlsx(selectedColumns, jsonData, fileName);
    };

    const exportCsv = (columns, jsonData) => {
        const selectedColumns = columns.filter((item) => item.isSelected);
        generateAndDownloadCSV(selectedColumns, jsonData, fileName);
    };

    useEffect(() => {
        if (headers.findIndex((item) => item.isSelected) > -1) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }, [headers]);

    return (
        <ModalComponent isOpen={isOpen} size="max-w-6xl">
            {/* {isLoading ? <Loader /> : null} */}
            {/* Modal header  */}
            <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                <h3 className="text-xl font-semibold text-white">
                    Seleziona informazioni da esportare
                </h3>
                <button
                    type="button"
                    className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => closeModel()}>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>

            {/* Modal Body */}
            <div className="flex flex-wrap p-6 space-y-4">
                {headers.map((field, index) => {
                    return (
                        <div
                            className="flex items-center font-bold text-sm space-x-2 w-1/4"
                            key={field.header}>
                            <input
                                id="checked-checkbox"
                                type="checkbox"
                                value=""
                                className="w-4 h-4"
                                checked={field.isSelected}
                                onChange={(event) =>
                                    selectHeaderChangeHandler(headers, event.target.checked, index)
                                }
                            />
                            <span>{field.header}</span>
                        </div>
                    );
                })}
            </div>

            {/* Modal Footer */}
            <div className="flex flex-row-reverse items-center p-6 space-x-2 space-x-reverse rounded-b bg-grey-700">
                <PrimaryButton onClick={() => exportPdf(headers, data)} disable={!isSelected}>
                    Esporta PDF
                </PrimaryButton>
                <PrimaryButton onClick={() => exportXlxs(headers, data)} disable={!isSelected}>
                    Esporta XLSX
                </PrimaryButton>
                <PrimaryButton onClick={() => exportCsv(headers, data)} disable={!isSelected}>
                    Esporta CSV
                </PrimaryButton>
            </div>
        </ModalComponent>
    );
}

export default ExportModal;
