/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React from 'react';

function PrimaryButton({ children, title, onClick, type = 'button', className, disable = false }) {
    return (
        <button
            type={type}
            title={title}
            onClick={onClick}
            className={`text-white bg-primary-800 hover:bg-primary-900 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-xs px-5 py-2.5 text-center ${className} md:text-sm`}
            disabled={disable}>
            {children}
        </button>
    );
}

export default PrimaryButton;
