/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MESSAGES } from '../../constants';
import AtecoDropdown from '../dropdown/AtecoDropdown';
import ProvinveDropdown from '../dropdown/ProvinveDropdown';
import RegionDropDown from '../dropdown/RegionDropDown';
import RegisteriesDropdown from '../dropdown/RegisteriesDropdown';
import RevenueDropDown from '../dropdown/RevenueDropDown';
import SectorsDropdown from '../dropdown/SectorsDropdown';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';
import ClassificationDropdown from '../dropdown/ClassificationDropdown';

function TenderFilters({
    isOpen = false,
    applyFilter = null,
    closeModel = null,
    selectedTender = null,
    filters
}) {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors }
    } = useForm();

    const tenderType = watch('type');

    // const [legaleDefaultQuery, setLegaleDefaultQuery] = useState('');
    // const [operativeDefaultQuery, setOperativeDefaultQuery] = useState('');

    // const [loading, setLoading] = useState(false);
    const [yearOptions, setYearOptions] = useState([]);

    // const [isOperativeSameAsLegal, setisOperativeSameAsLegal] = useState('1');

    const onSubmitHandler = async (data) => {
        console.log(data);
        applyFilter({ ...data });
    };

    const setClientFilters = (clientFilters) => {
        console.log(clientFilters);
        reset({ ...clientFilters });
    };

    const initYears = async () => {
        const years = [];
        const min = 1900;
        for (let year = min; year <= new Date().getFullYear(); year++) {
            years.push({ id: year, name: year });
        }

        setYearOptions([...years.reverse()]);
    };

    useEffect(() => {
        setClientFilters(filters);
    }, [isOpen, filters]);

    useEffect(() => {
        initYears();
    }, []);

    return (
        <ModalComponent isOpen={isOpen} size="max-w-6xl">
            {/* Modal header  */}
            <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                <h3 className="text-xl font-semibold text-white">Filtra risultati</h3>
                <button
                    type="button"
                    className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => closeModel()}>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>

            {/* { !selectedClient  } */}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                        <div className="my-4">
                            <p className="font-bold w-full">Bando per*</p>
                            <div className="flex w-full flex-wrap space-x-4">
                                <div className="flex items-center space-x-2">
                                    <input
                                        id="inline-radio"
                                        type="radio"
                                        value="Company"
                                        name="tender-type"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        {...register('type', { required: true })}
                                    />
                                    <label htmlFor="tender-type">Aziende</label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <input
                                        id="inline-radio"
                                        type="radio"
                                        value="Association"
                                        name="tender-type"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        {...register('type', { required: true })}
                                    />
                                    <label htmlFor="tender-type">Associazioni</label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <input
                                        id="inline-radio"
                                        type="radio"
                                        value="All"
                                        name="tender-type"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        {...register('type', { required: true })}
                                    />
                                    <label htmlFor="tender-type">Aziende e associazioni</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                        <div className="w-full input-field-group md:w-8/12">
                            <label htmlFor="companySize">Scadenza</label>
                            <div className="w-full flex">
                                <div className="w-1/2 relative">
                                    <span className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        Da:
                                    </span>
                                    <input
                                        type="date"
                                        {...register('deadlineStartDate')}
                                        className="!pl-10"
                                    />
                                </div>

                                <div className="w-1/2 relative">
                                    <span className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        A:
                                    </span>
                                    <input
                                        type="date"
                                        {...register('deadlineEndDate')}
                                        className="!pl-10"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            className={`w-full input-field-group md:w-1/3 ${
                                errors?.ceiling ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="ceiling">Plafond</label>
                            <input
                                type="number"
                                placeholder="Plafond"
                                step=".01"
                                id="ceiling"
                                name="ceiling"
                                {...register('ceiling', { maxLength: 21 })}
                            />
                            {errors?.ceiling?.type === 'maxLength' && (
                                <p className="text-red-700">{MESSAGES.maxLimitMessage(21)}</p>
                            )}
                        </div>
                    </div>

                    {/* section for company */}
                    {(tenderType === 'Company' || tenderType === 'All') && (
                        <div style={{ backgroundColor: '#3062AC13' }} className="p-4">
                            <p className="font-bold text-primary-800 w-full">PER AZIENDE</p>
                            <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                                {/* // TODO : Atecocode */}
                                {/* <AtecoDropdown
                                    register={register}
                                    label="Codice Ateco (se più di uno separare con virgola)"
                                    name="atecoCodes"
                                    errors={errors}
                                    className="md:w-2/3"
                                    defaultValue={[]}
                                    setValue={setValue}
                                /> */}
                                <AtecoDropdown
                                    register={register}
                                    label="Includi codici Ateco"
                                    name="includedAtecoCodes"
                                    errors={errors}
                                    className="md:w-[49%]"
                                    defaultValue={[...filters.includedAtecoCodes]}
                                    setValue={setValue}
                                />

                                <AtecoDropdown
                                    register={register}
                                    label="Escludi codici Ateco"
                                    name="excludedAtecoCodes"
                                    errors={errors}
                                    className="md:w-[49%]"
                                    defaultValue={[...filters.excludedAtecoCodes]}
                                    setValue={setValue}
                                />

                                {/* <div className="my-4">
                                    <p className="font-bold w-full">Escludi codici ATECO?</p>
                                    <div className="flex w-full flex-wrap space-x-4">
                                        <div className="flex items-center space-x-2">
                                            <input
                                                id="excludeAtecoCodes"
                                                type="radio"
                                                value="1"
                                                name="excludeAtecoCodes"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                {...register('excludeAtecoCodes')}
                                            />
                                            <label htmlFor="yes">Si escludi</label>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                id="excludeAtecoCodes"
                                                type="radio"
                                                value="0"
                                                name="excludeAtecoCodes"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                {...register('excludeAtecoCodes')}
                                            />
                                            <label htmlFor="no">No includi</label>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                                <RevenueDropDown
                                    register={register}
                                    name="revenueId"
                                    errors={errors}
                                    setValue={setValue}
                                />
                            </div>
                        </div>
                    )}

                    {/* Section for assoication */}
                    {(tenderType === 'Association' || tenderType === 'All') && (
                        <div style={{ backgroundColor: '#3062AC13' }} className="p-4">
                            <p className="font-bold text-primary-800 w-full">PER ASSOCIAZIONI</p>

                            <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                                <RegisteriesDropdown
                                    register={register}
                                    name="registerId"
                                    errors={errors}
                                    className="md:w-1/2"
                                />
                                <SectorsDropdown
                                    register={register}
                                    name="sectorId"
                                    errors={errors}
                                    config={{ required: false }}
                                    className="md:w-1/2"
                                />
                            </div>

                            <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                                <div
                                    className={`w-full input-field-group ${
                                        errors?.maximumRequested ? 'invalid-field' : ''
                                    }`}>
                                    <label htmlFor="maximumRequested">Massimo richiedibile</label>
                                    <input
                                        type="text"
                                        id="maximumRequested"
                                        name="maximumRequested"
                                        {...register('maximumRequested')}
                                    />
                                    {errors?.maximumRequested?.type === 'maxLength' && (
                                        <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                        <RegionDropDown
                            register={register}
                            name="legalAddressRegionId"
                            errors={errors}
                            config={{ required: false }}
                            className="md:w-1/2"
                        />

                        <ProvinveDropdown
                            register={register}
                            name="legalAddressProvinceId"
                            errors={errors}
                            config={{ required: false }}
                            className="md:w-1/2"
                        />
                    </div>

                    <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                        <div className="w-full input-field-group md:w-1/3">
                            <label htmlFor="femaleMajority">Maggioranza femminile</label>
                            <select
                                id="femaleMajority"
                                name="femaleMajority"
                                {...register('femaleMajority')}>
                                <option value="">Non dichiarato</option>
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </select>
                        </div>

                        <div className="w-full input-field-group md:w-1/3">
                            <label htmlFor="youthMajority">Maggioranza giovanile</label>
                            <select
                                id="youthMajority"
                                name="youthMajority"
                                {...register('youthMajority')}>
                                <option value="">Non dichiarato</option>
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </select>
                        </div>

                        <div className="w-full input-field-group md:w-1/3">
                            <label htmlFor="yearOfActivity">Minimo anni di attività</label>
                            <input
                                type="number"
                                id="yearOfActivity"
                                name="yearOfActivity"
                                step=".01"
                                {...register('minimumYearsOfActivity', {
                                    maxLength: 11
                                })}
                            />
                            {errors?.additionalNotes?.type === 'maxLength' && (
                                <p className="text-red-700">{MESSAGES.maxLimitMessage(11)}</p>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                        <div
                            className={`w-full input-field-group md:w-1/3 ${
                                errors?.loanPercentage ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="loanPercentage">Minimo richiedibile</label>
                            <input
                                type="number"
                                step=".01"
                                id="loanPercentage"
                                name="loanPercentage"
                                placeholder="Minimo richiedibile"
                                {...register('minimumRequired', { maxLength: 13 })}
                            />
                        </div>
                        <ClassificationDropdown
                            register={register}
                            name="classification"
                            errors={errors}
                            className="md:w-1/3"
                            setValue={setValue}
                            defaultValue={[...filters.classification]}
                            multiple
                        />
                        <div
                            className={`w-full input-field-group md:w-1/3 ${
                                errors?.loanPercentage ? 'invalid-field' : ''
                            }`}>
                            <label htmlFor="loanPercentage">Percentuale finanziamento</label>
                            <input
                                type="number"
                                step=".01"
                                id="loanPercentage"
                                name="loanPercentage"
                                placeholder="Percentuale finanziamento %"
                                {...register('loanPercentage', { maxLength: 13 })}
                            />
                            {errors?.loanPercentage?.type === 'maxLength' && (
                                <p className="text-red-700">{MESSAGES.maxLimitMessage(13)}</p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                    <PrimaryButton type="submit">FILTRA</PrimaryButton>
                    <SecondaryButton onClick={() => closeModel(true)}>
                        RIMUOVI FILTRI
                    </SecondaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default TenderFilters;
