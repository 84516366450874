/* eslint-disable react/prop-types */
import React from 'react';

function TableHeader({
    children,
    isSearch,
    searchString = '',
    onChangeSearchString,
    onPressEnter,
    onClearSearchClick
}) {
    return (
        <div className="flex flex-col justify-between space-y-2 md:flex-row md:items-center py-4">
            <div>{children}</div>
            <div>
                {isSearch ? (
                    <div className="input-field-group relative w-full md:w-80">
                        <input
                            type="text"
                            id="search"
                            placeholder="Search"
                            onChange={onChangeSearchString}
                            value={searchString}
                            className="!rounded-full"
                            onKeyPress={onPressEnter}
                        />
                        <div className="flex absolute inset-y-0 right-4 items-center pl-3 ">
                            {searchString.length > 0 ? (
                                <span onClick={() => onClearSearchClick()} aria-hidden="true">
                                    <svg
                                        className="w-5 h-5 text-gray-500 cursor-pointer"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </span>
                            ) : (
                                <span>
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-5 text-gray-500 dark:text-gray-400 cursor-pointer"
                                        fill="none"
                                        width="10"
                                        height="10"
                                        stroke="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                        />
                                    </svg>
                                </span>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default TableHeader;
