/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import PageHeader from '../../component/PageHeader';
import RoundButton from '../../component/RoundButton';
import AssociateClient from '../../component/modal/AssociateClient';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import CreateTender from '../../component/modal/CreateTender';
import FileUpload from '../../component/modal/FileUpload';
import ExportModal from '../../component/modal/exportModal';
import {
    FILES_TABLE_COLUMNS,
    MESSAGES,
    TENDER_CLIENT_HEADERS,
    TENDER_FIELDS,
    TENDER_STATUS
} from '../../constants';
import { deleteFile, downloadFile } from '../../helper/services/apis/client-services';
import { deleteTenderAssociation } from '../../helper/services/apis/common-services';
import {
    archiveTender,
    deleteTender,
    getTenderById
} from '../../helper/services/apis/tender-services';
import { reformatDate, sortArray } from '../../helper/utility';

function Tender() {
    const params = useParams();
    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [currentStateIndex, setCurrentStateIndex] = useState(0);
    const [tender, setTender] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [selectedClient, setSelectedClient] = useState(null);

    const [clientsData, setClientsData] = useState([]);

    const [clientsTableHeaders, setClientsTableHeaders] = useState([...TENDER_CLIENT_HEADERS]);

    const [isExportOpen, setIsExportOpen] = useState(false);
    const [exportData, setExportData] = useState(null);

    const [isAssociateClientOpen, setAssociateClientOpen] = useState(false);
    const [isTenderCreateOpen, setIsTenderCreateOpen] = useState(false);
    const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);
    const [isArchiveConfirmOpen, setIsArchiveConfirmOpen] = useState(false);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [isUpdateTenderOpen, setIsUpdateTenderOpen] = useState(false);
    const [isConfirmAssociationDelete, setIsConfirmAssociationDelete] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [isDeleteAttachmentConfirmOpen, setIsDeleteAttachmentConfirmOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    // const [user, setUser] = useState(JSON.parse(Storage.get('profile')));

    const getHeader = () => {
        return (
            <ol className="inline-flex items-center space-x-1">
                <li className="inline-flex items-center">
                    <Link
                        to="../tenders"
                        className="inline-flex items-center text-primary-800 cursor-pointer">
                        Bandi
                    </Link>
                </li>
                <li>
                    <div className="flex items-center">
                        <svg
                            className="w-8 h-8 text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="ml-1">{tender?.name}</span>
                    </div>
                </li>
            </ol>
        );
    };

    const stateLabelOnClickHandler = (index) => {
        setCurrentStateIndex(index);
    };

    const initTender = async (id) => {
        setIsLoading(true);
        const result = await getTenderById(id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        setIsLoading(false);

        if (result.ok) {
            setTender({ ...result.data });
        }
    };

    const onClickDownloadImageHandler = async (doc) => {
        // Download Image
        const result = await downloadFile(doc.path).catch((e) => toast.error(e.message));
        const downloadLink = document.createElement('a');
        downloadLink.target = 'blank';
        downloadLink.download = `${doc.name}`;
        downloadLink.href = result.data;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const deleteDocFromStorage = async (doc) => {
        const result = await deleteFile({ filePath: doc.path, type: 'tender', id: doc.id }).catch(
            (e) => toast.error(e.message)
        );
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            initTender(params.id);
        }
        setIsDeleteAttachmentConfirmOpen(false);
    };

    const onClickDeleteImageHandler = (doc) => {
        setSelectedAttachment(doc);
        setIsDeleteAttachmentConfirmOpen(true);
    };

    const onCancelDeleteImageHandler = () => {
        setSelectedAttachment(null);
        setIsDeleteAttachmentConfirmOpen(false);
    };

    const onAssociateModalOpen = (client) => {
        setSelectedClient(client);
        setIsUpdateTenderOpen(true);
    };

    const onAssociateModalClose = (reload = false) => {
        setSelectedClient(null);
        setIsUpdateTenderOpen(false);
        setAssociateClientOpen(false);
        if (reload) {
            initTender(params.id);
        }
    };

    const onAssociateRemoveConfirmModalOpen = (client) => {
        setSelectedClient(client);
        setIsConfirmAssociationDelete(true);
    };

    const onAssociateRemoveConfirmModalClose = (reload = false) => {
        setSelectedClient(null);
        setIsConfirmAssociationDelete(false);
        if (reload) {
            initTender(params.id);
        }
    };

    const deleteAssociateTender = async (client) => {
        const result = await deleteTenderAssociation(client.pivot.id).catch((e) =>
            toast.error(e.message)
        );
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            setIsConfirmAssociationDelete(false);
            initTender(params.id);
        }
    };

    /**
     *
     * @param {*} index : of column
     */
    const onChangeSortBtnClick = (index) => {
        let tempData = [...clientsTableHeaders];
        const singleColumn = { ...tempData[index] };
        console.log(index);
        console.log(singleColumn);
        if (!singleColumn.sortStatus) {
            singleColumn.sortStatus = 'asc';
        } else if (singleColumn.sortStatus === 'asc') {
            singleColumn.sortStatus = 'desc';
        } else {
            singleColumn.sortStatus = null;
        }
        tempData = tempData.map((item) => ({ ...item, sortStatus: null }));
        tempData[index] = { ...singleColumn };
        setClientsTableHeaders([...tempData]);
    };

    const getABIFINRichiesto = (client) => {
        let value = 0;
        // if (client.pivot.requiredAmount && client.pivot.percentageAbifinRequest) {
        // }
        value = (client.pivot.requiredAmount * client.pivot.percentageAbifinRequest) / 100;
        return `${value.toFixed(2)}`;
    };

    const getImponibile = (client, cost) => {
        let value = 0;

        // if (
        //     client.pivot.requiredAmount &&
        //     client.pivot.percentageAbifinRequest &&
        //     cost &&
        //     client.pivot.payedCostForTender &&
        //     client.pivot.costEnteredByAbifin
        // ) {
        //     const per = (client.pivot.requiredAmount * client.pivot.percentageAbifinRequest) / 100;

        //     value =
        //         (client.pivot.requiredAmount * client.pivot.percentageAbifinRequest) / 100 +
        //         (+cost + +client.pivot.payedCostForTender + +client.pivot.costEnteredByAbifin);
        // }

        value =
            (client.pivot.obtainedAmount * client.pivot.percentageAbifinRequest) / 100 +
            (+cost + +client.pivot.payedCostForTender + +client.pivot.costEnteredByAbifin);
        return +value.toFixed(2);
    };

    const getGeneratoPrevisto = (client, cost) => {
        if (client.pivot.status === 'Waiting') {
            let value = 0;
            let exp = 0;

            // console.log('Cost : ', cost);
            if (cost) {
                value += +cost;
            }

            // console.log('client.pivot.costEnteredByAbifin : ', client.pivot.costEnteredByAbifin);
            if (client.pivot.costEnteredByAbifin) {
                value += +client.pivot.costEnteredByAbifin;
            }

            // console.log('client.pivot.payedCostForTender : ', client.pivot.payedCostForTender);
            if (client.pivot.payedCostForTender) {
                value += +client.pivot.payedCostForTender;
            }

            // console.log('client.pivot.percentageOfWiningTender : ',client.pivot.percentageOfWiningTender);
            exp = client.pivot.percentageOfWiningTender
                ? client.pivot.percentageOfWiningTender / 100
                : 0;
            // console.log('exp : ', exp);

            // if (client.pivot.percentageAbifinRequest) {
            // console.log('client.pivot.percentageAbifinRequest : ',client.pivot.percentageAbifinRequest);
            exp *= client.pivot.percentageAbifinRequest
                ? client.pivot.percentageAbifinRequest / 100
                : 0;
            // console.log('exp : ', exp);

            // console.log('client.pivot.requiredAmount', client.pivot.requiredAmount);
            exp *= client.pivot.requiredAmount ? client.pivot.requiredAmount : 0;
            // console.log('exp : ', exp);
            // console.log('===================');
            // console.log('value : ', value);

            value += exp;

            return +value.toFixed(2);
        }
        return getImponibile(client, cost);
    };

    const getConIva = (client, cost) => {
        let result = 0;
        const imponibile = getImponibile(client, cost);
        let perValue = 0;
        if (imponibile > 0) {
            perValue = (imponibile * 22) / 100;
            result = imponibile + perValue;
        }
        return +result.toFixed(2);
    };

    const getClientsRows = (data) => {
        return data.map((client) => ({
            className: '',
            cells: [
                // COl : 1
                { template: client.displayCreatedAt, className: 'min-w-[128px] px-2' },
                {
                    template: (
                        <Link to={`../client/${client.id}`} className="text-primary-800">
                            {client.name}
                        </Link>
                    ),
                    className: 'min-w-[128px]'
                },
                // { template: client.createdAt, className: 'min-w-[128px]' },
                // {
                //     template: data.subscriptionCost ? data.subscriptionCost : '',
                //     className: 'min-w-[128px]'
                // },
                // COl : 2
                {
                    template: (
                        <span
                            className="px-3 py-2 rounded-2xl text-white inline-block"
                            style={{ backgroundColor: client.statusColor }}>
                            {client.status}
                        </span>
                    ),
                    className: 'min-w-[128px]'
                },
                // COl : 3
                {
                    template: client.percentageOfWiningTender
                        ? client.percentageOfWiningTender
                        : '-',
                    className: 'min-w-[128px]'
                },
                // COl : 4
                {
                    template: client.subscriptionCost ? client.subscriptionCost.toFixed(2) : '-',
                    className: 'min-w-[128px]'
                },
                // COl : 5
                {
                    template: client.payedCostForTender
                        ? `€ ${client.payedCostForTender.toFixed(2)}`
                        : '-',
                    className: 'min-w-[128px]'
                },
                // COl : 6
                {
                    template: client.costEnteredByAbifin
                        ? `€ ${client.costEnteredByAbifin.toFixed(2)}`
                        : '-',
                    className: 'min-w-[128px]'
                },
                // COl : 7
                {
                    template: client.requiredAmount ? client.requiredAmount.toFixed(2) : '-',
                    className: 'min-w-[128px]'
                },
                // COl : 8
                {
                    template: client.obtainedAmount ? `€ ${client.obtainedAmount.toFixed(2)}` : '-',
                    className: 'min-w-[128px]'
                },
                // COl : 9
                {
                    template: client.percentageAbifinRequest ? client.percentageAbifinRequest : '-',
                    className: 'min-w-[128px]'
                },

                // COl : 10
                {
                    template: `€ ${client.imponibile}`,
                    className: 'min-w-[128px]'
                },
                // COl : 11
                {
                    template: `€ ${client.coniva}`,
                    className: 'min-w-[128px]'
                },
                // COl : 12
                {
                    template: `€ ${client.previsto}`,
                    className: 'min-w-[128px]'
                },
                // COl : 13
                {
                    template: `€ ${client.abifinRichiesto.toFixed(2)}`,
                    className: 'min-w-[128px]'
                },
                {
                    template: (
                        <div className="relative inline-block">
                            <RoundButton className="bg-grey-700 text-black peer text-mainBlack hover:bg-grey-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-grey-700 font-medium rounded-full text-sm p-2.5 text-center">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                    />
                                </svg>
                            </RoundButton>
                            <div className="absolute right-[0%] z-10 hidden w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => onAssociateModalOpen(client)}
                                    aria-hidden="true">
                                    Modifica
                                </span>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => onAssociateRemoveConfirmModalOpen(client)}
                                    // onClick={() => deleteAssociateTender(client)}
                                    aria-hidden="true">
                                    Elimina
                                </span>
                            </div>
                        </div>
                    )
                }
            ]
        }));
    };

    const getDocumentsRows = (data) => {
        return data.documents.map((doc) => ({
            className: '',
            cells: [
                { template: doc.name, className: ' text-primary-800' },
                { template: doc.createdAt },
                {
                    template: (
                        <div className="relative inline-block">
                            <RoundButton className="bg-grey-700 text-black peer text-mainBlack hover:bg-grey-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-grey-700 font-medium rounded-full text-sm p-2.5 text-center">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                    />
                                </svg>
                            </RoundButton>
                            <div className="absolute right-[0%] z-10 hidden w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => onClickDownloadImageHandler(doc)}
                                    aria-hidden="true">
                                    Scarica allegato
                                </span>
                                <span
                                    className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                    onClick={() => onClickDeleteImageHandler(doc)}
                                    aria-hidden="true">
                                    Elimina allegato
                                </span>
                            </div>
                        </div>
                    )
                }
            ]
        }));
    };

    // const getClientOperativeCity = (address) => {
    //     let city = '-';
    //     const operativeAdd = address.find((item) => item.type === 'operative');
    //     if (operativeAdd) {
    //         city = operativeAdd.city;
    //     }
    //     return city;
    // };

    // const getClientOperativeRegion = (address) => {
    //     let region = '-';
    //     const operativeAdd = address.find((item) => item.type === 'operative');
    //     if (operativeAdd) {
    //         region = `${operativeAdd.province.name}/${operativeAdd.region.name}`;
    //     }
    //     return region;
    // };

    // const createTenderCSVRow = (data) => {
    //     const cells = [];
    //     if (data.length) {
    //         let name = '';
    //         let city = '';
    //         let region = '';
    //         let type = '';
    //         data.forEach((client, index) => {
    //             if (index === 0) {
    //                 name = client.name;
    //                 city = getClientOperativeCity(client.addresses);
    //                 region = getClientOperativeRegion(client.addresses);
    //                 type = client.type === 'Company' ? 'Azienda' : 'Associazione';
    //             } else {
    //                 name = `${name} |&| ${client.name}`;
    //                 city = `${city} |&| ${getClientOperativeCity(client.addresses)}`;
    //                 region = `${region} |&| ${getClientOperativeRegion(client.addresses)}`;
    //                 type = `${type} |&| ${client.type === 'Company' ? 'Azienda' : 'Associazione'}`;
    //             }
    //         });
    //         cells.push(name);
    //         cells.push(city);
    //         cells.push(region);
    //         cells.push(type);
    //     } else {
    //         cells.push('-');
    //         cells.push('-');
    //         cells.push('-');
    //         cells.push('-');
    //     }
    //     console.log(cells);
    //     return cells;
    // };

    const closeExportModal = () => {
        setIsExportOpen(false);
    };

    const exportClients = (data) => {
        const filteredTenders = data.map((item) => {
            const regions =
                item?.regions?.length > 0
                    ? item.regions.map((region) => region.name).join(' & ')
                    : null;
            const provinces =
                item?.provinces?.length > 0
                    ? item.provinces.map((province) => province.name).join(' & ')
                    : null;
            const atecoCodes =
                item?.atecoCodes?.length > 0
                    ? item.atecoCodes.map((atecoCode) => atecoCode.name).join(' & ')
                    : null;

            const revenue = item?.revenue ? item?.revenue?.name : null;

            const type =
                item.type === 'Company'
                    ? 'Aziende'
                    : item.type === 'Association'
                    ? 'Associazioni'
                    : 'Aziende e associazioni';

            return {
                name: item.name,
                ceiling: item.ceiling,
                deadline: reformatDate(item.deadline),
                textualCeiling: item.textualCeiling,
                matchingClientsCount: item.matchingClientsCount,
                clientsCount: item.clientsCount,
                type,
                atecoCodes,
                revenueId: revenue,
                regions,
                postalCode: item.postalCode,
                provinces,
                classification: item.classification,
                loanPercentage: item.loanPercentage,
                entity: item.entity,
                tenderUrl: item.tenderUrl,
                femaleMajority: item.femaleMajority == '1' ? 'Si' : 'No',
                youthMajority: item.youthMajority == '1' ? 'Si' : 'No',
                minimumYearsOfActivity: item.minimumYearsOfActivity,
                additionalNotes: item.additionalNotes
            };
        });

        setExportData([...filteredTenders]);
        setIsExportOpen(true);

        // const headers = [
        //     'Data inserimento',
        //     'Titolo',
        //     'Plafond',
        //     'Scadenza',
        //     'Tipologia',
        //     'Matching clienti Ragione sociale',
        //     'Matching clienti Città sede operativa',
        //     'Matching clienti Provincia / Regione sede operatova',
        //     'Matching clienti Tipologia',
        //     'Associati clienti Ragione sociale',
        //     'Associati clienti Città sede operativa',
        //     'Associati clienti Provincia / Regione sede operatova',
        //     'Associati clienti Tipologia',
        //     '\r\n'
        // ];

        // const rows = filteredTenders.map((item) => {
        //     let row = [];

        //     row.push(item.createdAt);
        //     row.push(item.name);
        //     row.push(item.ceiling);
        //     row.push(item.deadline);
        //     row.push(
        //         item.type === 'Company'
        //             ? 'Aziende'
        //             : tender.type === 'Association'
        //             ? 'Associazioni'
        //             : 'Aziende e associazioni'
        //     );

        //     row = [...row, ...createTenderCSVRow(item.matchingClients)];
        //     row = [...row, ...createTenderCSVRow(item.clients)];

        //     row.push('\r\n');
        //     return row;
        // });

        // let csvData = headers.join(',');
        // rows.forEach((row) => {
        //     csvData += row.join(',');
        // });

        // generateCSV(csvData, 'Bandi');
    };

    // eslint-disable-next-line no-unused-vars
    const associateOnClickHandler = () => {
        setAssociateClientOpen(true);
    };

    // eslint-disable-next-line no-unused-vars
    const editOnClickHandler = () => {
        setIsTenderCreateOpen(true);
    };

    const onCloseModelHandler = () => {
        setIsTenderCreateOpen(false);
        initTender(params.id);
    };

    // eslint-disable-next-line no-unused-vars
    const attachOnClickHandler = () => {
        setIsFileUploadOpen(true);
    };

    const onCloseFileUpload = () => {
        setIsFileUploadOpen(false);
        initTender(params.id);
    };

    const exportOnClickHandler = (data) => {
        exportClients([{ ...data }]);
    };

    // eslint-disable-next-line no-unused-vars
    const archiveOnClickHandler = () => {
        setIsArchiveConfirmOpen(true);
    };
    // eslint-disable-next-line no-unused-vars
    const deleteOnClickHandler = () => {
        setIsDeleteConfirmOpen(true);
    };

    const closeConfirmClickHandler = (type) => {
        if (type === 'archive') {
            setIsArchiveConfirmOpen(false);
        } else {
            setIsDeleteConfirmOpen(false);
        }
    };

    const archiveTenders = async (ids) => {
        const result = await archiveTender({ tenderIds: ids }).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            closeConfirmClickHandler('archive');
            initTender(params.id);
        }
    };

    const ArchiveConfirmBtnClickHandler = async (data) => {
        archiveTenders([data.id]);
    };

    const DeleteConfirmBtnClickHandler = async (data) => {
        const result = await deleteTender(data.id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            closeConfirmClickHandler('delete');
            navigate('/dashboard/tenders');
        }
    };

    useEffect(() => {
        initTender(params.id);
        if (searchParams.get('tabIndex')) {
            setCurrentStateIndex(1);
        }
    }, []);

    useEffect(() => {
        if (tender && tender.clients.length > 0) {
            setClientsData(
                tender.clients.map((client) => {
                    const date = client.pivot.participationDate
                        ? reformatDate(client.pivot.participationDate)
                        : '-';
                    return {
                        id: client.id,
                        name: client.name,
                        createdAt: new Date(client.pivot.participationDate),
                        displayCreatedAt: date,
                        statusColor: TENDER_STATUS[client.pivot.status].color,
                        status: TENDER_STATUS[client.pivot.status].label,
                        subscriptionCost: +client.subscriptionCost,
                        payedCostForclient: +client.pivot.payedCostForclient,
                        costEnteredByAbifin: +client.pivot.costEnteredByAbifin,
                        percentageOfWiningTender: client.pivot.percentageOfWiningTender,
                        obtainedAmount: +client.pivot.obtainedAmount,
                        percentageAbifinRequest: client.pivot.percentageAbifinRequest,
                        requiredAmount: +client.pivot.requiredAmount,
                        imponibile: getImponibile(client, client.subscriptionCost),
                        coniva: getConIva(client, client.subscriptionCost),
                        previsto: getGeneratoPrevisto(client, client.subscriptionCost),
                        abifinRichiesto: +getABIFINRichiesto(client),
                        pivot: { ...client.pivot }
                    };
                })
            );
        } else {
            setClientsData([]);
        }
    }, [tender]);

    useEffect(() => {
        if (clientsData.length > 0) {
            const result = clientsTableHeaders.find(
                (column) => column.isSortable && column.sortStatus != null
            );
            if (result) {
                setClientsData([...sortArray(clientsData, result.column, result.sortStatus)]);
            } else {
                setClientsData([...sortArray(clientsData, 'id', 'asc')]);
            }
        }
    }, [clientsTableHeaders]);

    return (
        <section>
            {isLoading ? <Loader /> : null}

            {isAssociateClientOpen ? (
                <AssociateClient
                    isOpen={isAssociateClientOpen}
                    selectedTender={tender}
                    closeModel={onAssociateModalClose}
                />
            ) : null}

            {isUpdateTenderOpen ? (
                <AssociateClient
                    isOpen={isUpdateTenderOpen}
                    selectedTender={tender}
                    closeModel={onAssociateModalClose}
                    selectedData={selectedClient.pivot}
                    clientId={selectedClient?.id}
                    selectedClientName={selectedClient?.name}
                />
            ) : null}

            {isExportOpen ? (
                <ExportModal
                    isOpen={isExportOpen}
                    data={exportData}
                    fields={TENDER_FIELDS.map((item) => ({ ...item }))}
                    fileName="Bandi"
                    closeModel={closeExportModal}
                />
            ) : null}

            {isTenderCreateOpen ? (
                <CreateTender
                    isOpen={isTenderCreateOpen}
                    closeModel={onCloseModelHandler}
                    selectedTender={tender}
                />
            ) : null}

            {isFileUploadOpen ? (
                <FileUpload
                    isOpen={isFileUploadOpen}
                    type="tender"
                    id={tender?.id}
                    closeModel={onCloseFileUpload}
                />
            ) : null}

            <ConfirmationModal
                isOpen={isConfirmAssociationDelete}
                onConfirmAction={() => deleteAssociateTender(selectedClient)}
                title="Eliminazione"
                message="Sei sicuro di voler procedere con l’eliminazione?"
                closeModel={onAssociateRemoveConfirmModalClose}
            />

            {isArchiveConfirmOpen ? (
                <ConfirmationModal
                    isOpen={isArchiveConfirmOpen}
                    onConfirmAction={() => ArchiveConfirmBtnClickHandler(tender)}
                    title="Archivia"
                    message="Sei sicuro di voler procedere con l’archiviazione?"
                    closeModel={() => closeConfirmClickHandler('archive')}
                />
            ) : null}

            {isDeleteConfirmOpen ? (
                <ConfirmationModal
                    isOpen={isDeleteConfirmOpen}
                    onConfirmAction={() => DeleteConfirmBtnClickHandler(tender)}
                    title="Eliminazione"
                    message="Sei sicuro di voler procedere con l’eliminazione?"
                    closeModel={() => closeConfirmClickHandler('delete')}
                />
            ) : null}

            {isDeleteAttachmentConfirmOpen ? (
                <ConfirmationModal
                    isOpen={isDeleteAttachmentConfirmOpen}
                    onConfirmAction={() => deleteDocFromStorage(selectedAttachment)}
                    title="Eliminazione"
                    message="Sei sicuro di voler procedere con l’eliminazione?"
                    closeModel={onCancelDeleteImageHandler}
                />
            ) : null}

            <PageHeader title={getHeader()}>
                <div className="relative inline-block">
                    <RoundButton className="peer">
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                            />
                        </svg>
                    </RoundButton>
                    <div className="absolute right-0 hidden z-20 w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                        {!tender?.archive && tender?.matchingClients.length ? (
                            <span
                                className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                onClick={() => associateOnClickHandler(tender)}
                                aria-hidden="true">
                                Associa cliente
                            </span>
                        ) : null}
                        {/* {!tender?.archive ? ( */}
                        <span
                            className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                            onClick={() => editOnClickHandler(tender)}
                            aria-hidden="true">
                            Modifica bando
                        </span>
                        {/* ) : null} */}
                        {!tender?.archive ? (
                            <span
                                className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                onClick={() => attachOnClickHandler(tender)}
                                aria-hidden="true">
                                Allega file
                            </span>
                        ) : null}
                        <span
                            className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                            onClick={() => exportOnClickHandler(tender)}
                            aria-hidden="true">
                            Esporta
                        </span>
                        {!tender?.archive ? (
                            <span
                                className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                onClick={() => archiveOnClickHandler(tender)}
                                aria-hidden="true">
                                Archivia bando
                            </span>
                        ) : null}

                        <span
                            className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                            onClick={() => deleteOnClickHandler(tender)}
                            aria-hidden="true">
                            Elimina bando
                        </span>
                    </div>
                </div>
            </PageHeader>

            <div className="border-b border-b-greyLine space-x-2">
                <ol className="inline-flex items-center space-x-1">
                    <li
                        onClick={() => stateLabelOnClickHandler(0)}
                        aria-hidden="true"
                        className={`py-1 px-2 md:px-5 font-bold cursor-pointer ${
                            currentStateIndex === 0
                                ? ' text-primary-800 border-b-2 border-b-primary-800'
                                : null
                        }`}>
                        Dettagli
                    </li>
                    {/* {user.role !== 'tenderOffice' && ( */}
                    <li
                        onClick={() => stateLabelOnClickHandler(1)}
                        aria-hidden="true"
                        className={`py-1 px-2 md:px-5 font-bold cursor-pointer ${
                            currentStateIndex === 1
                                ? ' text-primary-800 border-b-2 border-b-primary-800'
                                : null
                        }`}>
                        Clienti ({tender?.clients && tender?.clients.length})
                    </li>
                    {/* )} */}

                    <li
                        onClick={() => stateLabelOnClickHandler(2)}
                        aria-hidden="true"
                        className={`py-1 px-2 md:px-5 font-bold cursor-pointer ${
                            currentStateIndex === 2
                                ? ' text-primary-800 border-b-2 border-b-primary-800'
                                : null
                        }`}>
                        Allegati (
                        {tender?.documents && tender.documents.length ? tender.documents.length : 0}
                        )
                    </li>
                </ol>
            </div>

            {currentStateIndex === 0 ? (
                <div className="space-y-4">
                    <div className="flex space-x-2">
                        <dt>Bando per</dt>
                        <dd className="font-bold">
                            {tender?.type != null
                                ? tender?.type === 'Company'
                                    ? 'Aziende'
                                    : tender?.type === 'Association'
                                    ? 'Associazioni'
                                    : 'Aziende e associazioni'
                                : '-'}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Titolo bando</dt>
                        <dd className="font-bold">{tender?.name != null ? tender?.name : '-'}</dd>
                    </div>
                    {/* <div className="flex space-x-2">
                        <dt>Bando per</dt>
                        <dd className="font-bold">
                            {tender?.tenderFor != null ? tender?.tenderFor : '-'}
                        </dd>
                    </div> */}
                    <div className="flex space-x-2">
                        <dt>Includi codici Ateco</dt>
                        <dd className="font-bold">
                            {tender?.includedAtecoCodes &&
                                tender.includedAtecoCodes.map((item) => item.name).join(', ')}
                        </dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Escludi codici Ateco</dt>
                        <dd className="font-bold">
                            {tender?.excludedAtecoCodes &&
                                tender.excludedAtecoCodes.map((item) => item.name).join(', ')}
                        </dd>
                    </div>
                    {/* <div className="flex space-x-2">
                        <dt>Escludi codici ATECO?</dt>
                        <dd className="font-bold">
                            {tender?.excludeAtecoCodes != null
                                ? tender?.excludeAtecoCodes == '1'
                                    ? 'Si'
                                    : 'No'
                                : '-'}
                        </dd>
                    </div> */}
                    <div className="flex space-x-2">
                        <dt>Scadenza</dt>
                        <dd className="font-bold">
                            {tender?.deadline != null ? reformatDate(tender?.deadline) : '-'}
                        </dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Introduzione</dt>
                        <dd className="font-bold">
                            {tender?.introduction != null ? tender?.introduction : '-'}
                        </dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Letto e Analizzato</dt>
                        <dd className="font-bold">
                            {tender?.isAnalyzed != null
                                ? tender?.isAnalyzed == '1'
                                    ? 'Si'
                                    : 'No'
                                : '-'}
                        </dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Plafond</dt>
                        <dd className="font-bold">
                            {tender?.ceiling != null ? tender?.ceiling : '-'}
                        </dd>
                    </div>
                    <div className="flex space-x-2">
                        <dt>Plafond testuale</dt>
                        <dd className="font-bold">
                            {tender?.textualCeiling != null ? tender?.textualCeiling : '-'}
                        </dd>
                    </div>
                    {tender?.type === 'All' || tender?.type === 'Company' ? (
                        <div className="flex space-x-2">
                            <dt>Fatturato</dt>
                            <dd className="font-bold">
                                {tender?.revenue != null ? tender?.revenue?.name : '-'}
                            </dd>
                        </div>
                    ) : null}

                    {tender?.type === 'All' || tender?.type === 'Association' ? (
                        <>
                            <div className="flex space-x-2">
                                <dt>Registri</dt>
                                <dd className="font-bold">
                                    {tender?.registeries &&
                                        tender.registeries.map((item) => item.name).join(', ')}
                                </dd>
                            </div>

                            <div className="flex space-x-2">
                                <dt>Settore</dt>
                                <dd className="font-bold">
                                    {tender?.sectors &&
                                        tender.sectors.map((item) => item.name).join(', ')}
                                </dd>
                            </div>

                            <div className="flex space-x-2">
                                <dt>Massimo richiedibile</dt>
                                <dd className="font-bold">
                                    {tender?.maximumRequested != null
                                        ? tender?.maximumRequested
                                        : '-'}
                                </dd>
                            </div>
                        </>
                    ) : null}

                    <div className="flex space-x-2">
                        <dt>Regione</dt>
                        <dd className="font-bold">
                            {tender?.regions.map((item) => item.name).join(', ')}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>CAP</dt>
                        <dd className="font-bold">
                            {tender?.postalCode != null ? tender?.postalCode : '-'}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Provincia</dt>
                        <dd className="font-bold">
                            {tender?.provinces.map((item) => item.name).join(', ')}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Classificazione</dt>
                        <dd className="font-bold">
                            {tender?.classification != null ? tender?.classification : '-'}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Percentuale finanziamento</dt>
                        <dd className="font-bold">
                            {tender?.loanPercentage != null ? tender?.loanPercentage : '-'}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Ente</dt>
                        <dd className="font-bold">
                            {tender?.entity != null ? tender?.entity : '-'}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>URl fondo bando</dt>
                        <dd className="font-bold">
                            {tender?.tenderUrl != null ? tender?.tenderUrl : '-'}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Maggioranza femminile</dt>
                        <dd className="font-bold">
                            {tender?.femaleMajority != null
                                ? tender?.femaleMajority == '1'
                                    ? 'Si'
                                    : 'No'
                                : '-'}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Maggioranza giovanile</dt>
                        <dd className="font-bold">
                            {tender?.youthMajority != null
                                ? tender?.youthMajority == '1'
                                    ? 'Si'
                                    : 'No'
                                : '-'}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Minimo anni di attività</dt>
                        <dd className="font-bold">
                            {tender?.yearOfActivity != null ? tender?.yearOfActivity : '-'}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Note aggiuntive</dt>
                        <dd className="font-bold">
                            {tender?.additionalNotes != null ? tender?.additionalNotes : '-'}
                        </dd>
                    </div>

                    <div className="flex space-x-2">
                        <dt>Link Google Drive</dt>
                        <dd className="font-bold">
                            {tender?.driveLink != null ? (
                                <a
                                    href={tender?.driveLink}
                                    target="_blank"
                                    className="cursor-pointer text-primary-800 truncate"
                                    rel="noreferrer">
                                    {tender?.driveLink}
                                </a>
                            ) : (
                                '-'
                            )}
                        </dd>
                    </div>
                </div>
            ) : null}

            {currentStateIndex === 1 && tender?.clients ? (
                <DataTable
                    columns={clientsTableHeaders}
                    rows={getClientsRows(clientsData)}
                    sortBtnClick={onChangeSortBtnClick}
                />
            ) : null}

            {currentStateIndex === 2 ? (
                <DataTable columns={FILES_TABLE_COLUMNS} rows={getDocumentsRows(tender)} />
            ) : null}
        </section>
    );
}

export default Tender;
