/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CAP_REGEX, EMAIL_REGEX, INIT_CLIENT, MESSAGES } from '../../constants';
import { createNewClient, updateClient } from '../../helper/services/apis/client-services';
import AtecoDropdown from '../dropdown/AtecoDropdown';
import ProvinveDropdown from '../dropdown/ProvinveDropdown';
import RegionDropDown from '../dropdown/RegionDropDown';
import RegisteriesDropdown from '../dropdown/RegisteriesDropdown';
import SectorsDropdown from '../dropdown/SectorsDropdown';
import YearDropdown from '../dropdown/YearDropdown';
import Loader from '../Loader';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';
import RoundButton from '../RoundButton';

function CreateClient({
    isOpen = false,
    isCompany = true,
    closeModel = null,
    selectedClient = null
}) {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        control,
        formState: { errors }
    } = useForm();
    const { fields, remove, append } = useFieldArray({
        control,
        name: 'operative'
    });

    const [isLoading, setIsLoading] = useState(false);
    const isOperativeSameAsLegal = watch('isOperativeSameAsLegal');
    const [atecoCodes, setAtecoCodes] = useState([]);
    const [sector, setSector] = useState([]);
    const [registery, setRegistery] = useState([]);

    // eslint-disable-next-line consistent-return
    const onSubmitHandler = async (data) => {
        // setIsLoading(true);
        let atecoCode = [];
        let registeries = [];
        let sectors = [];

        if (data.atecoCodes.length) {
            atecoCode = data.atecoCodes.map((code) => code.value);
        }
        data.atecoCodes = atecoCode;

        if (!isCompany) {
            if (data.registeries.length) {
                registeries = data.registeries.map((code) => code.value);
            }
            if (data.sectors.length) {
                sectors = data.sectors.map((code) => code.value);
            }
        }

        data.registeries = registeries;
        data.sectors = sectors;

        if (data.isOperativeSameAsLegal === '0' && data.operative.length == 0) {
            toast.error('È necessario inserire almeno un indirizzo operativo.');
            return false;
        }
        if (selectedClient) {
            const result = await updateClient(data.id, data).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                closeModel();
            }
        } else {
            data.type = isCompany ? 'Company' : 'Association';
            const result = await createNewClient(data).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
                setIsLoading(false);
            });
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                setIsLoading(false);
                closeModel();
            }
        }
    };

    // const legaleAddressOnChange = (query, address) => {
    //     const data = {
    //         query,
    //         address
    //     };
    //     if ((query, address)) {
    //         setValue('legaleAddress', data);
    //     } else {
    //         setValue('legaleAddress', '');
    //     }
    // };

    // const operativeAddressOnChange = (query, address) => {
    //     const data = {
    //         query,
    //         address
    //     };
    //     if ((query, address)) {
    //         setValue('operativeAddress', data);
    //     } else {
    //         setValue('operativeAddress', '');
    //     }
    // };

    const setClient = (client) => {
        if (client) {
            console.log(client);
            const legaleAdd = client.addresses.legal.find((item) => item.type === 'legal');
            // operative
            // const operativeAdd = client.addresses.find((item) => item.type === 'operative');

            setAtecoCodes([
                ...client.atecoCodes.map((item) => ({ value: item.id, label: item.name }))
            ]);
            setSector([...client.sectors.map((item) => ({ value: item.id, label: item.name }))]);
            setRegistery([
                ...client.registeries.map((item) => ({ value: item.id, label: item.name }))
            ]);
            const data = {
                ...client,
                legalAddressId: legaleAdd.id,
                legalAddressCity: legaleAdd.city,
                legalAddressRegionId: legaleAdd.regionId,
                legalAddressProvinceId: legaleAdd.provinceId,
                legalAddressHouseNumber: legaleAdd.houseNumber,
                legalPostalCode: legaleAdd.postalCode,
                operative: client.addresses.operative,
                // operativeAddressId: operativeAdd.id,
                // operativeAddressCity: operativeAdd.city,
                // operativeAddressRegionId: operativeAdd.regionId,
                // operativeAddressProvinceId: operativeAdd.provinceId,
                // operativeAddressHouseNumber: operativeAdd.houseNumber,
                // operativePostalCode: operativeAdd.postalCode,
                isOperativeSameAsLegal:
                    client.isOperativeSameAsLegal != null
                        ? client.isOperativeSameAsLegal.toString()
                        : null,
                subscription: client.subscription != null ? client.subscription.toString() : null,
                registeries: client.registeries != null ? client.registeries.toString() : null,
                sectors: client.sectors != null ? client.sectors.toString() : null
            };

            delete data.createdAt;
            delete data.updatedAt;
            delete data.addresses;

            reset({ ...data });
        } else {
            reset({ ...INIT_CLIENT });
        }
    };

    useEffect(() => {
        if (selectedClient) {
            setClient(selectedClient);
        } else {
            setClient(null);
        }
    }, [selectedClient]);

    useEffect(() => {
        console.log(errors);
    }, [errors]);

    return (
        <ModalComponent isOpen={isOpen} size="max-w-6xl">
            {isLoading ? <Loader /> : null}
            {/* Modal header  */}
            <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                <h3 className="text-xl font-semibold text-white">
                    {isCompany
                        ? `${selectedClient ? 'Modifica' : 'Nuova'} azienda`
                        : `${selectedClient ? 'Modifica' : 'Nuova'} associazione`}
                </h3>
                <button
                    type="button"
                    className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={() => closeModel()}>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>

            {/* { !selectedClient  } */}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    {/* section one */}
                    <div className="border-b border-grey-800">
                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div
                                className={`w-full input-field-group md:w-1/4 ${
                                    errors?.name ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="name">Ragione sociale*</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Ragione sociale"
                                    {...register('name', { required: true, maxLength: 255 })}
                                />
                                {errors?.name?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                                {errors?.name?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/4 ${
                                    errors?.firstContactWithAbifin ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="firstContactWithAbifin">
                                    Primo contatto Abifin
                                </label>
                                <input
                                    type="text"
                                    id="firstContactWithAbifin"
                                    name="firstContactWithAbifin"
                                    placeholder="Primo contatto Abifin"
                                    {...register('firstContactWithAbifin', { maxLength: 255 })}
                                />
                                {errors?.firstContactWithAbifin?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/4 ${
                                    errors?.abifinReference ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="abifinReference">Referente Abifin</label>
                                <input
                                    type="text"
                                    id="abifinReference"
                                    name="abifinReference"
                                    placeholder="Referente Abifin"
                                    {...register('abifinReference', { maxLength: 255 })}
                                />
                                {errors?.abifinReference?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/4 ${
                                    errors?.signalman ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="signalman">Segnalatore Abifin</label>
                                <input
                                    type="text"
                                    id="signalman"
                                    name="signalman"
                                    placeholder="Segnalatore Abifin"
                                    {...register('signalman', { maxLength: 255 })}
                                />
                                {errors?.signalman?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            {/* <PlacesAutocomplete
                                label="Indirizzo sede legale (via e città)*"
                                name="legaleAddress"
                                onChange={legaleAddressOnChange}
                                errors={errors}
                                defaultValue={legaleDefaultQuery}
                            />
                            <input
                                type="hidden"
                                {...register('legaleAddress', { required: true })}
                            /> */}
                            <div
                                className={`w-full input-field-group md:w-1/4 ${
                                    errors?.legalAddressCity ? 'invalid-field' : ''
                                }`}>
                                <label>Città*</label>
                                <input
                                    type="text"
                                    placeholder="Città"
                                    {...register('legalAddressCity', {
                                        required: true,
                                        maxLength: 255
                                    })}
                                />
                                {errors?.legalAddressCity?.type === 'required' && (
                                    <p>{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                                {errors?.legalAddressCity?.type === 'maxLength' && (
                                    <p>{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/6 ${
                                    errors?.legalPostalCode ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="legalPostalCode">CAP*</label>
                                <input
                                    type="text"
                                    id="legalPostalCode"
                                    name="legalPostalCode"
                                    placeholder="CAP"
                                    {...register('legalPostalCode', {
                                        pattern: CAP_REGEX,
                                        required: true
                                    })}
                                />
                                {errors?.legalPostalCode?.type === 'required' && (
                                    <p>{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                                {errors?.legalPostalCode?.type === 'pattern' && (
                                    <p>CAP non è valido</p>
                                )}
                            </div>

                            <RegionDropDown
                                register={register}
                                name="legalAddressRegionId"
                                errors={errors}
                                config={{ required: true }}
                                className="md:w-1/5"
                            />

                            <ProvinveDropdown
                                register={register}
                                name="legalAddressProvinceId"
                                errors={errors}
                                config={{ required: true }}
                                className="md:w-1/5"
                            />
                            <div
                                className={`w-full input-field-group md:w-1/5 ${
                                    errors?.legalAddressHouseNumber ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="legalAddressHouseNumber">Via e numero*</label>
                                <input
                                    type="text"
                                    id="legalAddressHouseNumber"
                                    name="legalAddressHouseNumber"
                                    placeholder="Via e numero"
                                    {...register('legalAddressHouseNumber', {
                                        required: true,
                                        maxLength: 255
                                    })}
                                />
                                {errors?.legalAddressHouseNumber?.type === 'required' && (
                                    <p>{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                                {errors?.legalAddressHouseNumber?.type === 'maxLength' && (
                                    <p>{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>
                        </div>

                        <div className="my-4">
                            {/* TODO: add model  Address section */}
                            <p
                                className={`font-bold w-full ${
                                    errors.isOperativeSameAsLegal ? 'text-redInvalid' : ''
                                }`}>
                                La sede operativa è diversa dalla sede legale?*
                            </p>
                            <div className="flex w-full flex-wrap space-x-4">
                                <div className="flex items-center space-x-2">
                                    <input
                                        id="inline-radio"
                                        type="radio"
                                        value="0"
                                        name="isOperativeSameAsLegal"
                                        checked={isOperativeSameAsLegal == '0'}
                                        {...register('isOperativeSameAsLegal', { required: true })}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label htmlFor="inline-radio">Si</label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <input
                                        id="inline-radio"
                                        type="radio"
                                        value="1"
                                        name="isOperativeSameAsLegal"
                                        checked={isOperativeSameAsLegal == '1'}
                                        {...register('isOperativeSameAsLegal', { required: true })}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label htmlFor="inline-radio">No</label>
                                </div>
                            </div>
                        </div>

                        {isOperativeSameAsLegal === '0' ? (
                            <>
                                {fields.map(({ id }, index) => (
                                    <div
                                        className="flex flex-col w-full md:flex-row md:space-x-4 my-4"
                                        key={id}>
                                        <div
                                            className={`w-full input-field-group md:w-1/4 ${
                                                errors?.operative?.[index]?.city
                                                    ? 'invalid-field'
                                                    : ''
                                            }`}>
                                            <label>Città*</label>
                                            <input
                                                type="text"
                                                {...register(`operative[${index}].city`, {
                                                    required: true,
                                                    maxLength: 255
                                                })}
                                                placeholder="Città"
                                            />
                                            {errors?.operative?.[index]?.city?.type ===
                                                'required' && <p>{MESSAGES.FIELD_REQUIRED}</p>}
                                            {errors?.operative?.[index]?.city?.type ===
                                                'maxLength' && <p>{MESSAGES.maxLimitMessage()}</p>}
                                        </div>

                                        <div
                                            className={`w-full input-field-group md:w-1/6 ${
                                                errors?.operative?.[index]?.postalCode
                                                    ? 'invalid-field'
                                                    : ''
                                            }`}>
                                            <label htmlFor="operativePostalCode">CAP*</label>
                                            <input
                                                type="text"
                                                id="operativePostalCode"
                                                name="operativePostalCode"
                                                placeholder="CAP"
                                                {...register(`operative[${index}].postalCode`, {
                                                    pattern: CAP_REGEX,
                                                    required: true
                                                })}
                                            />
                                            {errors?.operative?.[index]?.postalCode?.type ===
                                                'required' && <p>{MESSAGES.FIELD_REQUIRED}</p>}
                                            {errors?.operative?.[index]?.postalCode?.type ===
                                                'pattern' && <p>CAP non è valido</p>}
                                        </div>

                                        <RegionDropDown
                                            register={register}
                                            name={`operative[${index}].regionId`}
                                            errors={errors?.operative?.[index]}
                                            config={{ required: true }}
                                            className="md:w-1/5"
                                            fieldArrayName="regionId"
                                        />

                                        <ProvinveDropdown
                                            register={register}
                                            name={`operative[${index}].provinceId`}
                                            errors={errors?.operative?.[index]}
                                            config={{ required: true }}
                                            className="md:w-1/5"
                                            fieldArrayName="provinceId"
                                        />

                                        <div
                                            className={`w-full input-field-group md:w-1/5 ${
                                                errors?.operative?.[index]?.houseNumber
                                                    ? 'invalid-field'
                                                    : ''
                                            }`}>
                                            <label htmlFor={`operative[${index}]?.houseNumber`}>
                                                Via e numero*
                                            </label>
                                            <input
                                                id={`operative[${index}]?.houseNumber`}
                                                name={`operative[${index}]?.houseNumber`}
                                                type="text"
                                                placeholder="Via e numero"
                                                {...register(`operative[${index}].houseNumber`, {
                                                    required: true,
                                                    maxLength: 255
                                                })}
                                            />
                                            {errors?.operative?.[index]?.houseNumber?.type ===
                                                'required' && <p>{MESSAGES.FIELD_REQUIRED}</p>}
                                            {errors?.operative?.[index]?.houseNumber?.type ===
                                                'maxLength' && <p>{MESSAGES.maxLimitMessage()}</p>}
                                        </div>

                                        <button
                                            type="button"
                                            className="text-red-500 bg-transparent hover:text-white hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                            data-modal-toggle="defaultModal"
                                            onClick={() => remove(index)}>
                                            <svg
                                                aria-hidden="true"
                                                className="w-5 h-5"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>
                                ))}

                                <div className="flex w-full md:flex-row md:space-x-4 my-4">
                                    <RoundButton onClick={() => append({})}>
                                        <span className="p-2 w-18 h-18">+</span>
                                    </RoundButton>
                                </div>
                            </>
                        ) : null}
                    </div>

                    {/* Section Two */}
                    <div className="border-b border-grey-800">
                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div
                                className={`w-full input-field-group md:w-1/4 ${
                                    errors?.companyFiscalCode ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="companyFiscalCode">Codice fiscale azienda</label>
                                <input
                                    type="text"
                                    id="companyFiscalCode"
                                    name="companyFiscalCode"
                                    placeholder="Codice fiscale azienda"
                                    {...register('companyFiscalCode', { maxLength: 255 })}
                                />
                                {errors?.companyFiscalCode?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/4 ${
                                    errors?.vatNumber ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="vatNumber">PIVA</label>
                                <input
                                    type="text"
                                    id="vatNumber"
                                    name="vatNumber"
                                    placeholder="PIVA"
                                    {...register('vatNumber', { maxLength: 255 })}
                                />
                                {errors?.vatNumber?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/4 ${
                                    errors?.email ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="email">Email Azienda</label>
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    placeholder="Email Azienda"
                                    {...register('email', { maxLength: 255, pattern: EMAIL_REGEX })}
                                />
                                {errors?.email?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                                {errors?.email?.type === 'pattern' && (
                                    <p className="text-red-700">{MESSAGES.INVALID_EMAIL}</p>
                                )}
                            </div>
                            {/* TODO : Phone regex */}
                            <div
                                className={`w-full input-field-group md:w-1/4 ${
                                    errors?.phoneNumber ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="phoneNumber">Telefono azienda</label>
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder="Telefono azienda"
                                    {...register('phoneNumber', {
                                        maxLength: 255
                                    })}
                                />
                                {errors?.phoneNumber?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                                {errors?.phoneNumber?.type === 'pattern' && (
                                    <p className="text-red-700">{MESSAGES.INVALID_PHONE_NUMBER}</p>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div
                                className={`w-full input-field-group md:w-1/3 ${
                                    errors?.legalEmail ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="legalEmail">PEC Azienda</label>
                                <input
                                    id="legalEmail"
                                    name="legalEmail"
                                    type="text"
                                    placeholder="PEC Azienda"
                                    {...register('legalEmail', {
                                        maxLength: 255,
                                        pattern: EMAIL_REGEX
                                    })}
                                />
                                {errors?.legalEmail?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                                {errors?.legalEmail?.type === 'pattern' && (
                                    <p className="text-red-700">{MESSAGES.INVALID_EMAIL}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/3 ${
                                    errors?.uniqueCode ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="uniqueCode">Codice Univoco</label>
                                <input
                                    id="uniqueCode"
                                    name="uniqueCode"
                                    type="text"
                                    placeholder="Codice Univoco"
                                    {...register('uniqueCode', { maxLength: 255 })}
                                />
                                {errors?.uniqueCode?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>

                            <div className="w-full md:w-1/3">
                                <p className="font-bold w-full">Abbonato?</p>
                                <div className="flex w-full flex-wrap space-x-4">
                                    <div className="flex items-center space-x-2">
                                        <input
                                            id="inline-radio"
                                            type="radio"
                                            value="1"
                                            name="subscription"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            {...register('subscription')}
                                        />
                                        <label htmlFor="subscription2">Si</label>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <input
                                            id="inline-radio"
                                            type="radio"
                                            value="0"
                                            name="subscription"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            {...register('subscription')}
                                        />
                                        <label htmlFor="subscription">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div className="w-full input-field-group md:w-1/2">
                                <label htmlFor="firstSubscription">Primo Abbonamento</label>
                                <input
                                    id="firstSubscription"
                                    name="firstSubscription"
                                    type="date"
                                    onKeyDown={(e) => e.preventDefault()}
                                    placeholder="Primo Abbonamento"
                                    {...register('firstSubscription', { required: false })}
                                />
                            </div>
                            <div className="w-full input-field-group md:w-1/2">
                                <label htmlFor="subscriptionCost">Abbonamento (€)</label>
                                <input
                                    id="subscriptionCost"
                                    name="subscriptionCost"
                                    type="number"
                                    step=".01"
                                    placeholder="Importo ottenuto (€)"
                                    {...register('subscriptionCost', { required: false })}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Section Three */}
                    <div className="border-b border-grey-800">
                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div
                                className={`w-full input-field-group md:w-1/2 ${
                                    errors?.legalRepresentative ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="legalRepresentative">
                                    Legale rappresentante (se più di uno separare con virgola)
                                </label>
                                <input
                                    type="text"
                                    id="legalRepresentative"
                                    name="legalRepresentative"
                                    placeholder="Legale rappresentante"
                                    {...register('legalRepresentative', { maxLength: 500 })}
                                />
                                {errors?.legalRepresentative?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage(500)}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/2 ${
                                    errors?.legalRepresentativeTaxCode ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="legalRepresentativeTaxCode">
                                    C. Fisc legale rappresentante (se più di uno separare con
                                    virgola)
                                </label>
                                <input
                                    type="text"
                                    id="legalRepresentativeTaxCode"
                                    name="legalRepresentativeTaxCode"
                                    placeholder="C. Fisc legale rappresentante"
                                    {...register('legalRepresentativeTaxCode', { maxLength: 255 })}
                                />
                                {errors?.legalRepresentativeTaxCode?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div
                                className={`w-full input-field-group md:w-1/2 ${
                                    errors?.referanceName ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="referanceName">Referente</label>
                                <input
                                    type="text"
                                    placeholder="Referente"
                                    id="referanceName"
                                    name="referanceName"
                                    {...register('referanceName', { maxLength: 255 })}
                                />
                                {errors?.referanceName?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/2 ${
                                    errors?.referanceEmail ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="referanceEmail">Email referente</label>
                                <input
                                    type="text"
                                    placeholder="Email referente"
                                    id="referanceEmail"
                                    name="referanceEmail"
                                    {...register('referanceEmail', {
                                        maxLength: 255,
                                        pattern: EMAIL_REGEX
                                    })}
                                />
                                {errors?.referanceEmail?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                                {errors?.referanceEmail?.type === 'pattern' && (
                                    <p className="text-red-700">{MESSAGES.INVALID_EMAIL}</p>
                                )}
                            </div>

                            <div
                                className={`w-full input-field-group md:w-1/2 ${
                                    errors?.referancePhoneNumber ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="referancePhoneNumber">Telefono referente</label>
                                <input
                                    type="text"
                                    placeholder="Telefono referente"
                                    id="referancePhoneNumber"
                                    name="referancePhoneNumber"
                                    {...register('referancePhoneNumber', {
                                        maxLength: 255
                                    })}
                                />
                                {errors?.referancePhoneNumber?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                                {errors?.referancePhoneNumber?.type === 'pattern' && (
                                    <p className="text-red-700">{MESSAGES.INVALID_PHONE_NUMBER}</p>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <YearDropdown
                                label="Anno costituzione"
                                register={register}
                                name="yearOfEstablishment"
                                errors={errors}
                                className="md:w-1/4"
                            />

                            <YearDropdown
                                label="Anno avvio attività"
                                register={register}
                                name="yearOfStartBusiness"
                                errors={errors}
                                className="md:w-1/4"
                            />

                            <YearDropdown
                                label="Anno ultimo fatturato"
                                register={register}
                                name="lastYearTurnover"
                                errors={errors}
                                className="md:w-1/4"
                            />

                            <div
                                className={`w-full input-field-group md:w-1/4 ${
                                    errors?.invoiceAmount ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="invoiceAmount">Importo fatturato</label>
                                <input
                                    type="number"
                                    step=".01"
                                    id="invoiceAmount"
                                    name="invoiceAmount"
                                    placeholder="Importo fatturato"
                                    {...register('invoiceAmount', { maxLength: 23 })}
                                />
                                {errors?.bank?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage(20)}</p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Section Four */}
                    <div className="border-b border-grey-800">
                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div
                                className={`w-full input-field-group md:w-1/3 ${
                                    errors?.bank ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="bank">Banca</label>
                                <input
                                    id="bank"
                                    name="bank"
                                    type="text"
                                    placeholder="Banca"
                                    {...register('bank', { maxLength: 255 })}
                                />
                                {errors?.bank?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>
                            <div
                                className={`w-full input-field-group md:w-2/3 ${
                                    errors?.bankAccount ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="bankAccount">IBAN</label>
                                <input
                                    id="bankAccount"
                                    name="bankAccount"
                                    type="text"
                                    placeholder="IBAN"
                                    {...register('bankAccount', { maxLength: 255 })}
                                />
                                {errors?.bankAccount?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                )}
                            </div>
                        </div>
                        {isCompany ? (
                            <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                                {/* TODO : Ateco code */}
                                <AtecoDropdown
                                    register={register}
                                    label="Codice Ateco (se più di uno separare con virgola)*"
                                    name="atecoCodes"
                                    config={{ required: true }}
                                    errors={errors}
                                    className="md:w-1/2"
                                    defaultValue={atecoCodes}
                                    setValue={setValue}
                                />
                                {/* <div className="w-full input-field-group md:w-1/2">
                                    <label>Codice Ateco (se più di uno separare con virgola)</label>
                                </div> */}

                                <div
                                    className={`w-full input-field-group md:w-1/4 ${
                                        errors?.reaCode ? 'invalid-field' : ''
                                    }`}>
                                    <label htmlFor="reaCode">REA</label>
                                    <input
                                        id="reaCode"
                                        name="reaCode"
                                        type="text"
                                        placeholder="REA"
                                        {...register('reaCode', { maxLength: 255 })}
                                    />
                                    {errors?.reaCode?.type === 'maxLength' && (
                                        <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                    )}
                                </div>

                                <div
                                    className={`w-full input-field-group md:w-1/4 ${
                                        errors?.inpsCode ? 'invalid-field' : ''
                                    }`}>
                                    <label htmlFor="inpsCode">Numero INPS</label>
                                    <input
                                        id="inpsCode"
                                        name="inpsCode"
                                        type="text"
                                        placeholder="Numero INPS"
                                        {...register('inpsCode', { maxLength: 255 })}
                                    />
                                    {errors?.inpsCode?.type === 'maxLength' && (
                                        <p className="text-red-700">{MESSAGES.maxLimitMessage()}</p>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                                    <div
                                        className={`w-full input-field-group md:w-1/3 ${
                                            errors?.registrationRegisters ? 'invalid-field' : ''
                                        }`}>
                                        <label htmlFor="registrationRegisters">
                                            Iscrizione registri
                                        </label>
                                        <input
                                            type="text"
                                            id="registrationRegisters"
                                            name="registrationRegisters"
                                            placeholder="Iscrizione registri"
                                            {...register('registrationRegisters', {
                                                maxLength: 255
                                            })}
                                        />
                                        {errors?.registrationRegisters?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                    </div>
                                    <div
                                        className={`w-full input-field-group md:w-1/3 ${
                                            errors?.registrationRegistersNumber
                                                ? 'invalid-field'
                                                : ''
                                        }`}>
                                        <label htmlFor="registrationRegistersNumber">
                                            Numero iscrizione registro
                                        </label>
                                        <input
                                            type="text"
                                            id="registrationRegistersNumber"
                                            name="registrationRegistersNumber"
                                            placeholder="Numero iscrizione registro"
                                            {...register('registrationRegistersNumber', {
                                                maxLength: 255
                                            })}
                                        />
                                        {errors?.registrationRegistersNumber?.type ===
                                            'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                    </div>
                                    <div
                                        className={`w-full input-field-group md:w-1/3 ${
                                            errors?.registrationRegistersDate ? 'invalid-field' : ''
                                        }`}>
                                        <label htmlFor="registrationRegistersDate">
                                            Data iscrizione registro
                                        </label>
                                        <input
                                            id="registrationRegistersDate"
                                            name="registrationRegistersDate"
                                            placeholder="Data iscrizione registro"
                                            type="date"
                                            {...register('registrationRegistersDate')}
                                            onKeyDown={(e) => e.preventDefault()}
                                        />
                                        {errors?.registrationRegistersDate?.type ===
                                            'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                                    {/* <RegisteriesDropdown
                                        register={register}
                                        name="registerId"
                                        errors={errors}
                                        className="md:w-1/3"
                                    />
                                    <SectorsDropdown
                                        register={register}
                                        name="sectorId"
                                        errors={errors}
                                        config={{ required: true }}
                                        className="md:w-1/3"
                                    /> */}
                                    <RegisteriesDropdown
                                        register={register}
                                        name="registeries"
                                        errors={errors}
                                        className="md:w-1/3"
                                        setValue={setValue}
                                        defaultValue={registery}
                                        multiple
                                    />
                                    <SectorsDropdown
                                        register={register}
                                        name="sectors"
                                        errors={errors}
                                        config={{ required: true }}
                                        className="md:w-1/3"
                                        setValue={setValue}
                                        defaultValue={sector}
                                        multiple
                                    />
                                    <div
                                        className={`w-full input-field-group md:w-1/3 ${
                                            errors?.federationAffiliations ? 'invalid-field' : ''
                                        }`}>
                                        <label htmlFor="federationAffiliations">
                                            Affiliazioni federazioni
                                        </label>
                                        <input
                                            type="text"
                                            id="federationAffiliations"
                                            name="federationAffiliations"
                                            placeholder="Affiliazioni federazioni"
                                            {...register('federationAffiliations', {
                                                maxLength: 255
                                            })}
                                        />
                                        {errors?.federationAffiliations?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div className="w-full input-field-group md:w-1/3">
                                <label htmlFor="companySize">Grandezza impresa</label>
                                <select
                                    id="companySize"
                                    name="companySize"
                                    {...register('companySize')}>
                                    <option value="">Non dichiarato</option>
                                    <option value="Micro">Micro Impresa</option>
                                    <option value="Piccola">Piccola Impresa</option>
                                    <option value="Media">Media Impresa</option>
                                    <option value="Grande">Grande Impresa</option>
                                </select>
                                <p className="hidden">Test</p>
                            </div>

                            <div className="w-full input-field-group md:w-1/3">
                                <label htmlFor="femaleMajority">Maggioranza femminile</label>
                                <select
                                    id="femaleMajority"
                                    name="femaleMajority"
                                    {...register('femaleMajority')}>
                                    <option value="">Non dichiarato</option>
                                    <option value="0">No</option>
                                    <option value="1">Si</option>
                                </select>
                            </div>

                            <div className="w-full input-field-group md:w-1/3">
                                <label htmlFor="youthMajority">Maggioranza giovanile</label>
                                <select
                                    id="youthMajority"
                                    name="youthMajority"
                                    {...register('youthMajority')}>
                                    <option value="">Non dichiarato</option>
                                    <option value="0">No</option>
                                    <option value="1">Si</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="border-b border-grey-800">
                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            <div
                                className={`w-full input-field-group ${
                                    errors?.driveLink ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="driveLink">Link Google Drive</label>
                                <input
                                    type="url"
                                    id="driveLink"
                                    name="driveLink"
                                    {...register('driveLink', { maxLength: 1000 })}
                                />
                                {errors?.driveLink?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage(1000)}</p>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:flex-row md:space-x-4 my-4">
                            {/* <div
                                className={`w-full input-field-group ${
                                    errors?.driveLink ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="driveLink">Link Google Drive</label>
                                <input
                                    type="url"
                                    id="driveLink"
                                    name="driveLink"
                                    {...register('driveLink', { maxLength: 1000 })}
                                />
                                {errors?.driveLink?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage(1000)}</p>
                                )}
                            </div> */}

                            <div
                                className={`w-full input-field-group ${
                                    errors?.note ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="note">Note</label>
                                <textarea
                                    id="note"
                                    name="note"
                                    row="2"
                                    {...register('note', { maxLength: 500 })}
                                />
                                {errors?.note?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage(500)}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                    <PrimaryButton type="submit">
                        {isCompany
                            ? `${selectedClient ? 'MODIFICA' : 'AGGIUNGI'} CLIENTE`
                            : `${selectedClient ? 'MODIFICA' : 'AGGIUNGI'} ASSOCIAZIONE`}
                    </PrimaryButton>
                    <SecondaryButton onClick={() => closeModel()}>ANNULLA</SecondaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default CreateClient;
