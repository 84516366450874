/* eslint-disable react/prop-types */
import React from 'react';
import Logo from '../assets/images/logo.png';

function AuthContainer({ children }) {
    return (
        <div className="flex justify-center items-center relative w-[749px] h-[663px] z-0">
            <div className="w-full h-full absolute top-0 left-0 bg-primary-800 md:rotate-[30deg]" />
            <div className="w-full h-full absolute top-0 left-0 bg-white md:rotate-[15deg]" />
            <div className="z-10 flex flex-col justify-center items-center w-3/4">
                <img src={Logo} alt="logo" className="w-16" />
                <div className="w-full">{children}</div>
            </div>
        </div>
    );
}

export default AuthContainer;
