/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants';
import {
    clientAssignConsultancy,
    clientAssignTender
} from '../../helper/services/apis/client-services';
import {
    getArchiveTendersDropdown,
    getConsultancyDropdown,
    getTendersMatchingToClientDropdown
} from '../../helper/services/apis/common-services';
import Loader from '../Loader';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

function AssociateAction({ isOpen = false, type = '', closeModel = null, selectedClient }) {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm();
    const status = watch('status');

    const [actionType, setActionType] = useState(type);
    const [consultanciesOptions, setConsultanciesOptions] = useState([]);
    const [tenders, setTenders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmitTenderHandler = async (data) => {
        clientAssignTender(selectedClient.id, { ...data })
            .then((data) => {
                toast.success(MESSAGES.SUCCESS);
                closeModel(true);
            })
            .catch((e) => {
                const message =
                    e.message.indexOf('Duplicate entry') > 0
                        ? 'Il cliente è già associato al bando selezionato.'
                        : e.message;
                toast.error(message);
                return false;
            });
    };

    const onSubmitConsultancyHandler = async (data) => {
        clientAssignConsultancy(selectedClient.id, { ...data })
            .then((data) => {
                toast.success(MESSAGES.SUCCESS);
                closeModel(true);
            })
            .catch((e) => {
                const message =
                    e.message.indexOf('Duplicate entry') > 0
                        ? 'Il cliente è già associato alla consulenza selezionata.'
                        : e.message;
                toast.error(message);
                return false;
            });
    };

    const loadConsultancy = async (type, id) => {
        const result = await getConsultancyDropdown(type, id).catch((e) => toast.error(e.message));
        if (result.data) {
            setConsultanciesOptions([...result.data]);
        }
    };

    const initClientTenders = async () => {
        const result = await getTendersMatchingToClientDropdown(selectedClient.id).catch((e) =>
            toast.error(e.message)
        );

        if (result.data) {
            setTenders([...result.data]);
        }
    };

    const initArchiveTenders = async (dataType) => {
        setIsLoading(true);
        const result = await getArchiveTendersDropdown(dataType).catch((e) =>
            toast.error(e.message)
        );

        if (result.data) {
            setTenders(result.data.map((item) => ({ value: item.id, label: item.name })));
        }
        setIsLoading(false);
    };

    const actionOnChangeEvent = (event) => {
        const e = event.target.value;
        setActionType(e);
        setValue('tenderId', '');
        if (e === 'tender') {
            initClientTenders();
        } else if (e === 'tenderArchive') {
            initArchiveTenders('short');
        } else {
            loadConsultancy(e, selectedClient?.id);
        }
    };

    const handleTenderArchiveChange = (event) => {
        if (event) {
            setValue('tenderId', event.value);
        } else {
            setValue('tenderId', '');
        }
    };

    // useEffect(() => {
    //     initClientTenders();
    // }, []);

    return (
        <ModalComponent isOpen={isOpen}>
            {isLoading ? <Loader /> : null}
            <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                <h3 className="text-xl font-semibold text-white">Associa azione</h3>
                <button
                    type="button"
                    className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={closeModel}>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            {/* Modal Body */}
            <div className=" p-6 space-y-6">
                <div className="w-full input-field-group">
                    <label>Tipologia azione*</label>
                    <select onChange={actionOnChangeEvent}>
                        <option value="">Seleziona...</option>
                        <option value="tender">Bandi aperti</option>
                        <option value="tenderArchive">Bandi archiviati</option>
                        <option value="marketing">Consulenze marketing</option>
                        <option value="financial">Consulenze finanziarie</option>
                    </select>
                </div>
                <hr />
            </div>

            {(actionType === 'tender' || actionType === 'tenderArchive') && (
                <form className="space-y-6" onSubmit={handleSubmit(onSubmitTenderHandler)}>
                    <div className="p-6 space-y-6">
                        <div className="flex flex-col w-full space-y-4">
                            {actionType === 'tender' && (
                                <div
                                    className={`w-full input-field-group ${
                                        errors?.tenderId ? 'invalid-field' : ''
                                    }`}>
                                    <label htmlFor="tenderId">Bando aperti*</label>
                                    <select
                                        id="tenderId"
                                        name="tenderId"
                                        {...register('tenderId', { required: true })}>
                                        <option value="">Seleziona...</option>
                                        {tenders.map((tender) => (
                                            <option key={tender.id} value={tender.id}>
                                                {tender.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors?.clientId?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                </div>
                            )}
                            {actionType === 'tenderArchive' && (
                                <div className="flex flex-row items-end space-x-4">
                                    {tenders.length ? (
                                        <>
                                            <div
                                                className={`w-[70%] input-field-group ${
                                                    errors?.tenderId ? 'invalid-field' : ''
                                                }`}>
                                                <label htmlFor="tenderId">Bando archiviati*</label>
                                                <Select
                                                    id="tenderId"
                                                    name="tenderId"
                                                    onChange={handleTenderArchiveChange}
                                                    options={tenders}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                />
                                                <input
                                                    type="hidden"
                                                    {...register('tenderId', { required: true })}
                                                />
                                                {/* <select
                                                    {...register('tenderId', { required: true })}>
                                                    <option value="">Seleziona...</option>
                                                    {tenders.map((tender) => (
                                                        <option key={tender.id} value={tender.id}>
                                                            {tender.name}
                                                        </option>
                                                    ))}
                                                </select> */}
                                                {errors?.clientId?.type === 'required' && (
                                                    <p className="text-red-700">
                                                        {MESSAGES.FIELD_REQUIRED}
                                                    </p>
                                                )}
                                            </div>
                                            <div>
                                                <PrimaryButton
                                                    onClick={() => initArchiveTenders(null)}>
                                                    Scarica tutto
                                                </PrimaryButton>
                                            </div>
                                        </>
                                    ) : (
                                        <div>Non bando archiviati</div>
                                    )}
                                </div>
                            )}
                            <div
                                className={`w-full input-field-group ${
                                    errors?.status ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="status">Stato*</label>
                                <select
                                    id="status"
                                    name="status"
                                    {...register('status', { required: true })}>
                                    <option value="">Seleziona...</option>
                                    {/* <option value="Required">Richiesto</option> */}
                                    <option value="Won">Vinto</option>
                                    <option value="Waiting">In attesa</option>
                                    <option value="Lost">Perso</option>
                                    <option value="Reported">Segnalato</option>
                                </select>
                                {errors?.status?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                            {status === 'Reported' && (
                                <div
                                    className={`w-full input-field-group ${
                                        errors?.note ? 'invalid-field' : ''
                                    }`}>
                                    <label htmlFor="note">Note</label>
                                    <textarea
                                        id="note"
                                        name="note"
                                        row="2"
                                        {...register('note', { maxLength: 500 })}
                                    />
                                    {errors?.note?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                    {errors?.note?.type === 'maxLength' && (
                                        <p className="text-red-700">
                                            {MESSAGES.maxLimitMessage(500)}
                                        </p>
                                    )}
                                </div>
                            )}
                            <div className="flex flex-row space-x-4">
                                <div
                                    className={`w-full input-field-group ${
                                        errors?.requiredAmount ? 'invalid-field' : ''
                                    }`}>
                                    <label htmlFor="requiredAmount">Importo richiesto (€)</label>
                                    <input
                                        id="requiredAmount"
                                        name="requiredAmount"
                                        type="number"
                                        step=".01"
                                        placeholder="Importo richiesto (€)"
                                        {...register('requiredAmount', { required: false })}
                                    />
                                    {errors?.requiredAmount?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                </div>
                                <div
                                    className={`w-full input-field-group ${
                                        errors?.obtainedAmount ? 'invalid-field' : ''
                                    }`}>
                                    <label htmlFor="obtainedAmount">Importo ottenuto (€)</label>
                                    <input
                                        id="obtainedAmount"
                                        name="obtainedAmount"
                                        type="number"
                                        step=".01"
                                        placeholder="Importo ottenuto (€)"
                                        {...register('obtainedAmount', { required: false })}
                                    />
                                    {errors?.obtainedAmount?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-row space-x-4">
                                <div
                                    className={`w-full input-field-group ${
                                        errors?.costEnteredByAbifin ? 'invalid-field' : ''
                                    }`}>
                                    <label htmlFor="requiredAmount">Rendicontazione (€)</label>
                                    <input
                                        id="costEnteredByAbifin"
                                        name="costEnteredByAbifin"
                                        type="number"
                                        step=".01"
                                        placeholder="Rendicontazione (€)"
                                        {...register('costEnteredByAbifin', {
                                            required: false
                                        })}
                                    />
                                    {errors?.costEnteredByAbifin?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                </div>
                                <div
                                    className={`w-full input-field-group ${
                                        errors?.payedCostForTender ? 'invalid-field' : ''
                                    }`}>
                                    <label htmlFor="payedCostForTender">Fisso (€)</label>
                                    <input
                                        id="payedCostForTender"
                                        name="payedCostForTender"
                                        type="number"
                                        step=".01"
                                        placeholder="Fisso (€)"
                                        {...register('payedCostForTender', { required: false })}
                                    />
                                    {errors?.payedCostForTender?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-row space-x-4">
                                <div
                                    className={`w-full input-field-group ${
                                        errors?.percentageAbifinRequest ? 'invalid-field' : ''
                                    }`}>
                                    <label htmlFor="percentageAbifinRequest">% (% di Abifin)</label>
                                    <input
                                        id="percentageAbifinRequest"
                                        name="percentageAbifinRequest"
                                        type="number"
                                        // step=".01"
                                        placeholder="Importo richiesto (€)"
                                        {...register('percentageAbifinRequest', {
                                            required: false
                                        })}
                                    />
                                    {errors?.percentageAbifinRequest?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                </div>
                                <div
                                    className={`w-full input-field-group ${
                                        errors?.percentageOfWiningTender ? 'invalid-field' : ''
                                    }`}>
                                    <label htmlFor="percentageOfWiningTender">
                                        % di vittoria (%)
                                    </label>
                                    <input
                                        id="percentageOfWiningTender"
                                        name="percentageOfWiningTender"
                                        type="number"
                                        // step=".01"
                                        placeholder="% di vittoria (%)"
                                        {...register('percentageOfWiningTender', {
                                            required: false
                                        })}
                                    />
                                    {errors?.percentageOfWiningTender?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`w-full input-field-group ${
                                    errors?.participationDate ? 'invalid-field' : ''
                                }`}>
                                <label htmlFor="participationDate">Data partecipazione*</label>
                                <input
                                    id="participationDate"
                                    name="participationDate"
                                    type="date"
                                    {...register('participationDate', { required: true })}
                                />
                                {errors?.participationDate?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                        <PrimaryButton type="submit">CONFERMA</PrimaryButton>
                        <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
                    </div>
                </form>
            )}

            {(actionType === 'marketing' || actionType === 'financial') && (
                <form className="space-y-6" onSubmit={handleSubmit(onSubmitConsultancyHandler)}>
                    <div className="p-6 ">
                        <div className="flex flex-col w-full space-y-4">
                            <div className="w-full input-field-group">
                                <label htmlFor="consultancyId">
                                    {actionType === 'marketing'
                                        ? 'Consulenza marketing*'
                                        : 'Consulenze finanziarie*'}
                                </label>
                                <select
                                    id="consultancyId"
                                    name="consultancyId"
                                    {...register('consultancyId', { require: true })}>
                                    <option value="">Seleziona...</option>
                                    {consultanciesOptions.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.description}
                                        </option>
                                    ))}
                                </select>
                                <p className="hidden">Test</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                        <PrimaryButton type="submit">CONFERMA</PrimaryButton>
                        <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
                    </div>
                </form>
            )}
        </ModalComponent>
    );
}

export default AssociateAction;
