/* eslint-disable prettier/prettier */
import axios from 'axios';

// Import helper functions
import Storage from '../helper/services/common/storage-helper-services';
import { convertSnakeCaseToCamelCase, getEnvironmentKeyValue, logoutProcess, mapKeys } from '../helper/utility';

// STEP : Create axios an instance using the config
const axiosInstance = axios.create({
    baseURL: getEnvironmentKeyValue('API_BASE_URL')
});

// STEP : Add a request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        return {
            ...config,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${Storage.get('auth-token')}`
            }
        };
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// STEP : Add a response interceptor
axiosInstance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return {
            ok: true,
            data: response.data.data ? mapKeys(response.data.data, convertSnakeCaseToCamelCase) : null,
            message: response.message || 'Success!'
        };
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const errorObject = {
            ok: false,
            errors: error?.response?.data?.errors,
            message: error?.response?.data?.message
        };
        if(error?.response?.data?.message === 'Unauthenticated.') {
            logoutProcess();
        }
        return Promise.reject(errorObject);
    }
);

export default axiosInstance;
