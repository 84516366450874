/* eslint-disable react/prop-types */
import React from 'react';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

function ConfirmationModal({
    isOpen = false,
    onConfirmAction = null,
    title,
    message,
    closeModel = null
}) {
    const onSubmitHandler = () => {
        onConfirmAction();
    };

    return (
        <ModalComponent isOpen={isOpen}>
            {/* Modal header  */}
            <div className="flex justify-between items-start p-4 rounded-t bg-primary-800">
                <h3 className="text-xl font-semibold text-white">{title}</h3>
                <button
                    type="button"
                    className="text-white bg-transparent hover:bg-grey-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                    onClick={closeModel}>
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            {/* Modal Body */}
            <div className="p-6 space-y-6">
                <b>{message}</b>
            </div>

            {/* Modal Footer */}
            <div className="flex items-center p-6 space-x-2 rounded-b bg-grey-700">
                <PrimaryButton onClick={onSubmitHandler}>CONFERMA</PrimaryButton>
                <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
            </div>
        </ModalComponent>
    );
}

export default ConfirmationModal;
