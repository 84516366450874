/* eslint-disable new-cap */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import xlsx from 'json-as-xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Storage from './services/common/storage-helper-services';

export const getCurrentYear = () => new Date().getFullYear();

export const convertSnakeCaseToCamelCase = (text) => {
    return text.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
};

export const convertCamelCaseToSnakeCase = (text) => {
    return text.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

const forEach = (array, iteratee) => {
    let index = -1;
    const { length } = array;
    // eslint-disable-next-line no-plusplus
    while (++index < length) {
        iteratee(array[index], index);
    }
    return array;
};

export const mapKeys = (target, iterator) => {
    if (target && typeof target === 'object') {
        const isArray = Array.isArray(target);
        const cloneTarget = isArray ? [] : {};
        if (isArray) {
            forEach(target, (value, index) => {
                cloneTarget[index] = mapKeys(value, iterator);
            });
        } else {
            forEach(Object.keys(target), (key) => {
                cloneTarget[iterator(key)] = mapKeys(target[key], iterator);
            });
        }
        return cloneTarget;
    }
    return target;
};

export const logoutProcess = () => {
    Storage.clear();
    window.location.href = '/login';
};

export const getDevice = (width) => {
    return width > 900 ? 'desktop' : 'mobile';
};

export const getPaginateData = (response) => {
    let data = null;
    if (response.data.links) {
        const nextPage = response.data.nextPageUrl ? response.data.nextPageUrl.split('?')[1] : null;
        const previousPage = response.data.prevPageUrl
            ? response.data.prevPageUrl.split('?')[1]
            : null;
        data = {
            from: response.data.from,
            to: response.data.to,
            total: response.data.total,
            nextPage,
            previousPage
        };
    }
    return data;
};

export const getEnvironmentKeyValue = (key) => {
    const envKey =
        process.env.NODE_ENV === 'production' ? `REACT_APP_PROD_${key}` : `REACT_APP_DEV_${key}`;
    return process.env[envKey];
};

export function toFormData(payload) {
    const data = new FormData();
    for (const key of Object.keys(payload)) {
        data.append(key, payload[key]);
    }
    return data;
}

export function reformatDate(dateStr) {
    const dArr = dateStr.split('-'); // ex input: "2010-01-18"
    return `${dArr[2].split(' ')[0]}-${dArr[1]}-${dArr[0]}`; // ex output: "18-01-10"
}

/**
 * Generate and Download PDF
 */
export function generateAndDownloadPdf(columns, jsonData, fileName) {
    const headers = [];
    const fields = [];

    /**
     * Create headers
     */
    columns.forEach((item) => {
        headers.push(item.header);
        fields.push(item.field);
    });

    /**
     * Create row data.
     */
    const rows = jsonData.map((data) => {
        const row = [];
        fields.forEach((field, index) => {
            const value = data[field] != null ? data[field] : '';
            row.push(value);

            if (index === fields.length - 1) {
                row.push('\r\n');
            }
        });
        return row;
    });

    const size =
        headers.length < 10
            ? 'a4'
            : headers.length < 16
            ? 'a3'
            : headers.length < 21
            ? 'a2'
            : headers.length < 26
            ? 'a1'
            : 'a0';

    const doc = new jsPDF('l', '', size);
    doc.autoTable({
        head: [[...headers]],
        body: rows,
        theme: 'grid',
        styles: {
            minCellHeight: 13,
            halign: 'center',
            valign: 'middle'
        },
        headStyles: {
            minCellHeight: 15,
            textColor: [255, 255, 255],
            fillColor: [48, 98, 172]
        },
        margin: { top: 8, right: 4, bottom: 4, left: 4 }
    });

    // TODO : Download undo
    doc.save(fileName);
    // window.open(URL.createObjectURL(doc.output('blob')));
}

/**
 * Generate and Download XLSX
 * @param {*} columns
 * @param {*} jsonData
 * @param {*} fileName
 */
export function generateAndDownloadXlsx(columns, jsonData, fileName) {
    const data = [
        {
            sheet: 'sheet 1',
            columns,
            content: jsonData
        }
    ];

    const settings = {
        fileName, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false // Display the columns from right-to-left (the default value is false)
    };

    xlsx(data, settings);
}

/**
 * Generate and Download CSV
 * @param {*} columns
 * @param {*} jsonData
 * @param {*} fileName
 */
export function generateAndDownloadCSV(columns, jsonData, fileName) {
    const headers = [];
    const fields = [];

    /**
     * Create headers
     */
    columns.forEach((item, index) => {
        headers.push(item.header);
        if (index === columns.length - 1) {
            headers.push('\r\n');
        }
        fields.push(item.field);
    });

    /**
     * Create row data.
     */
    const rows = jsonData.map((data) => {
        const row = [];
        fields.forEach((field, index) => {
            const value = data[field] != null ? data[field] : '';
            row.push(value);

            if (index === fields.length - 1) {
                row.push('\r\n');
            }
        });
        return row.join(',');
    });

    let csvData = headers.join(',');
    csvData += rows.join('');

    /**
     * Generate Blob
     */
    const csvFile = new Blob([csvData], { type: 'text/csv' });

    /**
     * Download csv file
     */
    const downloadLink = document.createElement('a');
    downloadLink.download = `${fileName}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export function generateCSV(csvData, filename) {
    const csvFile = new Blob([csvData], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.download = `${filename}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export const buildQueryChunck = (qs, key, value) => {
    if (qs && qs.length) {
        return `${qs}&${key}=${value}`;
    }

    return `${key}=${value}`;
};

export const sortArray = (items, key, type) => {
    console.log(' items : ', items);
    console.log(' key : ', key);
    console.log(' type : ', type);
    if (Array.isArray(items)) {
        if (type === 'asc') {
            return items.sort((a, b) => {
                if (a[key] < b[key]) {
                    return -1;
                }
                if (a[key] > b[key]) {
                    return 1;
                }
                return 0;
            });
        }
        if (type === 'desc') {
            return items.sort((a, b) => {
                if (b[key] < a[key]) {
                    return -1;
                }
                if (b[key] > a[key]) {
                    return 1;
                }
                return 0;
            });
        }
        return Error('Invalid type');
    }
    return Error('Invalid Array');
};
