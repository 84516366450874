/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants';
import { getRevenueDropdown } from '../../helper/services/apis/common-services';

function RevenueDropDown({
    register,
    name,
    config = {},
    errors,
    className,
    defaultValue,
    setValue
}) {
    const [revenues, setRevenues] = useState([]);

    const initRevenues = async () => {
        const result = await getRevenueDropdown().catch((e) => toast.error(e.message));
        console.log(result);
        if (result.data) {
            setRevenues([...result.data]);
        }
        // setRefresh(!refresh);
        setValue(name, defaultValue);
    };

    useEffect(() => {
        initRevenues();
    }, []);

    useEffect(() => {
        setValue(name, defaultValue);
    }, [defaultValue]);

    return (
        <div
            className={`w-full input-field-group ${className} ${
                errors[name] ? 'invalid-field' : ''
            }`}>
            <label htmlFor="revenue">Fatturato{config.required ? '*' : ''}</label>
            {revenues.length ? (
                <select id="revenue" name="revenue" {...register(name, { ...config })} refresh>
                    <option value="">Seleziona...</option>
                    {revenues.map((revenue) => (
                        <option key={revenue.id} value={revenue.id}>
                            {revenue.name}
                        </option>
                    ))}
                </select>
            ) : null}

            {errors[name]?.type === 'required' && (
                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
            )}
        </div>
    );
}

export default RevenueDropDown;
