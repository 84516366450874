/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { MESSAGES } from '../../constants';

function YearDropdown({ label, register, name, config = {}, errors, className }) {
    const [options, setOptions] = useState([]);

    const initYears = async () => {
        const years = [];
        const min = 1900;
        for (let year = min; year <= new Date().getFullYear(); year++) {
            years.push({ id: year, name: year });
        }

        setOptions([...years.reverse()]);
    };

    useEffect(() => {
        initYears();
    }, []);

    return (
        <div
            className={`w-full input-field-group ${className} ${
                errors[name] ? 'invalid-field' : ''
            }`}>
            <label htmlFor={name}>{config.required ? `${label}*` : label}</label>
            <select id={name} name={name} {...register(name, { ...config })}>
                <option value="">Seleziona...</option>
                {options.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </select>
            {errors[name]?.type === 'required' && (
                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
            )}
        </div>
    );
}

export default YearDropdown;
