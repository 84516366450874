/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants';
import { getRegionDropdown } from '../../helper/services/apis/common-services';

function RegionDropDown({
    register,
    name,
    config = {},
    errors,
    className,
    defaultValue,
    setValue,
    multiple = false,
    fieldArrayName = null
}) {
    const [options, setOptions] = useState([]);

    const initRegion = async () => {
        const result = await getRegionDropdown().catch((e) => toast.error(e.message));

        if (result.data) {
            if (multiple) {
                setOptions(result.data.map((item) => ({ value: item.id, label: item.name })));
            } else {
                setOptions([...result.data]);
            }
        }
    };

    const handleChange = (event) => {
        if (event && event.length) {
            setValue(name, event);
        } else {
            setValue(name, '');
        }
    };

    useEffect(() => {
        initRegion();
        if (setValue) {
            handleChange(defaultValue);
        }
    }, [defaultValue]);

    return (
        <div
            className={`w-full input-field-group ${className} ${
                (fieldArrayName ? errors?.[fieldArrayName] : errors[name]) ? 'invalid-field' : ''
            }`}>
            <label htmlFor="regione">Regione{config.required ? '*' : ''}</label>
            {multiple ? (
                options.length ? (
                    <>
                        <Select
                            defaultValue={[...defaultValue]}
                            isMulti
                            name={name}
                            onChange={handleChange}
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                        <input type="hidden" {...register(name, { ...config })} />
                    </>
                ) : null
            ) : options.length ? (
                <select id="regione" name="regione" {...register(name, { ...config })}>
                    <option value="">Seleziona...</option>
                    {options.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </select>
            ) : null}

            {(fieldArrayName ? errors?.[fieldArrayName]?.type : errors[name]?.type) ===
                'required' && <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>}
        </div>
    );
}

export default RegionDropDown;
