/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import RoundButton from './RoundButton';

function PageHeader({
    children,
    title,
    importClickHandler = null,
    filterClickHandler = null,
    filters = null
}) {
    const [filtersCount, setFiltersCount] = useState(0);

    useEffect(() => {
        console.log(filters);
        if (filters) {
            let count = 0;
            const keys = Object.keys(filters);
            keys.forEach((key) => {
                if (filters[key].length > 0) {
                    count += 1;
                }
            });
            setFiltersCount(count);
        } else {
            setFiltersCount(0);
        }
    }, [filters]);

    return (
        <div className="flex flex-col justify-between space-y-2 md:flex-row md:items-center py-4">
            <div className="text-2xl font-bold">
                {/* <span className="text-2xl font-bold">{title}</span> */}
                {title}
            </div>
            <div className="flex justify-between md:justify-start md:space-x-2 ">
                {children}
                {importClickHandler ? (
                    <RoundButton onClick={importClickHandler}>
                        <svg
                            width="20"
                            height="18"
                            viewBox="0 0 20 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11 7H16L10 13L4 7H9V0H11V7ZM2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16Z"
                                fill="currentColor"
                            />
                        </svg>
                    </RoundButton>
                ) : null}
                {filterClickHandler ? (
                    <div className="flex relative">
                        <RoundButton
                            onClick={filterClickHandler}
                            className={filtersCount > 0 ? 'bg-grey-900' : ''}>
                            <svg
                                width="20"
                                height="14"
                                viewBox="0 0 12 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.5 8.10526L0 1.47368V0H12V1.47368L7.5 8.10526V12.5263L4.5 14V8.10526Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </RoundButton>

                        {filtersCount ? (
                            <span className="bg-red-600 absolute top-[-10px] right-[-10px] w-6 h-6 rounded-full text-white text-center">
                                {filtersCount}
                            </span>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default PageHeader;
